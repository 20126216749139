import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
// Customizable Area End

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../../../components/src/DocumentLoader.web";
import React from "react";
import type { FormInstance } from "antd/es/form";
// Customizable Area End

export const configJSON = require("../config");

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  isUserLoggedIn: any;
  changemodal: any;
  editbtn: boolean;
  employeeprofiledetails: any;
  companies: any;
  confirmPassword: any;
  profileImage: any;
  oldPasswordIncorrect: boolean;
  oldPasswordnewPassword: boolean;
  emptyField: boolean;
  emptyFieldContact: boolean;
  emptyFieldAddress: boolean;
  sameError: boolean;
  apiToken: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployeeProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  postLoginMobileApiCallId: any;
  getemployeeprofiledetailsApiCallId: any;
  postemployeeEditprofiledetailsApiCallId: any;
  postChangepasswordApiCallId: any;
  upload: any;
  formRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      isUserLoggedIn: "",
      changemodal: false,
      editbtn: false,
      employeeprofiledetails: {},
      companies: {},
      confirmPassword: "",
      profileImage: null,
      oldPasswordIncorrect: false,
      oldPasswordnewPassword: false,
      emptyField: false,
      emptyFieldAddress: false,
      emptyFieldContact: false,
      sameError: false,
      apiToken: "",
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.formRef = React.createRef<FormInstance>();
  }

  // Customizable Area Start

  async componentDidMount() {
    this.checkUserStatus();
  }

  checkUserStatus = async () => {
    this.setState(
      {
        apiToken: localStorage.getItem("token"),
      },
      () => {
        if (this.state.apiToken) {
          this.getEmployeeProfileDetailsShow();
          this.setState({ isUserLoggedIn: true });
        }
      }
    );
  };

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type = "" } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.apiToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(responseJson){
        if (responseJson.status === 500) {
          this.props.hideLoader();
          MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
          return;
        }else if(responseJson.errors){

          switch(apiRequestCallId) {
            case this.getemployeeprofiledetailsApiCallId : {
              this.props.hideLoader();
              this.getemployeeprofiledetailsShowFailureCallBack(responseJson);
              break;
            }

            case this.postemployeeEditprofiledetailsApiCallId : {
              this.setState({
                editbtn: !this.state.editbtn,
              });
              this.props.hideLoader();
              this.postemployeeEditprofiledetailsShowFailureCallBack(responseJson);
              break;
            }

            case this.postChangepasswordApiCallId : {
              this.props.hideLoader();
              this.postChangePasswordShowFailureCallBack(responseJson);
              break;
            }

            default : {
              break;
            }

          }

        }else if (responseJson.contact) {
          this.props.hideLoader();
          this.setState({
            sameError : true
        });
        }else{
          switch(apiRequestCallId) {
            case this.getemployeeprofiledetailsApiCallId : {
              this.props.hideLoader();
              this.getemployeeprofiledetailsShowSuccessCallBack(responseJson);
              break;
            }

            case this.postemployeeEditprofiledetailsApiCallId : {
              this.setState({
                editbtn: !this.state.editbtn,
              });
              this.props.hideLoader();
              notification["success"]({
                message: "Your details has been saved successfully",
              });
              this.postemployeeEditprofiledetailsShowSuccessCallBack(responseJson);
              break;
            }

            case this.postChangepasswordApiCallId : {
              this.props.hideLoader();
              this.postChangePasswordShowSuccessCallBack(responseJson);
              break;
            }

            default : {
              break;
            }
          }
        }
      }else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
  }

  onFinish = async (values: any) => {
    if (this.validateForm(values)) {
      this.setState(
        {
          confirmPassword: values.confirm_password,
        },
        () => {
          this.employeeChangePassword(values);
        }
      );
    }
  };

  validateForm = (values: any) => {
    let isValid = true;

    return isValid;
  };

  changePasswordModal = () => {
    this.setState({ changemodal: true });
  };
  modalhandleCancel = () => {
    this.setState({ changemodal: false });
  };
  handleEditProfile = () => {
    this.setState({
      editbtn: !this.state.editbtn,
    });
  };

  handleprofileEdit = (e: any) => {
    const { name, value } = e.target;
    const employeeprofiledetails = this.state.employeeprofiledetails;
    if (name === "Education") {
      employeeprofiledetails["education"] = value;
      this.setState({
        employeeprofiledetails,
      });
      if (value.length == 0) {
        this.setState({
          emptyField: true,
        });
      } else {
        this.setState({
          emptyField: false,
        });
      }
    }
  };

  handleprofileEditcontact = (e: any) => {
    const { name, value } = e.target;
    const employeeprofiledetails = this.state.employeeprofiledetails;
    if (name === "Contact") {
      let numbers = /^[0-9]+$/;
      if (value === "" || numbers.test(value)) {
        if (value.length <= 10 || value.length === 0) {
          employeeprofiledetails["contact"] = value;
          this.setState({
            employeeprofiledetails,
          });
        }
      }
      if (value.length == 0) {
        this.setState({
          emptyFieldContact: true,
        });
      } else {
        this.setState({
          emptyFieldContact: false,
          sameError : false,
        });
      }
    }
  };

  handleprofileEditaddress = (e: any) => {
    const { name, value } = e.target;

    const employeeprofiledetails = this.state.employeeprofiledetails;
    if (name === "Address") {
      employeeprofiledetails["address"] = value;
      this.setState({
        employeeprofiledetails,
      });
      if (value.length == 0) {
        this.setState({
          emptyFieldAddress: true,
        });
      } else {
        this.setState({
          emptyFieldAddress: false,
        });
      }
    }
  };

  getEmployeeProfileDetailsShow = async () => {
    this.props.showLoader();
    this.getemployeeprofiledetailsApiCallId = await this.apiCall({
      contentType: configJSON.employeeprofileApiContentType,
      method: configJSON.employeeprofileAPiMethod,
      endPoint: configJSON.getEmployeeprofileAPiEndPoint,
    });
  };

  getemployeeprofiledetailsShowFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  getemployeeprofiledetailsShowSuccessCallBack = async (res: any) => {
    let companies = res.data.attributes.companies.map((item: any) => {
      return {
        email: item.email,
        name: item.name,
      };
    });
    this.setState({
      employeeprofiledetails: res.data.attributes,
      companies: companies,
    });
  };

  employeeEditfunction = async () => {
    if (
      this.state.emptyField ||
      this.state.emptyFieldAddress ||
      this.state.emptyFieldContact
    ) {
      return notification["error"]({
        message: "Please fill the blank field",
      });
    }
    if (this.state.employeeprofiledetails.contact.length < 7) {
      return notification["error"]({
        message: "Please fill (minimum 7) characters in Employee contact field",
      });
    }
    if (this.state.employeeprofiledetails.education.trim() == "") {
      return notification["error"]({
        message: "Please fill the Education field",
      });
    }
    if (this.state.employeeprofiledetails.contact.trim() == "") {
      return notification["error"]({
        message: "Please fill the Employee contact field",
      });
    }
    if (this.state.employeeprofiledetails.address.trim() == "") {
      return notification["error"]({
        message: "Please fill the Address field",
      });
    }
    this.props.showLoader();
    let apiBody = {
      address: this.state.employeeprofiledetails.address.trim(),
      contact: this.state.employeeprofiledetails.contact.trim(),
      education: this.state.employeeprofiledetails.education.trim(),
    };
    this.postemployeeEditprofiledetailsApiCallId = await this.apiCall({
      contentType: configJSON.employeeEditprofileApiContentType,
      method: configJSON.employeeEditprofileAPiMethod,
      endPoint: configJSON.postEmployeeEditprofileAPiEndPoint,
      body: apiBody,
    });
  };

  profileEditfunction = async () => {
    this.props.showLoader();
    let formdata = new FormData();
    formdata.append("profile_image", this.state.profileImage);
    this.postemployeeEditprofiledetailsApiCallId = await this.apiCall({
      method: configJSON.employeeEditprofileAPiMethod,
      endPoint: configJSON.postEmployeeEditprofileAPiEndPoint,
      body: formdata,
      type: "formData",
    });
  };

  postemployeeEditprofiledetailsShowSuccessCallBack = (res: any) => {
    const employeeprofiledetails = this.state.employeeprofiledetails;
    employeeprofiledetails["profile_image"] =
      res?.data?.attributes?.profile_image;
    this.setState({ employeeprofiledetails });
  };

  postemployeeEditprofiledetailsShowFailureCallBack = async (err: any) => {
    MESSAGE.error(err, 4);
  };

  employeeChangePassword = async (values: any) => {
    this.props.showLoader();
    let apiBody = {
      old_password: values.old_password,
      password: values.confirm_password,
    };
    this.postChangepasswordApiCallId = await this.apiCall({
      contentType: configJSON.employeeprofileApiContentType,
      method: configJSON.ChangePasswordAPiMethod,
      endPoint: configJSON.postChangePasswordAPiEndPoint,
      body: apiBody,
    });
  };

  postChangePasswordShowSuccessCallBack = (res: any) => {
    if (res.message === "Old password is incorrect") {
      this.setState({
        oldPasswordIncorrect: true,
      });
    }
    if (res.message === "Old and New password cannot be same") {
      this.setState({
        oldPasswordnewPassword: true,
        oldPasswordIncorrect: false,
      });
    }
    if (res.message === "Password changed successfully") {
      notification["success"]({
        message: res.message,
      });
      this.setState(
        {
          changemodal: false,
        },
        () => {
          this.onReset();
        }
      );
    }
  };
  postChangePasswordShowFailureCallBack = async (err: any) => {
    MESSAGE.error(err, 4);
  };

  imgFilehandler = (e: any) => {
    const reader: any = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const employeeprofiledetails = this.state.employeeprofiledetails;
        let profileImage = {
          url: reader.result,
        };
        employeeprofiledetails["profile_image"] = profileImage;
        this.setState({ employeeprofiledetails });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    this.setState(
      {
        profileImage: e.target.files[0],
        editbtn: true,
      },
      () => {
        this.profileEditfunction();
      }
    );
  };
  handleOldpassword = () => {
    this.setState({ 
      oldPasswordIncorrect: false ,
      oldPasswordnewPassword : false
    });
  };

  handleNewpassword = () => {
    this.setState({ oldPasswordnewPassword: false });
  };

  onReset = () => {
    this.formRef.current?.resetFields();
  };

  handleConfirpasword = () => {
    
  }

}
// Customizable Area End
