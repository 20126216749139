import React from "react";
// Customizable Area Start
import { Col, Row } from "antd";
import EmployeeHomeController from "./EmployeeHomeController.web";
import { filterimgsort } from "./assets";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import TableCommonComponent from "../../../components/src/TableCommonComponent.web";
import DocumentLoader from "../../../components/src/DocumentLoader.web";
import FilterCommonComponent from "../../../components/src/FilterCommonComponent.web";
// Customizable Area End

class EmployeeHome extends EmployeeHomeController {
  // Customizable Area Start
  renderHomeHeaderPart = () => {
    return (
      <>
        <Row>
          <Col lg={12} xl={12} md={24} sm={24} xs={24}>
            <div className="header-entry-box">
              <h2 className="header-title">
                {this.state.dataList.length} Companies
              </h2>
              <p className="header-notification">
                + {this.state?.allData?.new_companies_count} New Company
              </p>
              <button
                type="button"
                data-test-id="btnFilterCountry"
                className="header-filter-sort"
                onClick={() => this.handlefiltersort()}
              >
                {(!this.state.isHomeFiltersort ||
                  this.state.isHomeFiltersort) && (
                  <img src={filterimgsort} className="filterimgsort" />
                )}
              </button>
            </div>
          </Col>
          <Col lg={12} xl={12} md={24} sm={24} xs={24}>
            <div className="header-create-box">
              <button
                type="button"
                data-test-id="btnCreateCountry"
                className="create-button"
                onClick={this.handleCreateCompanyAccount}
              >
                Create company account
              </button>
            </div>
          </Col>
        </Row>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderFilterSortPart = () => {
    return (
      <>
        <FilterCommonComponent
          dataList={this.state.dataList}
          isFiltersort={this.state.isHomeFiltersort}
          checkBoxChangeFilter={this.checkBoxChangeFilter}
          clearFilter={this.handleClearFilter}
        />
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <>
        <div className="home-height-set">
          <NavbarCommonComponent
            displaySpinner={() => { } }
            hideLoader={() => { } }
            showLoader={() => { } }
            history={undefined}
            navigation={undefined}
            id={""}
            location={undefined} isNotificationUpdate={false}          />
          <div className="Main_container">
            {this.renderHomeHeaderPart()}
            {this.renderFilterSortPart()}
            <TableCommonComponent
              paginationTitle=""
              handlePageSize={undefined}
              pageSize={this.state.pageSize}
              dataList={
                this.state.dataForFilterSort.length > 0
                  ? this.state.dataForFilterSort
                  : this.state.dataList
              }
              callingColumnsFeature={"home"}
              props={this.props}
              employeeName={this.state.employeeName}
              dueDateSet={this.state.pendingHomeDueDate}
              upload={undefined}
              uploadDoc={undefined}
              uplodeId={undefined}
            />
          </div>
        </div>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(EmployeeHome);
export { EmployeeHome };
// Customizable Area End
