import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../../components/src/DocumentLoader.web";
import axios from "axios";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  isUserLoggedIn: any;
  apiToken: any;
  documentData: any;
  companyId: any;
  documentFilesData: any;
  deleteFileModal:boolean;
  fileId: any;
  deleteDocModal: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployeeViewDocumentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmployeeViewFilesApiCallId: any;
  deleteDocumentFilesApiCallId: any;
  deleteDocumentTypeApiCallId: any;
  postUploadDocumentsApiCallId: any;
  uploadRef:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      isUserLoggedIn: false,
      apiToken: "",
      documentData: [],
      companyId: "",
      documentFilesData: "",
      deleteFileModal: false,
      fileId : "",
      deleteDocModal: false,
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.setState({
      companyId: this.props.location.state.companyId,
      documentFilesData: this.props.location.state.documentFilesData,
    });
    this.checkUserStatus();
  }

  checkUserStatus = async () => {
    this.setState(
      {
        apiToken: localStorage.getItem("token"),
      },
      () => {
        if (this.state.apiToken) {
          this.getEmployeeFilesDetailsShow();
          this.setState({ isUserLoggedIn: true });
        }
      }
    );
  };

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.apiToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(responseJson){
        if(responseJson.status === 500){
          MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
          return;
        }else if(responseJson.errors){
          switch(apiRequestCallId){
            case this.getEmployeeViewFilesApiCallId : {
              this.getEmployeeViewFilesFailureCallBack(responseJson);
              break;
            }

            case this.deleteDocumentFilesApiCallId : {
              this.deleteDocumentFilesFailureCallBack(responseJson);
              break;
            }

            case this.deleteDocumentTypeApiCallId : {
              this.deleteDocumentTypeFailureCallBack(responseJson);
              break;
            }

            case this.postUploadDocumentsApiCallId : {
              this.postUploadDocumentsFailureCallBack(responseJson);
              break;
            }

            default : {
              break;
            }
          }
        }else{
          switch(apiRequestCallId){

            case this.deleteDocumentTypeApiCallId : {
              this.deleteDocumentTypeSuccessCallBack(responseJson);
              break;
            }

            case this.deleteDocumentFilesApiCallId : {
              this.deleteDocumentFilesSuccessCallBack(responseJson);
              break;
            }

            case this.getEmployeeViewFilesApiCallId : {
              this.getEmployeeViewFilesSuccessCallBack(responseJson);
              break;
            }

            case this.postUploadDocumentsApiCallId : {
              this.postUploadDocumentsSuccessCallBack(responseJson);
              break;
            }

            default : {
              break;
            }
          }
        }
      }else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
  }

  postUploadDocumentsFailureCallBack = (error: any) => {
    this.props.hideLoader();
  };

  postUploadDocumentsSuccessCallBack = async (res: any) => {
    if(res.message?.includes("Document uploaded successfully.")){
      notification["success"]({
        message: res.message?.replace("!!","!"),
      });
      this.getEmployeeFilesDetailsShow();
    }else{
      notification["error"]({
        message: res.message?.replace("!!","!"),
      });
    }
    this.props.hideLoader();
  };

  getEmployeeFilesDetailsShow = async () => {
    this.getEmployeeViewFilesApiCallId = await this.apiCall({
      contentType: configJSON.getEmployeeViewFilesApiContentType,
      method: configJSON.getEmployeeViewFilesApiMethod,
      endPoint:
        configJSON.getEmployeeViewFilesApiEndPoint +
        `?id=${this.state.companyId}&document_id=${this.state.documentFilesData?.id}`,
    });
  };

  getEmployeeViewFilesFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  getEmployeeViewFilesSuccessCallBack = async (res: any) => {
    this.setState({ documentData: res.data.attributes });
  };

  deleteHandler = () => {
    if(this.state.deleteFileModal){
      this.deleteDocumentFiles();
    }else{
      this.deleteDocumentType();
    }
  };

  deleteDocumentFiles = async () => {
   this.props.showLoader()
    this.deleteDocumentFilesApiCallId = await this.apiCall({
      method: configJSON.deleteFilesApiMethod,
      endPoint:
        configJSON.deleteFilesApiEndPoint +
        `?id=${this.state.companyId}&document_id=${this.state.documentFilesData?.id}&file_id=${this.state.fileId}`,
      contentType: configJSON.deleteFilesApiContentType,
    });
  };

  deleteDocumentFilesFailureCallBack = (error: any) => {
    this.props.hideLoader();
  };

  deleteDocumentFilesSuccessCallBack = async (res: any) => {
    if (res.message === "File deleted.") {
      this.setState({
        deleteFileModal: false ,
      }, () => {
        setTimeout(() => {
        this.getEmployeeFilesDetailsShow() ;
        }, 1500);
      });
    }
    notification["success"]({
      message: res.message,
    });
      this.getEmployeeFilesDetailsShow();
      this.props.hideLoader()
  };

  handleDownloadFiles = (Data: any) => {
    axios({
      url: Data.url,
      method: "GET",
      responseType: "blob",
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", Data.name);
      document.body.appendChild(link);
      link.click();
    });
  };

  deleteDocumentType = async () => {
    this.props.showLoader()
    this.deleteDocumentTypeApiCallId = await this.apiCall({
      method: configJSON.deleteDocumentApiMethod,
      endPoint:
        configJSON.deleteDocumentApiEndPoint +
        `?id=${this.state.companyId}&document_id=${this.state.documentFilesData?.id}`,
      contentType: configJSON.deleteDocumentApiContentType,
    });
  };

  deleteDocumentTypeFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  deleteDocumentTypeSuccessCallBack = async (res: any) => {
    notification["success"]({
      message: res.message,
    });
    setTimeout(() => {
      this.props.history.push("/employee/files");
    }, 1500);
    this.props.hideLoader()
  };

  handleCancelFileModal = () => {
    this.setState({ deleteFileModal: false, deleteDocModal: false, fileId: "" });
  };

  deleteDocumentModal = () => {
    this.setState({ deleteDocModal: true });
  };

  deleteDocumentFilesModal = (item:any) => {
    this.setState({ fileId: item , deleteFileModal: true });
  };

  postUploadDocFiles = async (e:any) => {
    let fileList = [...e.target.files]
    let formData = new FormData();
    fileList.forEach((item: any) => {
      formData.append('docs[]',item)
    });
    this.props.showLoader();
    this.postUploadDocumentsApiCallId = await this.apiCall({
      method: configJSON.postUploadDocApiMethod,
      endPoint: `${configJSON.postUploadDocApiEndPoint}/${this.state.documentFilesData?.id}`,
      body: formData,
      type: "formData",
    });
    this.uploadRef.value = "";
  };

}
// Customizable Area End
