Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Documentation";
exports.labelBodyText = "Documentation Body";

exports.getEmployeeDocumentsAPiEndPoint = "account_block/employee/home";
exports.getEmployeeDocumentsAPiMethod = "GET";
exports.getEmployeeDocumentsApiContentType = "application/json";

exports.getCompanyDocumentsAPiEndPoint = "company/company_documents";
exports.getCompanyDocumentsAPiMethod = "GET";
exports.getCompanyDocumentsApiContentType = "application/json";
exports.getEmployeeViewFilesApiEndPoint = "company_document";
exports.getEmployeeViewFilesApiMethod = "GET";
exports.getEmployeeViewFilesApiContentType = "application/json";

exports.deleteFilesApiEndPoint = "account_block/employee/delete_company_document_file";
exports.deleteFilesApiMethod = "DELETE";
exports.deleteFilesApiContentType = "application/json";

exports.deleteDocumentApiEndPoint = "account_block/employee/delete_company_document";
exports.deleteDocumentApiMethod = "DELETE";
exports.deleteDocumentApiContentType = "application/json";

exports.getEmployeeAllDocumentsApiEndPoint = "account_block/employee/all_documents";
exports.getEmployeeAllDocumentsApiMethod = "GET";
exports.getEmployeeAllDocumentsApiContentType = "application/json";

exports.postUploadDocApiEndPoint = "account_block/employee/upload_document";
exports.postUploadDocApiMethod = "POST";
exports.postUploadDocApiContentType = "multipart/form-data";

exports.getEmployeeViewFilesApiEndPoint = "company_document";
exports.getEmployeeViewFilesApiMethod = "GET";
exports.getEmployeeViewFilesApiContentType = "application/json";

exports.deleteFilesApiEndPoint = "account_block/employee/delete_company_document_file";
exports.deleteFilesApiMethod = "DELETE";
exports.deleteFilesApiContentType = "application/json";

exports.deleteDocumentApiEndPoint = "account_block/employee/delete_company_document";
exports.deleteDocumentApiMethod = "DELETE";
exports.deleteDocumentApiContentType = "application/json";

exports.getEmployeeAllDocumentsApiEndPoint = "account_block/employee/all_documents";
exports.getEmployeeAllDocumentsApiMethod = "GET";
exports.getEmployeeAllDocumentsApiContentType = "application/json";

exports.postUploadDocApiEndPoint = "account_block/employee/upload_document";
exports.postUploadDocApiMethod = "POST";
exports.postUploadDocApiContentType = "multipart/form-data";

exports.getCompanyDocumentDetailsAPiEndPoint = "company_document";
exports.getCompanyDocumentDetailsAPiMethod = "GET";
exports.getCompanyDocumentDetailsContentType = "application/json";

exports.getCompanyDocumentsAPiEndPoint = "company/company_documents";
exports.getCompanyDocumentsAPiMethod = "GET";
exports.getCompanyDocumentsApiContentType = "application/json"

exports.postCreateEmployeeStaffAPiEndPoint = "employee/company";
exports.postCreateEmployeeStaffAPiMethod = "POST";
exports.postCreateEmployeeStaffApiContentType = "application/json"; 

exports.deleteEmployeeStaffAPiEndPoint = "employee/company";
exports.deleteEmployeeStaffAPiMethod = "DELETE";
exports.deleteEmployeeStaffApiContentType = "application/json"; 
exports.getNewDocumentTypeApiEndPoint = "bx_block_types/document_types";
exports.getNewDocumentTypeApiMethod = "GET";
exports.getNewDocumentTypeApiContentType = "application/json";

exports.postNewDocApiEndPoint = "account_block/employee/create_company_document";
exports.postNewDocApiMethod = "POST";
exports.postNewDocTypeApiContentType = "application/json";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
