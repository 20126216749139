import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import moment from "moment";
// Customizable Area End

export interface Props {
  navigation: any;
  location: any;
  history: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  apiToken: any;
  docTypes: any;
  selectedData: any;
  newDocCompanyId: any;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployeeAddDocumentsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  //   saveContactUsApiCallId: any;
  getDocumentTypeApiCallId: any;
  postNewDocumentApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      apiToken: [],
      docTypes: [],
      selectedData: [],
      newDocCompanyId:this.props.location.state?.companyId,
      // Customizable Area End
    };

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    this.checkUserStatus();
  }

  checkUserStatus = async () => {
    this.setState(
      {
        apiToken: localStorage.getItem("token"),
      },
      () => {
        if (this.state.apiToken) {
          this.getDocumentType();
        }
      }
    );
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isValidResponse = (responseJson: any) => {
    return responseJson || responseJson.data;
  };
  // Customizable Area End

  // Customizable Area Start
  isInValidResponse = (responseJson: any) => {
    return responseJson || responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getDocumentTypeApiCallId) {
      this.getDocumentTypeSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postNewDocumentApiCallId) {
      this.postNewDocumentShowSuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getDocumentTypeApiCallId) {
      this.getDocumentTypeFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.postNewDocumentApiCallId) {
      this.postNewDocumentShowFailureCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = (await localStorage.getItem("token")) || "";

    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
 
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  getDocumentType = async () => {
    this.getDocumentTypeApiCallId = await this.apiCall({
      contentType: configJSON.getNewDocumentTypeApiContentType,
      method: configJSON.getNewDocumentTypeApiMethod,
      endPoint: configJSON.getNewDocumentTypeApiEndPoint,
    });
  };

  getDocumentTypeSuccessCallBack = (res: any) => {
    this.setState({
      docTypes: res,
    });
  };

  getDocumentTypeFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  postNewDocumentShow = async (values:any) => {
    let apiBody = {
    id: this.state.newDocCompanyId,
    name : values.name,
    deadline_date :moment(values.deadline_date, "DD-MM-YYYY") ,
    document_type_id:values.documenttype ,
    };
    this.postNewDocumentApiCallId = await this.apiCall({
      method: configJSON.postNewDocApiMethod,
      endPoint: configJSON.postNewDocApiEndPoint,
      contentType: configJSON.postNewDocTypeApiContentType,
      body: apiBody,
    });
  };

  postNewDocumentShowFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  postNewDocumentShowSuccessCallBack = async (res: any) => {
    notification["success"]({
      message: "New document created successfully",
    });
    this.props.history.push("/employee/files")
  };

  validateForm = (values: any) => {
    let isValid = true;
    return isValid;
  };

  onAddNewDocumentFinish = async (values: any) => {
    if (this.validateForm(values)) {
          this.postNewDocumentShow(values);
        }
    }

  handleDocumentTypes = (Value: any) => {
    this.setState({
      selectedData: Value,
    });
  };

  disabledPastDate = (current: any) => {
    return (
          current && current < moment().startOf("day")
        );
}

  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End
