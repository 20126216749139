//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
withRouter  
} from 'react-router-dom';
/** Routes List start */
import Login from "../../blocks/email-account-login/src/Login.web";
import EmployeLayout from "../../components/src/EmployeLayout";
import CompanyLayout from "../../components/src/CompanyLayout";
import EmployeeHome from "../../blocks/landingpage/src/EmployeeHome.web";
import EmployeeProfile from "../../blocks/CustomisableUserProfiles/src/EmployeeProfile/EmployeeProfile.web";
import EmployeeCreateCompanyAccount from "../../blocks/landingpage/src/EmployeeCompany/EmployeeCreateCompanyAccount.web";
import EmployeeDocuments from "../../blocks/Documentation/src/EmployeeDocuments.web";
import EmployeeViewDocuments from "../../blocks/Documentation/src/EmployeeViewDocument.web";
import EmployeeCompany from "../../blocks/landingpage/src/EmployeeCompany/EmployeeCompany.web";
import EmployeeCompanyDetails from "../../blocks/landingpage/src/EmployeeCompany/EmployeeCompanyDetails.web";
import CompanyHome from "../../blocks/landingpage/src/CompanyHome.web";
import CompanyDocuments from "../../blocks/Documentation/src/CompanyDocuments/CompanyDocuments.web";
import CompanyProfile from "../../blocks/CustomisableUserProfiles/src/CompanyProfile/CompanyProfile.web";
import EmployeeUpload from "../../blocks/Documentation/src/EmployeeUploadDocs.web";
import CompanyDocumentDetails  from "../../blocks/Documentation/src/CompanyDocuments/CompanyDocumentDetails.web";
import EmployeeAddDocuments from "../../blocks/Documentation/src/EmployeeAddDocuments.web";
import EmployeeReminders from "../../blocks/AutomaticReminders/src/EmployeeReminders.web";
import EmployeeViewReminder from "../../blocks/AutomaticReminders/src/EmployeeViewReminder.web";
import Download from "../../blocks/Download/src/Download.web";
import DownloadedFile from "../../blocks/Download/src/DownloadedFile.web"
const Notifications = lazy(() =>
  import('../../blocks/EmailNotifications/src/Notifications.web')
);
/** Routes List End */

/** Private Routes start */
import PrivateRoute from './PrivateRoute.web';
import Loader from '../../components/src/Loader.web';
/** Private Roues End */

function WebRoutes() {
  // render() {
  return (
    <Router>
      <Suspense fallback={<Loader loading />}>
        <Switch>
          <Route
            path="/"
            exact
            render={props => <Login {...props} />}
          />
          <Route
            path="/company/login"
            exact
            render={props => <Login {...props} />} 
          />
           <Route
            path="/Download"
            exact
            render={props => <Download {...props} />} 
          />
          <Route
            path="/DownloadedFile"
            exact
            render={props => <DownloadedFile {...props} />} 
          />
            <PrivateRoute
                path="/employee/create/companyaccount"
                exact
                render={props => <EmployeeCreateCompanyAccount {...props} />}
              />
          <PrivateRoute path="/employee">
            <EmployeLayout>
              <Route
                path="/employee/home"
                exact
                render={props => <EmployeeHome {...props} />}
              />
              <Route
                path="/employee/company"
                exact
                render={props => <EmployeeCompany {...props} />}
              />
              <Route
                path="/employee/profile"
                exact
                render={props => <EmployeeProfile {...props} />}
              />
               <Route
                path="/employee/files"
                exact
                render={props => <EmployeeDocuments {...props} />}
              />
               <Route
                path="/employee/company/details"
                exact
                render={props => <EmployeeCompanyDetails {...props} />}
              />
               <Route
                path="/employee/upload/docs"
                exact
                render={props => <EmployeeUpload {...props} />}
              />
               <Route
                path="/employee/view/docs"
                exact
                render={props => <EmployeeViewDocuments {...props} />}
              />
            <Route
                path="/employee/add/newdocument"
                exact
                render={props => <EmployeeAddDocuments {...props} />}
              />
            <Route
                path="/employee/reminders"
                exact
                render={props => <EmployeeReminders {...props} />}
              />
            <Route
                path="/employee/view/reminder"
                exact
                render={props => <EmployeeViewReminder {...props} />}
              />
            <Route
                path="/employee/notifications"
                exact
                render={props => <Notifications {...props} />}
              />
            </EmployeLayout>
          </PrivateRoute>
          <PrivateRoute path="/company">
            <CompanyLayout>
              <Route
                path="/company/home"
                exact
                render={props => <CompanyHome {...props} />}
            />
              <Route
                path="/company/files"
                exact
                render={props => <CompanyDocuments {...props} />}
              />
               <Route
                path="/company/profile"
                exact
                render={props => <CompanyProfile {...props} />}
              />
              <Route 
                path="/company/document/:documentId"
                exact
                render={props => <CompanyDocumentDetails {...props} />}
              />
               <Route
                path="/company/notifications"
                exact
                render={props => <Notifications {...props} />}
              />
            </CompanyLayout>
          </PrivateRoute>
        </Switch>
      </Suspense>
    </Router>
  );
  // }
}

//@ts-ignore
export default withRouter(WebRoutes);
