import React from "react";
// Customizable Area Start
import {  Col, Row } from "antd";
import NavbarCompanyCommonComponent from "../../../../components/src/NavbarCompanyCommonComponent.web";
import TableCompanyCommonComponent from "../../../../components/src/TableCompanyCommonComponent.web";
import CompanyDocumentsController from "./CompanyDocumentsController.web";
import { pendingfilter } from "../assets";
import DocumentLoader from "../../../../components/src/DocumentLoader.web";
import FilterCompanyCommonComponent from "../../../../components/src/FilterCompanyCommonComponent.web";
// Customizable Area End

class CompanyDocuments  extends CompanyDocumentsController{
  // Customizable Area Start
  renderDocumentHomeHeaderPart = () => {
    return (
      <>
        <Row>
          <Col lg={12} xl={12} md={24} sm={24} xs={24}>
            <div className="header-entry-box">
              <h2 className="header-title">
                {this.state.dataList.length} Documents
              </h2>
              <div className="header-title-images">
                <button
                  type="button"
                  className="header-filter-sort"
                  onClick={this.handlefiltersortdocuments}
                >
                  {(!this.state.isOpenDocumentsFilter ||
                    this.state.isOpenDocumentsFilter) && (
                    <img src={pendingfilter} className="filterimgsort" />
                  )}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderDocumentsFilterSortPart = () => {
    return (
      <>
        <FilterCompanyCommonComponent 
        selectedList={this.state.selectedList}
        isOpenFilter={this.state.isOpenDocumentsFilter} 
        checkBoxChangeFilter={this.companyDocumentsCheckBoxFilterHandler} 
        data={this.state.dataList}
        resetFilters={this.resetFilters}
        />
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <>
        <NavbarCompanyCommonComponent
        isNotificationUpdate={false} {...this.props} />
        <div className="Main_container_company">
          {this.renderDocumentHomeHeaderPart()}
          {this.renderDocumentsFilterSortPart()}
          <TableCompanyCommonComponent
            data={
              this.state.filteredDocuments.length > 0
                ? this.state.filteredDocuments
                : this.state.dataList
            }
            props={this.props}
            callingColumnsFeature={"allFiles"}
            companyName=""
            />
        </div>
      </>
    );
  }

  // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(CompanyDocuments);
export {CompanyDocuments};
// Customizable Area End
