import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import { RouteComponentProps } from "react-router";
import axios from "axios";
import { withLoaderProps } from "../../../../components/src/DocumentLoader.web";
// Customizable Area End

export const configJSON = require("../config");

export type Props = RouteComponentProps &
  withLoaderProps & {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    match: any
    // Customizable Area End
  };

interface S {
    // Customizable Area Start
    companyDocumentDetails: any;
    apiToken: any;
    companyName:any;
    // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CompanyDocumentDetailsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCompanyDocumentDetailsApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      companyDocumentDetails: null,
      apiToken: "",
      companyName:"",
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.checkUserStatus();
  }

  checkUserStatus = async () => {
    this.setState({
      apiToken: localStorage.getItem("token")
    },()=>{
      if (this.state.apiToken) {
        this.getCompanyDocumentDetailsShow();
      }
    })
  };

  companyDocDetailsApiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.apiToken,
    };
    const companyDocDetailsReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    companyDocDetailsReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    companyDocDetailsReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    companyDocDetailsReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      companyDocDetailsReqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(companyDocDetailsReqMessage.id, companyDocDetailsReqMessage);
    return companyDocDetailsReqMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const companyDocumentDetailsApiReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let companyDocDetailsResJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let companyDocDetailsErrorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(companyDocDetailsResJson){
        if (companyDocDetailsResJson.status === 500) {
          MESSAGE.error(`${companyDocDetailsResJson.error}. Please try again later.`, 4);
          return;
        }else if (companyDocDetailsResJson.errors) {
            this.getCompanyDocumentDetailsShowFailureCallBack(companyDocumentDetailsApiReqCallId, companyDocDetailsResJson);
            this.props.hideLoader();
        }else if(companyDocDetailsResJson.data){
            this.getCompanyDocumentDetailsShowSuccessCallBack(companyDocumentDetailsApiReqCallId, companyDocDetailsResJson);
            this.props.hideLoader();
        }
      }else if (companyDocDetailsErrorResponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
  }

  getCompanyDocumentDetailsShow = async () => {
    this.props.showLoader();
    const {documentId} = this.props?.match?.params;
    this.getCompanyDocumentDetailsApiCallId = await this.companyDocDetailsApiCall({
      contentType: configJSON.getCompanyDocumentDetailsContentType,
      method: configJSON.getCompanyDocumentDetailsAPiMethod,
      endPoint: `${configJSON.getCompanyDocumentDetailsAPiEndPoint}?document_id=${documentId}`,
    });
  };

  getCompanyDocumentDetailsShowFailureCallBack = (apiCallId:any, error: any) => {
    if(apiCallId === this.getCompanyDocumentDetailsApiCallId){
      MESSAGE.error(error, 4);
    }
  };

  getCompanyDocumentDetailsShowSuccessCallBack = async (apiCallId:any, res: any) => {
    if(apiCallId === this.getCompanyDocumentDetailsApiCallId){
      this.setState({
        companyDocumentDetails: res.data,
        companyName: res.data.attributes.company.name
      })

    }
  };

  handleDownloadDocumentFile = (data: any) => {
    axios({
      url: data.url,
      method: "GET",
      responseType: "blob",
    }).then((response: any) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.name);
      document.body.appendChild(link);
      link.click();
    });
  };

}
  
