import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import axios, {CancelTokenSource} from "axios";
import { Location } from "history";
const { baseURL } = require("../../../framework/src/config");
const cancelTokens: { [key: string]: CancelTokenSource } = {};
export type NavObject=TypeNav
export interface TypeNav{
  addListener:Function
  goBack:Function
}
export type History=TypeHistory
export interface TypeHistory{
  push: Function
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: NavObject;
  screenId: string;
  // Customizable Area Start
  value: number;
  history: History ;
  location: Location<{ refernceFileId: string, refrenceFIleType: string }>;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  progress: number;
  cancelDownload: boolean;
  DownloadGetArray: { id: number; downloading: number, name:string, downloaded_at:string }[];
  UploadFileArray: string;
  ProgressBarShow: boolean;
  file: string;
  referance_id: string;
  referance_type: string;
  fileError: string;
  fielDownloading: File | null;
  downloadID: number;
  alredyUploadedFIleId: number
  ButtonActive: boolean;
  uploadSucessMesaage: string;
  referenceId: string;
  referenceType: string;
  fileAddress: string;
  filereferenceType:string;
  showLoader: boolean
  // Customizable Area End
}

interface SS {
  screenId: string;
  // Customizable Area Start
  // Customizable Area End
}


export default class DownloadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  GetDownloadApi: string = "";
  UploadDownloadPostApi: string = "";
  DownloadGetApiFileId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceDataMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      progress: 0,
      cancelDownload: false,
      DownloadGetArray: [],
      UploadFileArray: "[]",
      ProgressBarShow: false,
      file: '',
      referance_id: '2',
      referance_type: 'account',
      fileError: '',
      fielDownloading:  null,
      downloadID: 0,
      alredyUploadedFIleId: 0,
      uploadSucessMesaage: '',
      ButtonActive: true,
      referenceId: '',
      referenceType: '',
      fileAddress: this.props?.location?.state?.refernceFileId,
      filereferenceType: this.props?.location?.state?.refrenceFIleType,
      showLoader: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      if (apiRequestCallId === this.UploadDownloadPostApi) {
        this.setState({downloadID: responseJson.downloadable?.data?.attributes?.files.reverse()[0].id})
        this.setState({UploadFileArray:  responseJson.downloadable})
        this.setState({ uploadSucessMesaage: responseJson.message })
        this.setState({ ButtonActive: false })
      }
      this.setState({ showLoader: false })

      if (apiRequestCallId === this.GetDownloadApi) {
        this.setState({alredyUploadedFIleId: responseJson.downloadable?.data?.attributes?.files.reverse()[0].id})
        const DownloadFileData = responseJson?.downloadable?.data?.attributes?.files
        this.setState({fileAddress:responseJson?.downloadable?.data?.attributes.reference_id,filereferenceType: responseJson?.downloadable?.data?.attributes.reference_type,DownloadGetArray: DownloadFileData.map((Item:{id:string,downloading:boolean}) =>  {Item.downloading = false; return Item})});
      }

      if (apiRequestCallId === this.DownloadGetApiFileId) {
        const uploadedFile = responseJson
        this.setState({UploadFileArray: uploadedFile});
      }
      if (errorReponse) {
        this.parseApiErrorResponse(errorReponse);
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  async componentDidMount() {
    this.GetDownoldFiles();
  }

  UploadFIle = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ showLoader: true })
    let token = "";
    if (typeof window !== 'undefined') {
      token = localStorage.getItem('token') || ""
    }
    const headers = {
      token
    };

    this.setState({ fielDownloading: event.target.files?.[0] || null})
    let formdata = new FormData();
    formdata.append("files[]", event.target.files?.[0] || "");
    formdata.append("reference_id", this.state.referenceId.trim());
    formdata.append("reference_type", this.state.referenceType.trim());
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.UploadDownloadPostApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postUploadFileEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod

    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  GetDownoldFiles = () => {
    let token = "";
    if (typeof window !== 'undefined') {
      token = localStorage.getItem('token') || ""
    }
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.GetDownloadApi = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getDownloadFilesEndPoint}?reference_id=${this.state.fileAddress}&reference_type=${this.state.filereferenceType}`,
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleDownloadFiles = async (fileID: number, fileName: string) => {
    this.setState({ ButtonActive: true });
    let token = "";
    if (typeof window !== 'undefined') {
      token = localStorage.getItem('token') || "";
    }
    cancelTokens[fileID] = axios.CancelToken.source();
    try {
      const response = await axios({
        url: `${baseURL}/bx_block_download/downloadables/download?reference_id=${this.state.fileAddress}&reference_type=${this.state.filereferenceType}&file_id=${fileID}`,
        headers: { token: token },
        method: "GET",
        responseType: "blob", 
        cancelToken: cancelTokens[fileID].token,
        onDownloadProgress: (progressEvent) => {
          if (progressEvent.lengthComputable) {
            const percentComplete = (progressEvent.loaded / progressEvent.total) * 100; 
            this.setState({ progress: percentComplete });
            let proggressBarArray = this.state.DownloadGetArray.map((files:{id:number, downloading:number, name:string, downloaded_at:string})=>{
              if(fileID === files.id){
                files.downloading = percentComplete;
              }
              return files;
            });
            this.setState({DownloadGetArray: proggressBarArray},()=>{
              setTimeout(() => {
                this.GetDownoldFiles();
              }, 1000);
            });
          }
        },
      });
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const linkDownloadFile = document.createElement("a");
      linkDownloadFile.href = downloadUrl;
      linkDownloadFile.setAttribute("download", fileName);
      document.body.appendChild(linkDownloadFile); 
      linkDownloadFile.click();
    } catch (error) {
      if (axios.isCancel(error)) {
        alert("Request canceled");
      }
    }
  };
  
  
  handleCancelbtn = (idCancel:string) => {
    if (cancelTokens[idCancel]) {
      cancelTokens[idCancel].cancel(`Download canceled for file ${idCancel} by user`);
      delete cancelTokens[idCancel];
    }
  }
  onChangeReferenceId = (event: React.ChangeEvent<HTMLInputElement>) =>{
    this.setState({referenceId: event.target.value})
  }

  onChangeReferenceType = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({referenceType:  event.target.value})
  }

  handleClickViewUpload =() =>{
    this.props.history.push({ pathname: "/DownloadedFile", state: { refernceFileId: this.state.referenceId, refrenceFIleType : this.state.referenceType}})
  }
  CancelDownload = () => {
    this.setState({fielDownloading:null})
    this.setState({ ProgressBarShow: false })
  }
  // Customizable Area End
}
