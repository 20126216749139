export const filterimgsort = require("../assets/filterimg.png");
export const companylogoimg = require("../assets/Image.png");
export const createlogo = require("../assets/logo.png");
export const docsearch = require("../assets/search.png");
export const mail = require("../assets/mail.png");
export const editdetails = require("../assets/edit.png");
export const deleteimg = require("../assets/delete.png");
export const eyeslash = require("../assets/eyeslash.png");
export const galleryimg = require("../assets/gallery.png");
export const maleimg = require("../assets/img_avatar.png");
