Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";

exports.getEmployeeprofileAPiEndPoint = "account_block/employee/profile";
exports.employeeprofileAPiMethod = "GET";
exports.employeeprofileApiContentType = "application/json";

exports.postEmployeeEditprofileAPiEndPoint = "account_block/employee/update";
exports.employeeEditprofileApiContentType = "application/json";
exports.employeeEditprofileAPiMethod = "PUT";

exports.postChangePasswordAPiEndPoint = "account_block/employee/change_password";
exports.ChangePasswordAPiMethod = "POST";

exports.employeeEditprofileMultiPartApiContentType = "multipart/form-data";

exports.getcompanyprofileAPiEndPoint = "account_block/company/profile";
exports.companyprofileAPiMethod = "GET";
exports.companyprofileApiContentType = "application/json";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End