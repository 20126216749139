import React from "react";
// Customizable Area Start
import { CheckOutlined } from "@ant-design/icons";
import { Col, Row, Modal, Form, DatePicker, Input } from "antd";
import NavbarCommonComponent from "../../../../components/src/NavbarCommonComponent.web";
import { editdetails, deleteimg, maleimg } from "../assets";
import EmployeeCompanyDetailsController from "./EmployeeCompanyDetailsController.web";
import PaswordCommonComponent from "../../../../components/src/PasswordCommonComponent.web";
import DocumentLoader from "../../../../components/src/DocumentLoader.web";
import moment from "moment";
// Customizable Area End

class EmployeeCompanyDetails extends EmployeeCompanyDetailsController {
  // Customizable Area Start
  renderDetailsHeaderPart = () => {

    return (
      <>
        <div className="profile-header-container">
          <div className="profile-edit-box">
            <button
              type="button"
              className="edit-button"
              data-test-id="btnEditCompany"
              onClick={this.handleCompanyEdit}
            >
              <img src={editdetails} className="emopanymodalsopen" />
            </button>
            <button type="button" data-test-id="btnDeleteCompany" className="edit-button delete-company-btn" onClick={() => this.deleteCompanyModal()}>
              <img src={deleteimg} className="emopanymodalsopen" />
            </button>
          </div>
          <div className="profile-name-box">
            <button className="profile_insta_btn">
              <input
                id="myInput"
                type="file"
                data-test-id="fieldLogoUpdate"
                accept="image/*"
                ref={(ref) => this.upload = ref}
                style={{ display: "none" }}
                onChange={this.logoFilehandler}
              />
              {this.state.companyDetails?.logo?.url ?
                <img onClick={() => this.upload.click()} alt="" src={this.state.companyDetails.logo.url} className="profile-img" />
                :
                <img onClick={() => this.upload.click()} alt="" src={maleimg} className="profile-img" />
              }
            </button>
            <h3 className="profile-name-title">{this.state.companyDetails?.name}</h3>
          </div>
          <div className="employe-details-title-box">
            <p className="employe-details-title">Company details</p>
          </div>
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderCompanytype = () => {
    return (
      <>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Company type</p>
            <p className="details-title-text">{this.state.companyDetails?.company_type}</p>
          </div>
        </Col>
      </>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  renderCompanyID = () => {
    return (
      <>
        <div className="details-text-box personal-details-padding">
          <p className="details-title">Company ID</p>
          <p className="details-title-text">{this.state.companyDetails?.id}</p>
        </div>
      </>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  renderAssignedEmployeedetails = () => {
    return (
      <>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Employee contact details</p>
            <p className="details-title-text">
              {this.state.companyDetails?.employee_contact_details?.email}
              <br />
              +971 {this.state.companyDetails?.employee_contact_details?.contact}
            </p>
          </div>
        </Col>
      </>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  renderStaffDetails = () => {
    if (this.state.companyDetails?.staffs?.length === 0) {
      return <></>
    }
    return (
      <Col lg={12} xl={12} md={12} sm={24} xs={24}>
        <div className="details-text-box personal-details-padding">
          <p className="details-title">Staffs details</p>
          {this.state.companyDetails?.staffs?.map((staff: any) => {
            return (
              <p className="details-title-text">
                {staff?.name}
                <br />
                {staff?.email}
              </p>
            )
          })}
        </div>
      </Col>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  renderEditStaffDetails = () => {
    return (
      <Col lg={12} xl={12} md={12} sm={24} xs={24}>
        <div className="details-text-box personal-details-padding password-change">
          <div className="staff-container">
            <div className="new-staff-box">
              <p className="details-title">Staffs details</p>
              <button type="button" className="files-button" onClick={this.addNewStaffModal}>
                Add new staff
              </button>
            </div>
            {this.state.companyDetails?.staffs?.map((staff: any) => {
              return (
                <div className="new-staff-box">
                  <p className="details-title-text">
                    {staff?.name}
                    <br />
                    {staff?.email}
                  </p>
                  <button type="button" className="files-button" onClick={()=>this.removeStaffModal(staff.id)}>
                    Remove staff
                  </button>
                </div>
              )
            })}
          </div>

        </div>
      </Col>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  renderCompanyContactandEmployeeassigned = () => {
    return (
      <>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Company contact</p>
            <p className="details-title-text">+971 {this.state.companyContact}</p>
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Rent amount</p>
            <p className="details-title-text">{parseFloat(this.state.companyRentAmmount).toFixed(2)}</p>
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Rent due date</p>
            <p className="details-title-text">{this.state.companyRentDueDate}</p>
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Employee assigned</p>
            <p className="details-title-text">{this.state.employeeName}</p>
          </div>
        </Col>
      </>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  renderEditCompanyContactandEmployeeassigned = () => {
    return (
      <>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Company contact</p>
            <input
              className="details-title-text edit-input-text"
              value={this.state.companyContact}
              type="input"
              name="contact"
              data-test-id="inputCompanyContact"
              onChange={this.handleCompanyDetailsEditContact}
            />
            {this.state.emptyFieldContact ? <p className='error-password'>This field cannot remain empty</p> : ""}
            {this.state.alreadyTakenContact ? <p className='error-password'>Company contact has already been taken</p> : ""}
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Rent amount</p>
            <input
              className="details-title-text edit-input-text"
              value={this.state.companyRentAmmount}
              name="rentamount"
              onChange={this.handleCompanyDetailsEditRentAmmount}
            />
            {this.state.emptyFieldRentAmmount ? <p className='error-password'>This field cannot remain empty</p> : ""}
            {this.state.negativeValue && <p className='error-password'>Please input positive  numbers only</p>}
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Rent due date</p>
            <DatePicker
              className="details-title-text edit-input-text"
              placement="bottomRight"
              name="rentduedate"
              onChange={this.handleCompanyDetailsEditRentDueDate}
              disabledDate={(current: any) => {
                return (
                  current && current < moment().startOf("day")
                );
              }}
            // value={moment(this.state.companyRentDueDate)}
            />
            {this.state.emptyFieldRentDueDate ? <p className='error-password'>This field cannot remain empty</p> : ""}
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Employee assigned</p>
            <p className="details-title-text">{this.state.employeeName}</p>
          </div>
        </Col>
      </>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  renderCompanyDetailsPart = () => {
    return (
      <>
        <div className="personal-details-box">
          <Row gutter={[16, 16]} className="details-row">
            {this.renderCompanytype()}
            <Col lg={12} xl={12} md={12} sm={24} xs={24}>
              <div className="details-text-box personal-details-padding">
                <p className="details-title">Company stakeholder </p>
                <p className="details-title-text">{this.state.companyStakeholder}</p>
              </div>
            </Col>
            <Col lg={12} xl={12} md={12} sm={24} xs={24}>
              <div className="details-text-box personal-details-padding">
                <p className="details-title">Company Email </p>
                <p className="details-title-text">{this.state.companyEmail}</p>
              </div>
            </Col>
            <Col lg={12} xl={12} md={12} sm={24} xs={24}>
              {this.renderCompanyID()}
            </Col>
            <Col lg={12} xl={12} md={12} sm={24} xs={24}>
              <div className="details-text-box personal-details-padding">
                <p className="details-title">Address</p>
                <p className="details-title-text">{this.state.companyAddress}</p>
              </div>
            </Col>
            {this.renderCompanyContactandEmployeeassigned()}
            <Col lg={12} xl={12} md={12} sm={24} xs={24}>
              <div className="details-text-box personal-details-padding password-change">
                <div>
                  <p className="details-title">Password</p>
                  <p className="details-title-text">***********</p>
                </div>
              </div>
            </Col>
            {this.renderAssignedEmployeedetails()}
            {this.renderStaffDetails()}
          </Row>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderEmployeeCompanyDetailsMainPart = () => {
    return (
      <>
        {this.renderDetailsHeaderPart()}
        {this.renderCompanyDetailsPart()}
        {this.renderDeleteCompanyModal()}
        {this.renderConfirmDeleteCompanyModal()}
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderEditCompanyDetailsPart = () => {
    return (
      <>
        <div className="personal-details-box">
          <Row gutter={[16, 16]} className="details-row">
            {this.renderCompanytype()}
            <Col lg={12} xl={12} md={12} sm={24} xs={24}>
              <div className="details-text-box personal-details-padding">
                <p className="details-title">Company stakeholder</p>
                <input
                  className="details-title-text edit-input-text"
                  value={this.state.companyStakeholder}
                  type="input"
                  name="Stakeholder"
                  data-test-id="inputStakeholder"
                  onChange={this.handleCompanyDetailsEditstakeholder}
                />
                {this.state.emptyFieldStakeholder ? <p className='error-password'>This field cannot remain empty</p> : ""}
              </div>
            </Col>
            <Col lg={12} xl={12} md={12} sm={24} xs={24}>
              <div className="details-text-box personal-details-padding">
                <p className="details-title">Company Email</p>
                <input
                  className="details-title-text edit-input-text"
                  value={this.state.companyEmail?.trim()}
                  type="input"
                  name="Email"
                  onChange={this.handleCompanyDetailsEditemail}
                />
                {this.state.emptyFieldEmail ? <p className='error-password'>This field cannot remain empty </p> : ""}
                {this.state.emailErrorMessage ? <p className='error-password'>Invalid Email</p> : ""}
                {this.state.alreadyTaken ? <p className='error-password'>Company email has already been taken</p> : ""}
              </div>
            </Col>
            <Col lg={12} xl={12} md={12} sm={24} xs={24}>
              {this.renderCompanyID()}
            </Col>
            <Col lg={12} xl={12} md={12} sm={24} xs={24}>
              <div className="details-text-box personal-details-padding">
                <p className="details-title">Address</p>
                <input
                  className="details-title-text edit-input-text details-textarea"
                  value={this.state.companyAddress}
                  name="Address"
                  onChange={this.handleCompanyDetailsEditaddress}
                />
                {this.state.emptyFieldAddress ? <p className='error-password'>This field cannot remain empty</p> : ""}
              </div>
            </Col>
            {this.renderEditCompanyContactandEmployeeassigned()}
            <Col lg={12} xl={12} md={12} sm={24} xs={24}>
              <div className="details-text-box personal-details-padding password-change">
                <div>
                  <p className="details-title">Password</p>
                  <p className="details-title-text">***********</p>
                </div>
                <button type="button" className="pswrd-btn" onClick={this.changePasswordCompanyModal}>
                  Change password
                </button>
              </div>
            </Col>
            {this.renderAssignedEmployeedetails()}
            {this.renderEditStaffDetails()}
          </Row>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderSaveDetailsbutton = () => {
    return (
      <>
        <div className="save-btn-container">
          <button type="button" data-test-id="btnSaveDetails" className="pswrd-btn save-btn" onClick={() => this.editEmployeeCompanyDetailShow()}>
            Save Details
          </button>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderDeleteCompanyModal = () => {
    return (
      <>
        <Modal
          closable={false}
          footer={null}
          centered
          className="delete-main-modal"
          open={this.state.deletemodal}
          onCancel={this.handleCancelModal}
        >
          <div className="modal-content">
            <h3 className="modal-heading">
              You're about to delete the account!
            </h3>
          </div>
          <div className="password-box-container">
            <p className="delete-text-details">
              All information about the company will be
              deleted, you won't be able to see them again.
            </p>
          </div>
          <div className="pswrd-submit-btn">
            <button type="button" className="pswrd-btn companymodalbutton" onClick={this.handleCancelModal}>
              Cancel
            </button>
            <button
              type="button"
              className="pswrd-btn save-btn companymodalbutton"
              onClick={() => this.deleteEmployeeCompany()}
            >
              Delete
            </button>
          </div>
        </Modal>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderConfirmDeleteCompanyModal = () => {
    return (
      <>
        <Modal
          closable={false}
          footer={null}
          centered
          className="confirm-delete-main-modal"
          open={this.state.confirmdeletemodal}
          onCancel={this.handleCancelDeleteModal}
        >
          <div className="modal-deleted-succesful">
            <p>
              <CheckOutlined translate={undefined} />
            </p>
            <p className="change-pswrd-title">Deleted Succesfully</p>
          </div>
        </Modal>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderSavePasswordbutton = () => {
    return (
      <>
        <Form.Item>
          <div className='pswrd-submit-btn'>
            <button type='button' className='pswrd-btn close-btn' onClick={this.companyPasswordModalCancel}>
              Close
            </button>
            <button type='submit' className='pswrd-btn save-btn close-btn' >
              Submit
            </button>
          </div>
        </Form.Item>
      </>
    )
  }
  // Customizable Area End
  // Customizable Area Start
  renderAddNewStaffbutton = () => {
    return (
      <>
        <Form.Item>
          <div className='pswrd-submit-btn'>
            <button type='button' className='pswrd-btn close-btn' onClick={this.addNewStaffModalCancel}>
              Close
            </button>
            <button type='submit' className='pswrd-btn save-btn close-btn' >
              Add
            </button>
          </div>
        </Form.Item>
      </>
    )
  }
  // Customizable Area End
  // Customizable Area Start
  renderCompanyChangePasswordModal = () => {
    return (
      <>
        <Modal
          closable={false}
          open={this.state.companyChangeModal}
          onCancel={this.companyPasswordModalCancel}
          footer={null}
          centered
          className='main-modal'
        >
          <div className='modal-content'>
            <h3 className="modal-heading"> Change password</h3>
          </div>
          <Form
            name='basic'
            layout='vertical'
            initialValues={{ remember: false }}
            onFinish={this.onPassowrdFinish}
            autoComplete='off'
            className='login-indv-form'
            ref={this.formRef}
          >
            <PaswordCommonComponent handleNewpassword={undefined} handleConfirmNewpassword={undefined} />
            {this.renderSavePasswordbutton()}
          </Form>
        </Modal>

      </>
    )
  }
  // Customizable Area End
  // Customizable Area Start
  renderAddNewStaffModal = () => {
    return (
      <>
        <Modal
          closable={false}
          open={this.state.companyAddNewStaffModal}
          onCancel={this.addNewStaffModalCancel}
          footer={null}
          centered
          className='main-modal'
        >
          <div className='modal-content'>
            <h3 className="modal-heading"> Add new staff</h3>
          </div>
          <Form
            name='basic'
            layout='vertical'
            initialValues={{ remember: false }}
            onFinish={this.onAddNewStaffFinish}
            autoComplete='off'
            className='login-indv-form'
            ref={this.formRef}
          >
            <Row className="">
              <p className="form-text">Staff name</p>
            </Row>
            <Form.Item
              name="staffname"
              label=""
              rules={[
                {
                  required: true,
                  message: "Please input staff name",
                },
              ]}
            >
              <Input name="staffname" className="staff--input" />
            </Form.Item>
            <Row className="">
              <p className="form-text">Staff email</p>
            </Row>
            <Form.Item
              label=""
              name="email"
              rules={[
                { required: true, message: "Please input staff email" },
                {
                  type: "email",
                  message: "Please enter a valid Email address",
                },
              ]}
            >
              <Input className="staff--input" name="email" onChange={this.handleNewStaffEmail}/>
            </Form.Item>
            {this.state.alreadyTakenStaffEmail ? <p className='error-password'>Email has already been taken</p> : ""}
            {this.renderAddNewStaffbutton()}
          </Form>
        </Modal>

      </>
    )
  }
  // Customizable Area End
  // Customizable Area Start
  renderRemoveModal = () => {
    return (
      <>
        <Modal
          closable={false}
          footer={null}
          centered
          className="delete-main-modal"
          open={this.state.removeStaff}
          onCancel={this.handleCancelRemoveStaffModal}
        >
          <div className="modal-content">
            <h3 className="modal-heading">
            You're about to remove the staff!
            </h3>
          </div>
          <div className="pswrd-submit-btn">
            <button type="button" className="pswrd-btn companymodalbutton" onClick={this.handleCancelRemoveStaffModal}>
              Cancel
            </button>
            <button
              type="button"
              data-test-id="btnDeleteHandler"
              className="pswrd-btn save-btn companymodalbutton"
              onClick={this.deleteCompanyStaff}
            >
              Delete
            </button>
          </div>
        </Modal>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderEditEmployeeCompanyDetailsMainPart = () => {
    return (
      <>
        {this.renderDetailsHeaderPart()}
        {this.renderEditCompanyDetailsPart()}
        {this.renderSaveDetailsbutton()}
        {this.renderDeleteCompanyModal()}
        {this.renderConfirmDeleteCompanyModal()}
        {this.renderCompanyChangePasswordModal()}
        {this.renderAddNewStaffModal()}
        {this.renderRemoveModal()}
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <>
        <NavbarCommonComponent
          displaySpinner={() => { } }
          hideLoader={() => { } }
          showLoader={() => { } }
          history={undefined}
          navigation={undefined}
          id={""}
          location={undefined} isNotificationUpdate={false}/>
        <div className="Employee-Pofile-container">
          {this.state.editbutton
            ? this.renderEditEmployeeCompanyDetailsMainPart()
            : this.renderEmployeeCompanyDetailsMainPart()}

        </div>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(EmployeeCompanyDetails);
export { EmployeeCompanyDetails };
// Customizable Area End