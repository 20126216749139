import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../../components/src/DocumentLoader.web";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    isNotificationUpdate:boolean
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  isUserLoggedIn: any;
  userValues: any;
  isHomeFiltersort: any;
  dataList: any;
  isLogout: any;
  employeeName: any;
  dataForFilterSort: any;
  indOrderNext: any;
  itemsPerPage: any;
  allData: any;
  pendingHomeDueDate: any;
  pageSize: any;
  isReadDataCount:any;
  getNotificationsData:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HomeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getEmployeeHomeApiCallId: any;
  deleteFcmTokenApiCallId: any;
  getNotificationApiCallID:any
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      isUserLoggedIn: false,
      userValues: null,
      isHomeFiltersort: false,
      dataList: [],
      isLogout: false,
      employeeName: "",
      dataForFilterSort: [],
      allData: [],
      indOrderNext: 0,
      itemsPerPage: 5,
      pendingHomeDueDate: "",
      pageSize: null,
      isReadDataCount:"",
      getNotificationsData:[]
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getEmployeeHomeDetailsShow();
    this.notifications();
  }

  async componentDidUpdate(prevProps: any) {
    if(prevProps.isNotificationUpdate !== this.props.isNotificationUpdate){
      this.notifications()
    }
  }
  employeeHomeApiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = (await localStorage.getItem("token")) || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let employeeHomeReqJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let employeeHomeErrorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (employeeHomeReqJson) {
        if (employeeHomeReqJson.status === 500) {
          MESSAGE.error(
            `${employeeHomeReqJson.error}. Please try again later.`,
            4
          );
          return;
        } else if (employeeHomeReqJson.data) {
          this.props.hideLoader();
          this.getEmployeeHomeShowSuccessCallBack(
            apiRequestCallId,
            employeeHomeReqJson
          );
        } 
        else if (employeeHomeReqJson.errors) {
          this.props.hideLoader();
          this.getEmployeeHomeShowFailureCallBack(
            apiRequestCallId,
            employeeHomeReqJson
          );
        } 
        else if (employeeHomeReqJson.message) {
          this.deleteFcmTokenShowSuccessCallBack(apiRequestCallId);
        }
      } else if (employeeHomeErrorResponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
  }

  handlefiltersort = () => {
    this.setState({
      isHomeFiltersort: !this.state.isHomeFiltersort,
      dataForFilterSort:
        this.state.dataForFilterSort.length > 0
          ? this.state.dataForFilterSort
          : this.state.dataList,
    });
  };
  handleCreateCompanyAccount = () => {
    this.props.history.push("/employee/create/companyaccount");
  };
  handlePageClick = (e: any) => {
    let event = e.selected;
    this.setState({ indOrderNext: event + 1 }, () =>
      this.getEmployeeHomeDetailsShow()
    );
  };
  getEmployeeHomeDetailsShow = async () => {
    this.props.showLoader();
    this.getEmployeeHomeApiCallId = await this.employeeHomeApiCall({
      contentType: configJSON.getEmployeeHomeApiContentType,
      method: configJSON.getEmployeeHomeAPiMethod,
      endPoint: configJSON.getEmployeeHomeAPiEndPoint,
    });
  };

  getEmployeeHomeShowFailureCallBack = (apiRequestCallId: any, error: any) => {
    if (apiRequestCallId === this.getEmployeeHomeApiCallId) {
      MESSAGE.error(error, 4);
    }
    if (apiRequestCallId === this.getNotificationApiCallID) {
      MESSAGE.error(error, 4);
    }
  };

  getEmployeeHomeShowSuccessCallBack = async (
    apiRequestCallId: any,
    res: any
  ) => {
    if (apiRequestCallId === this.getEmployeeHomeApiCallId) {
      res.data.attributes.companies.map((val: any, index: any) => {
        val["isSelect"] = false;
        let arrayData: any = [];
        val?.data?.attributes?.documents?.pending?.map((inerItem: any) => {
          let deadDate = inerItem?.deadline_date;
          let eventDateSeconds: any = new Date(deadDate);
          arrayData.push(eventDateSeconds);
        });
        const minDate = new Date(Math.min.apply(null, arrayData));
        val.data.attributes["leastDeadlineDate"] = moment(minDate).format(
          "YYYY/MM/DD"
        );
      });
      this.setState({
        dataList: res.data.attributes.companies,
        employeeName: res.data.attributes.name,
        allData: res.data.attributes,
      });
    }
    if (apiRequestCallId === this.getNotificationApiCallID) {
      let unReadDataCount = res?.data?.filter((item:any)=>{ return item?.attributes?.is_read === false} )
      this.setState({
          getNotificationsData: res?.data, 
          isReadDataCount : unReadDataCount.length ,
      });
    }
  };

  handleLogout = async () => {
    localStorage.clear();
      this.setState(
        {
          isLogout: true,
        },
        () => this.props.history.push("/")
      );
  };

  filtredData = (data: any) => {
    const filtredData = this.state.dataList;
    let filtredDataForSelect = filtredData.filter((compItem: any) => {
      if (compItem.isSelect) {
        return compItem;
      }
    });
    this.setState({
      dataForFilterSort: filtredDataForSelect,
    });
  };
  checkBoxChangeFilter = (e: any, item: any) => {
    const companData = this.state.dataList;
    if (e.target.checked) {
      let dataItemForSelect = companData.map((compItem: any) => {
        if (compItem.data.id === item.data.id) {
          compItem.isSelect = true;
        }
      });
      this.filtredData(dataItemForSelect);
    } else {
      let dataItemForSelect = companData.map((compItem: any) => {
        if (compItem.data.id === item.data.id) {
          compItem.isSelect = false;
        }
      });
      this.filtredData(dataItemForSelect);
    }
  };

  handleClearFilter = () => {
    let filteredData = this.state.dataList;
    let b = filteredData.map((filItem: any) => {
      if (filItem.isSelect) {
        filItem.isSelect = false;
      }
      return filItem;
    });
    this.setState({
      dataForFilterSort: [],
      dataList: b,
    });
  };

  deleteFcmToken = async () => {
    this.deleteFcmTokenApiCallId = await this.employeeHomeApiCall({
      method: configJSON.deleteFcmTokenAPiMethod,
      endPoint: configJSON.deleteFcmTokenAPiEndPoint,
      contentType: configJSON.deleteFcmTokenApiContentType,
    });
    localStorage.clear();
    };

  deleteFcmTokenShowFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  deleteFcmTokenShowSuccessCallBack = async (apiRequestCallId: any) => {
    if (apiRequestCallId == this.deleteFcmTokenApiCallId) {
      this.handleLogout();
    }
  };

  notifications = async () => {
    this.getNotificationApiCallID = await this.employeeHomeApiCall({
      contentType: configJSON.getNotificationsApiContentType,
      method: configJSON.getNotificationsAPiMethod,
      endPoint: configJSON.getNotificationsAPiEndPoint,
    });
  };



}
// Customizable Area End
