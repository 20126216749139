import React from "react";
// Customizable Area Start
import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { logo, home,shop, pending, filter, file } from "../assets";
const { Content, Sider } = Layout;
// Customizable Area End
const EmployeLayout = (props: any) => {
  // Customizable Area Start
  return (
    <>
      <Layout className="layout-container">
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {   
          }}
          onCollapse={(collapsed, type) => {
 }}
          className="indv-dash-sidebar"
          width={220}
        >
          <div className="indv-logo-head ">
            <Link to="/employee/home">
              <img src={logo} alt="LOGO" className="layout-logo" />
            </Link>
          </div>
          <Menu theme="dark" mode="inline" className="menumargin">
            <Menu.Item key="1">
              <Link
                to="/employee/home"
                className={
                  window.location.pathname === "/employee/home"
                    ? "active-link"
                    : "ant-menu-item"
                }
              >
                <span className="indv-dash-side-img">
                  <img src={home} className="homeimg" />
                </span>{" "}
                Home
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link
                to="/employee/company"
                className={
                  window.location.pathname === "/employee/company"
                    ? "active-link"
                    : "ant-menu-item"
                }
              >
                <span className="indv-dash-side-img">
                  <img src={shop} />
                </span>{" "}
                Companies
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link
                to="/employee/files"
                className={
                  window.location.pathname === "/employee/files"
                    ? "active-link"
                    : "ant-menu-item"
                }
              >
                <span className="indv-dash-side-img">
                  <img src={file} />
                </span>{" "}
                All files
              </Link>
            </Menu.Item>
            <Menu.Item key="4">
            <Link
                to="/employee/upload/docs"
                className={
                  window.location.pathname === "/employee/upload/docs"
                    ? "active-link"
                    : "ant-menu-item"
                }
              >
                <span className="indv-dash-side-img">
                  <img src={pending} />
                </span>{" "}
                Upload Document
                </Link>
            </Menu.Item>
            <Menu.Item key="5">   
                <Link
                to="/employee/reminders"
                className={
                  window.location.pathname === "/employee/reminders"
                    ? "active-link"
                    : "ant-menu-item"
                }
              >
                 <span className="indv-dash-side-img">
                  <img src={filter} />
                </span>{" "}
                Reminders
                </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout className="indv-layout">
          <Content>{props.children}</Content>
        </Layout>
      </Layout>
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default EmployeLayout;
// Customizable Area End
