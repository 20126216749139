import React from "react";
// Customizable Area Start
import { Col, Row } from 'antd';
import NavbarCompanyCommonComponent from "../../../../components/src/NavbarCompanyCommonComponent.web";
import DocumentLoader from "../../../../components/src/DocumentLoader.web";
import CompanyDocumentDetailsController from "./CompanyDocumentDetailsController.web";
// Customizable Area End

class CompanyDocumentDetails extends CompanyDocumentDetailsController{
    // Customizable Area Start
    renderCompanyDocumentDetailsHeaderPart = () => {
        return (
            <>
                <div className="profile-name-box">
                <h3 className="profile-name-title">{this.state.companyDocumentDetails?.attributes?.name}</h3>
                </div>
                <div className='header-container'>
                        <div className='employe-details-title-box'>
                            <p className='employe-details-title'>
                                Document details
                            </p>
                        </div>
                </div>
            </>
                
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderCompanyDocumentDetailsPart = () => {
        return (
                <div className="company-profiles-details-box">
                    <Row gutter={[16, 16]} className="details-row">
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Document Id
                                </p>
                                <p className='details-title-text'>
                                    {this.state.companyDocumentDetails?.attributes?.id}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Document Name
                                </p>
                                <p className='details-title-text'>
                                    {this.state.companyDocumentDetails?.attributes?.name}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Document Type
                                </p>
                                <p className='details-title-text'>
                                    {this.state.companyDocumentDetails?.attributes?.document_type}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Deadline Date
                                </p>
                                <p className='details-title-text'>
                                    {this.state.companyDocumentDetails?.attributes?.deadline_date}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Upload Date
                                </p>
                                <p className='details-title-text'>
                                    {this.state.companyDocumentDetails?.attributes?.upload_date}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Uploaded By
                                </p>
                                <p className='details-title-text'>
                                    {this.state.companyDocumentDetails?.attributes?.uploaded_by}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Status
                                </p>
                                <p className='details-title-text'>
                                    {this.state.companyDocumentDetails?.attributes?.status}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderCompanyDodumentDetailsFilesPart = () => {
        if(this.state.companyDocumentDetails?.attributes?.files === null || this.state.companyDocumentDetails?.attributes?.files?.length === 0) {
            return <></>
        }

        return (
            <>
            <div className='header-container'>
                    <div className='employe-details-title-box'>
                        <p className='employe-details-title'>
                            Files
                        </p>
                    </div>
                </div>
            <div className="doc-details-box">
                <div className="personal-details-box height-class">
                    <Row>
                    <Col lg={24} xl={24} md={24} sm={24} xs={24}>
                        {this.state.companyDocumentDetails?.attributes?.files?.map((item:any,index:any) => {
                        return (
                            <>
                            <div className="files-box">
                                <div className="details-text-box">
                                <p className="details-title">{item.name}</p>
                                </div>
                                <div className="files-button-box">
                                <button
                                    type="button"
                                    data-test-id="btnDownloadDocumentFile"
                                    className="files-button files-button-margin"
                                    onClick={()=> this.handleDownloadDocumentFile(item)}
                                >
                                    Download
                                </button>
                                </div>
                            </div>
                            </>
                        );
                        })
                    }
                    </Col>
                    </Row>
                </div>
            </div>
            </>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderCompanyDocumentDetailsView = () => {
        return (
            <>
                {this.renderCompanyDocumentDetailsHeaderPart()}
                {this.renderCompanyDocumentDetailsPart()}
                {this.renderCompanyDodumentDetailsFilesPart()}
            </>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    render() {
        return (
            <>
                <NavbarCompanyCommonComponent 
                isNotificationUpdate={false} {...this.props}/>
                <div className="Main_container_document_details">
                    {this.renderCompanyDocumentDetailsView()}
                </div>
            </>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
export default DocumentLoader(CompanyDocumentDetails)
export {CompanyDocumentDetails}
// Customizable Area End
