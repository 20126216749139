import React from "react";
// Customizable Area Start
import { Col, Row } from "antd";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import TableCommonComponent from "../../../components/src/TableCommonComponent.web";
import {pendingfilter} from "./assets"
import FilterCommonComponent from "../../../components/src/FilterCommonComponent.web";
import EmployeeUploadDocController from "../src/EmployeeUploadDocController.web";
import DocumentLoader from "../../../components/src/DocumentLoader.web";
// Customizable Area End

 class EmployeeUpload extends EmployeeUploadDocController {
  // Customizable Area Start
  renderPendingHomeHeaderPart = () => {
    return (
      <>
        <Row>
          <Col lg={12} xl={12} md={24} sm={24} xs={24}>
            <div className="header-entry-box">
              <h2 className="header-title">{this.state?.allDocument?.length} Documents</h2>
              <button
                type="button"
                data-test-id="btnFilterCountry"
                className="header-filter-sort upload-filter"
                onClick={() => this.handleUploadFiltersort()}
              >
                {(!this.state.isUploadFiltersort ||
                  this.state.isUploadFiltersort) && (
                    <img src={pendingfilter} className="pending-filter-img"/>
                )}
              </button>
            </div>
          </Col>
        </Row>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <>
        <NavbarCommonComponent displaySpinner={() => { } } hideLoader={() => { } } showLoader={() => { } } history={undefined} navigation={undefined} id={''} location={undefined} isNotificationUpdate={false} />
        <div className="Main_container home-height-set">
          {this.renderPendingHomeHeaderPart()}
          <FilterCommonComponent dataList={this.state.allDocument} isFiltersort={this.state.isUploadFiltersort} checkBoxChangeFilter={this.uploadCheckBoxFilter} clearFilter={this.handleUploadClearFilter} />
          <TableCommonComponent
          paginationTitle="Documents per page"
          handlePageSize={this.handleChangePageSize}
          pageSize={this.state.pageSize}
            dataList={this.state.dataForUploadFilterSort.length > 0
              ? this.state.dataForUploadFilterSort
              : this.state.allDocument}
            callingColumnsFeature={"upload"} props={undefined} employeeName={undefined} dueDateSet={undefined} upload={undefined} uploadDoc={this.postUploadDocDetailShow} uplodeId={this.uplodeId} />
        </div>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(EmployeeUpload);
export { EmployeeUpload };
// Customizable Area End
