Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.getEmployeeHomeAPiEndPoint = "account_block/employee/home";
exports.getEmployeeHomeAPiMethod = "GET";
exports.getEmployeeHomeApiContentType = "application/json";

exports.getEmployeeCompaniesAPiEndPoint = "account_block/employee/companies";
exports.getEmployeeCompaniesAPiMethod = "GET";
exports.getEmployeeCompaniesApiContentType = "application/json";

exports.getCreateCompanyTypeApiEndPoint = "bx_block_types/company_types";
exports.getCreateCompanyTypeApiMethod = "GET";
exports.getCreateCompanyTypeApiContentType = "application/json";

exports.getCreateDocumentTypeApiEndPoint = "bx_block_types/document_types";
exports.getCreateDocumentTypeApiMethod = "GET";
exports.getCreateDocumentTypeApiContentType = "application/json";

exports.getRecentSearchApiEndPoint = "recent_searches";
exports.getRecentSearchApiMethod = "GET";
exports.getRecentSearchApiContentType = "application/json";

exports.postSearchApiEndPoint = "recent_searches";
exports.postSearchApiMethod = "POST";
exports.postSearchApiContentType = "application/json";

exports.putCompaniesDetailsAPiEndPoint = "account_block/employee/company_update";
exports.putCompaniesDetailsAPiMethod = "PUT";
exports.putCompaniesDetailsApiContentType = "multipart/form-data";

exports.deleteCompaniesAPiEndPoint = "account_block/employee/company_delete";
exports.deleteCompaniesAPiMethod = "DELETE";
exports.deleteCompaniesApiContentType = "application/json";

exports.deleteRecentSearchAPiEndPoint = "recent_searches";
exports.deleteRecentSearchAPiMethod = "DELETE";
exports.deleteRecentSearchApiContentType = "application/json";

exports.PostCompaniesPasswordAPiEndPoint = "account_block/employee/change_password/company";
exports.PostCompaniesPasswordAPiMethod = "POST";
exports.PostCompaniesPasswordApiContentType = "application/json";

exports.getCompanyHomeAPiEndPoint = "account_block/company/home";
exports.getCompanyHomeApiMethod = "GET";
exports.getCompanyHomeAPiContentType = "application/json";

exports.postCreateCompanyAPiEndPoint = "account_block/company/new";
exports.postCreateCompanyAPiMethod = "POST";
exports.postCreateCompanyApiContentType = "application/json";

exports.postAddNewStaffAPiEndPoint = "/employee/company";
exports.postAddNewStaffAPiMethod = "POST";
exports.postAddNewStaffApiContentType = "application/json";

exports.deleteCompanyStaffAPiEndPoint = "employee/company";
exports.deleteCompanyStaffAPiMethod = "DELETE";
exports.deleteCompanyStaffApiContentType = "application/json";

exports.deleteFcmTokenAPiEndPoint = "account_block/delete_fcm_token";
exports.deleteFcmTokenAPiMethod = "DELETE";
exports.deleteFcmTokenApiContentType = "application/json";

exports.getNotificationsAPiEndPoint = "bx_block_notifications/notifications";
exports.getNotificationsAPiMethod = "GET";
exports.getNotificationsApiContentType = "application/json";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
