import React from 'react';
// Customizable Area Start
import { Col, Row } from 'antd';
import NavbarCompanyCommonComponent from '../../../../components/src/NavbarCompanyCommonComponent.web';
import DocumentLoader from "../../../../components/src/DocumentLoader.web"
import CompanyProfileController from './CompanyProfileController.web';
// Customizable Area End

class CompanyProfile extends CompanyProfileController{
    // Customizable Area Start
    renderProfileHeaderPart = () => {
        return (
                <div className='profile-header-container'>
                    <div className='profile-name-box'>
                           <img alt="" src={this.state.companyprofiledetails?.logo?.url} className="profile-img" />
                        <h3 className='profile-name-title'>
                            {this.state.companyprofiledetails?.name}
                        </h3>
                    </div>
                    <div className='employe-details-title-box'>
                        <p className='employe-details-title'>
                            Company details
                        </p>
                    </div>
                </div>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderCompanyDetailsPart = () => {
        return (
                <div className="company-profiles-details-box">
                    <Row gutter={[16, 16]} className="details-row">
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Company type
                                </p>
                                <p className='details-title-text'>
                                    {this.state.companyprofiledetails?.company_type}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Company Stakeholder
                                </p>
                                <p className='details-title-text'>
                                    {this.state.companyprofiledetails?.stakeholder}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Company Email
                                </p>
                                <p className='details-title-text'>
                                    {this.state.companyprofiledetails?.email}
                                </p>
                            </div>
                        </Col> 
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Company ID
                                </p>
                                <p className='details-title-text'>
                                    {this.state.companyprofiledetails?.id}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Address
                                </p>
                                <p className='details-title-text'>
                                    address
                                    {this.state.companyprofiledetails?.address}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Contact details
                                </p>
                                <p className='details-title-text'>
                                   +971 {this.state.companyprofiledetails?.contact}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Rent ammount
                                </p>
                                <p className='details-title-text'>
                                   {parseFloat(this.state.companyprofiledetails?.rent_amt).toFixed(2)}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Rent due date
                                </p>
                                <p className='details-title-text'>
                                   {this.state.companyprofiledetails?.rent_due_date}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Employee assigned
                                </p>
                                <p className='details-title-text'>
                                    {this.state.companyprofiledetails?.employee?.name}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Password
                                </p>
                                <p className='details-title-text'>
                                    *********
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='company-details-text-box'>
                                <p className='details-title'>
                                    Employee contact details
                                </p>
                                <div className='contact-details'>
                                    <p>{this.state.companyprofiledetails?.employee_contact_details?.email}</p>
                                   <p>+971 {this.state.companyprofiledetails?.employee_contact_details?.contact}</p>
                                </div>
                            </div>
                        </Col>
                        {this.renderCompanyStaffDetails()}
                    </Row>
                </div>
        )
    }
    // Customizable Area End

     // Customizable Area Start
  renderCompanyStaffDetails = () => {
    if(this.state.companyprofiledetails?.staffs?.length === 0) {
      return <></>
    }
    return (
      <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Staffs details</p>
            {this.state.companyprofiledetails?.staffs?.map((staff:any) => {
              return (
                <p className="details-title-text">
                {staff?.name}
                <br />
                {staff?.email}
                </p>
              )
            })}
          </div>
        </Col>
    )
  }
  // Customizable Area End
    // Customizable Area Start
    renderCompanyProfileView = () => {
        return (
            <>
                {this.renderProfileHeaderPart()}
                {this.renderCompanyDetailsPart()}
            </>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    render() {
        return (
            <>
                <NavbarCompanyCommonComponent 
                isNotificationUpdate={false} {...this.props}/>
                <div className='Employee-Pofile-container'>
                    {this.renderCompanyProfileView()}
                </div>
            </>
        )
    }
    // Customizable Area End
}

// Customizable Area Start
export default DocumentLoader(CompanyProfile)
export {CompanyProfile}
// Customizable Area End
