import React from "react";
// Customizable Area Start
import {  Col, Row } from "antd";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import TableCommonComponent from "../../../components/src/TableCommonComponent.web";
import EmployeeDocumentsController from "./EmployeeDocumentsController.web";
import { pendingfilter } from "./assets";
import DocumentLoader from "../../../components/src/DocumentLoader.web";
import FilterCommonComponent from "../../../components/src/FilterCommonComponent.web";
// Customizable Area End

class EmployeeDocuments extends EmployeeDocumentsController {
  // Customizable Area Start
  renderDocumentHomeHeaderPart = () => {
    return (
      <>
        <Row>
          <Col lg={12} xl={12} md={24} sm={24} xs={24}>
            <div className="header-entry-box">
              <h2 className="header-title">
                {this.state.documentCount ? this.state.documentCount : 0}{" "}
                Documents
              </h2>
              <div className="header-title-images">
                <button
                  type="button"
                  className="header-filter-sort"
                  onClick={this.handlefiltersortdocuments}
                >
                  {(!this.state.isDocumentFiltersort ||
                    this.state.isDocumentFiltersort) && (
                    <img src={pendingfilter} className="filterimgsort" />
                  )}
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderDocumentsFilterSortPart = () => {
    return (
      <>
        <FilterCommonComponent dataList={this.state.dataList} isFiltersort={this.state.isDocumentFiltersort} checkBoxChangeFilter={this.documentsCheckBoxChangeFilter} clearFilter={this.handleDocumentFilter} />
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <>
        <NavbarCommonComponent
          displaySpinner={() => { } }
          hideLoader={() => { } }
          showLoader={() => { } }
          history={undefined}
          navigation={undefined}
          id={""}
          location={undefined} isNotificationUpdate={false}/>
        <div className="Main_container home-height-set">
          {this.renderDocumentHomeHeaderPart()}
          {this.renderDocumentsFilterSortPart()}
          <TableCommonComponent
            paginationTitle="Companies per page"
            handlePageSize={this.handleChangePageSize}
            pageSize={this.state.pageSize}
            dataList={this.state.documentsDataForFilterSort.length > 0
              ? this.state.documentsDataForFilterSort
              : this.state.dataList}
            callingColumnsFeature={"allFiles"} props={this.props}
            employeeName={this.state.employeeDocumentName}
            dueDateSet={undefined} uploadDoc={undefined} upload={undefined} uplodeId={undefined} />
        </div>
      </>
    );
  }

  // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(EmployeeDocuments);
export {EmployeeDocuments};
// Customizable Area End
