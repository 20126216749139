import React from 'react'
// Customizable Area Start
import { Layout, Menu } from 'antd'
import { Link } from "react-router-dom";
import { logo, home, pending, file } from '../assets'
const { Content, Sider } = Layout;
// Customizable Area End
const CompanyLayout = (props: any) => {
    // Customizable Area Start
    return (
        <>
            <Layout className="layout-container">
                <Sider
                     breakpoint="lg"
                    collapsedWidth='0'
                    onBreakpoint={(broken) => {
                        console.log(broken)
                    }}
                    onCollapse={(collapsed, type) => {
                        console.log(collapsed, type)
                    }}
                    className='indv-dash-sidebar'
                    width={220}
                >
                    <div className='indv-logo-head '>
                        <Link to="/company/home">
                            <img src={logo} alt='LOGO' className='layout-logo' />
                        </Link>
                    </div>
                    <Menu
                        theme='dark'
                        mode='inline'
                        className='menumargin'
                    >
                        <Menu.Item key='1'>
                            <Link
                                to="/company/home"
                                className={
                                window.location.pathname === "/company/home"
                                    ? "active-link"
                                    : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                <img src={home} className="homeimg" />
                                </span>{" "}
                                Home
                            </Link>
                        </Menu.Item>
                        <Menu.Item key='2'>
                            <Link
                                to="/company/files"
                                className={
                                window.location.pathname === "/company/files"
                                    ? "active-link"
                                    : "ant-menu-item"
                                }
                            >
                                <span className="indv-dash-side-img">
                                <img src={file} className="homeimg" />
                                </span>{" "}
                                All Files
                            </Link>
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout className='indv-layout'>
                    <Content>
                        {props.children}
                    </Content>
                </Layout>
            </Layout>
        </>
    )
    // Customizable Area End
}
// Customizable Area Start
export default CompanyLayout
// Customizable Area End
