import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../../components/src/DocumentLoader.web";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  isUserLoggedIn: any;
  userValues: any;
  isDocumentFiltersort: any;
  dataList:any;
  documentCount:any;
  employeeDocumentName:any;
  apiToken: any;
  documentsDataForFilterSort: any;
  pageSize: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployeeDocumentsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
   getEmployeeDocumentsApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      isUserLoggedIn: false,
      userValues: null,
      isDocumentFiltersort: false,
      dataList: [],
      documentCount:0,
      employeeDocumentName:"",
      apiToken: "",
      documentsDataForFilterSort: [],
      pageSize:10
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.checkUserStatus();
  }

  checkUserStatus = async () => {
    this.setState({
      apiToken: localStorage.getItem("token")
    },()=>{
      if (this.state.apiToken) {
        this.getEmployeeDocumentsDetailsShow();
        this.setState({ isUserLoggedIn: true });
      }
    })
  };

  employeeDocumentsApiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.apiToken,
    };
    const employeeDocumentsReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    employeeDocumentsReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    employeeDocumentsReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    employeeDocumentsReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      employeeDocumentsReqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(employeeDocumentsReqMessage.id, employeeDocumentsReqMessage);
    return employeeDocumentsReqMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const employeeDocumentApiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let emloyeeDocumentsResJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let employeeDocumentsErrorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(emloyeeDocumentsResJson){
        if (emloyeeDocumentsResJson.status === 500) {
          MESSAGE.error(`${emloyeeDocumentsResJson.error}. Please try again later.`, 4);
          return;
        }else if(emloyeeDocumentsResJson.data){
            this.props.hideLoader()
            this.getEmployeeDocumentsShowSuccessCallBack(employeeDocumentApiCallId, emloyeeDocumentsResJson);
        }else if(emloyeeDocumentsResJson.errors){
            this.props.hideLoader()
            this.getEmployeeDocumentsShowFailureCallBack(employeeDocumentApiCallId, emloyeeDocumentsResJson);
          }
      }else if (employeeDocumentsErrorResponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
  }


getEmployeeDocumentsDetailsShow = async () => {
  this.props.showLoader()
  this.getEmployeeDocumentsApiCallId = await this.employeeDocumentsApiCall({
    contentType: configJSON.getEmployeeDocumentsApiContentType,
    method: configJSON.getEmployeeDocumentsAPiMethod,
    endPoint: configJSON.getEmployeeDocumentsAPiEndPoint,
  });
};

getEmployeeDocumentsShowFailureCallBack = (apiCallId:any, error: any) => {
  if(apiCallId === this.getEmployeeDocumentsApiCallId){
    MESSAGE.error(error, 4);
  }
};

documentCount = (countData: any) => {
  let documentCount : any = 0;
  countData.map((item:any)=>{
    let countData = item?.data?.attributes?.documents?.uploaded.length
    documentCount += countData
  })
  return documentCount
};

getEmployeeDocumentsShowSuccessCallBack = async (apiCallId:any, res: any) => {
  if(apiCallId === this.getEmployeeDocumentsApiCallId){
    res.data.attributes.companies.map((val: any, index: any) => {
      val["isSelect"] = false;
    });
    let documentCount = this.documentCount(res.data.attributes.companies)
    this.setState({ dataList : res.data.attributes.companies, documentCount : documentCount , employeeDocumentName : res.data.attributes.name});
  }
};


handlefiltersortdocuments = () => {
  this.setState({ isDocumentFiltersort: !this.state.isDocumentFiltersort , documentsDataForFilterSort:this.state.documentsDataForFilterSort.length > 0 ? this.state.documentsDataForFilterSort : this.state.dataList});
};

documentsFiltredData = (data:any) => {
  const documentsFiltredData = this.state.dataList
  let filtredDataForSelect = documentsFiltredData.filter((compItem:any)=>{
    if(compItem.isSelect){
      return compItem
    }
  })
  this.setState({
    documentsDataForFilterSort:filtredDataForSelect,
  })
}
documentsCheckBoxChangeFilter = (e:any,item:any) => {
  const companData = this.state.dataList
  if(e.target.checked){
    let dataItemForSelect = companData.map((compItem:any)=>{
      if(compItem.data.id === item.data.id){
        compItem.isSelect = true
      }
    })
    this.documentsFiltredData(dataItemForSelect)
  }else {
    let dataItemForSelect = companData.map((compItem:any)=>{
      if(compItem.data.id === item.data.id){
        compItem.isSelect = false
      }
    })
    this.documentsFiltredData(dataItemForSelect)
  }
}

handleDocumentFilter =  () => {
  let DocumentFilteredData = this.state.dataList
 let a = DocumentFilteredData.map((filItem:any)=>{
    if (filItem.isSelect) {
      filItem.isSelect = false
    }
   return filItem
  }
  )
  this.setState({
    documentsDataForFilterSort : [],
    dataList : a
  })
}

handleChangePageSize = (val:any) => {
  this.setState({pageSize:val})
}

}
// Customizable Area End
