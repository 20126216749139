import React from "react";
// Customizable Area Start
import { Button, DatePicker, Form, Input, Row, Select } from "antd";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import EmployeeAddDocumentsController from "../src/EmployeeAddDocumentsController.web";
import DocumentLoader from "../../../components/src/DocumentLoader.web";
// Customizable Area End

 class EmployeeAddDocuments extends EmployeeAddDocumentsController {
  // Customizable Area Start
 // Customizable Area Start
 renderAddNewDocPart = () => {
    return (
        <>
              <Form
              name="basic"
              layout="vertical"
              onFinish={this.onAddNewDocumentFinish}
              autoComplete="off"
              className="create-accnt-form"
            >
              <Row className="">
                <p className="form-text">Document name</p>
              </Row>
              <Form.Item
                name="name"
                label=""
                rules={[
                  {
                    required: true,
                    message: "Please input document name",
                    whitespace: true,
                  },
                ]}
              >
                <Input data-test-id={"documentname"} className="form-accnt-box" name="name" />
              </Form.Item>
              <Row className="">
                <p className="form-text">Document type</p>
              </Row>
              <Form.Item
                name="documenttype"
                label=""
                rules={[
                  {
                    required: true,
                    message: "Please select document type",
                  },
                ]}
              >
                <Select
                data-test-id={"documentTypeInputID"}
                  bordered={false}
                  showArrow={false}
                  className="form-accnt-box"
                  onChange={this.handleDocumentTypes}
                  placeholder={
                    <p className="select_placeholder">Select document type</p>
                  }
                >
                  {this.state.docTypes.map((item: any) => (
                    <Select.Option value={item.id} key={item.id} name="documenttype">
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Row className="">
                <p className="form-text">Deadline date</p>
              </Row>    
              <Form.Item
                name="deadline_date"
                label=""
                rules={[
                  {
                    required: true,
                    message: "Please input deadline date ",
                  },
                ]}
              >
                <DatePicker
                  className="form-accnt-box"
                  placement="bottomRight"
                  name="deadline_date"
                  disabledDate={this.disabledPastDate}
                  data-test-id={"documentdeadlinedate"}
                />
              </Form.Item>
            
              <Form.Item>
                <div className="company-accnt-btn">
                  <Button className="btn-accnt-new" htmlType="submit"  >
                    Create new document
                  </Button>
                </div>
              </Form.Item>
            </Form>
        </>
    )
}
// Customizable Area End
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <>
        <NavbarCommonComponent displaySpinner={() => { } } hideLoader={() => { } } showLoader={() => { } } history={undefined} navigation={undefined} id={''} location={undefined} isNotificationUpdate={false} />
        <div className="Main_container new-doc-container">
        {this.renderAddNewDocPart()}
        </div>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(EmployeeAddDocuments);
export { EmployeeAddDocuments };
// Customizable Area End