import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import { RouteComponentProps } from "react-router";
import { withLoaderProps } from "../../../../components/src/DocumentLoader.web";
// Customizable Area End

export const configJSON = require("../config");

export type Props = RouteComponentProps &
  withLoaderProps & {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    match: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  isOpenDocumentsFilter: any;
  dataList:any;
  apiToken: any;
  filteredDocuments: any;
  selectedList:any;
  companyName:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CompanyDocumentsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
   getCompanyDocumentsApiCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      isOpenDocumentsFilter: false,
      dataList: [],
      apiToken: "",
      filteredDocuments: [],
      selectedList: [],
      companyName: "",
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.checkUserStatus();
  }

  checkUserStatus = async () => {
    this.setState({
      apiToken: localStorage.getItem("token")
    },()=>{
      if (this.state.apiToken) {
        this.getCompanyDocumentsDetailsShow();
      }
    })
  };

  companyDocumentsApiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.apiToken,
    };
    const companyDocumentReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    companyDocumentReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    companyDocumentReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    companyDocumentReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      companyDocumentReqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(companyDocumentReqMessage.id, companyDocumentReqMessage);
    return companyDocumentReqMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const companyDocumentsApiReqCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let companyDocumentsResJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let companyDocumentsErrorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(companyDocumentsResJson){
        if (companyDocumentsResJson.status === 500) {
          MESSAGE.error(`${companyDocumentsResJson.error}. Please try again later.`, 4);
          return;
        }else if (companyDocumentsResJson.errors) {
            this.getCompanyDocumentsShowFailureCallBack(companyDocumentsApiReqCallId, companyDocumentsResJson);
            this.props.hideLoader();
        }else if (companyDocumentsResJson.documents) {
            this.getCompanyDocumentsShowSuccessCallBack(companyDocumentsApiReqCallId, companyDocumentsResJson);
            this.props.hideLoader();
        } 
      }else if (companyDocumentsErrorJson) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      } 
    }
  }


  getCompanyDocumentsDetailsShow = async () => {
    this.props.showLoader()
    this.getCompanyDocumentsApiCallId = await this.companyDocumentsApiCall({
      contentType: configJSON.getCompanyDocumentsApiContentType,
      method: configJSON.getCompanyDocumentsAPiMethod,
      endPoint: configJSON.getCompanyDocumentsAPiEndPoint,
    });
  };

  getCompanyDocumentsShowFailureCallBack = (apiCallId:any, error: any) => {
    if(apiCallId === this.getCompanyDocumentsApiCallId){
      MESSAGE.error(error, 4);
    }  
  };

  getCompanyDocumentsShowSuccessCallBack = async (apiCallId:any, res: any) => {
    if(apiCallId === this.getCompanyDocumentsApiCallId){
      this.setState({ dataList : res.documents.data, 
        companyName : res.documents.data.length > 0 ? res.documents.data[0].attributes.company.name : ""});
    }
  };

  handlefiltersortdocuments = () => {
    this.setState({ isOpenDocumentsFilter: !this.state.isOpenDocumentsFilter });
  };

  resetFilters = () => {
    this.setState({selectedList:[], filteredDocuments:[]});
  };

  handleDocumentsFilter = (selectedList:any) => {
    let filteredData  = this.state.dataList;
        filteredData = filteredData.length > 0 
      ? this.documentsFilterHandler(selectedList, filteredData): [];
      if(selectedList.length > 0) {
        this.setState({
          filteredDocuments: filteredData
        })
      }else{
        this.setState({
          filteredDocuments: []
        })
      }
      return filteredData;
  };

  companyDocumentsCheckBoxFilterHandler = (e:any,item:any) => {
    const { selectedList } = this.state;

    let updatedSelectedList = [...selectedList];

    if(e.target.checked){
      updatedSelectedList.push(item.attributes.name);
    }else{
      updatedSelectedList = updatedSelectedList.filter(val => !item.attributes.name.includes(val));
    }
    this.setState({selectedList: updatedSelectedList});
    this.handleDocumentsFilter(updatedSelectedList);
  };

  documentsFilterHandler = (selectedList:any ,arr: any) => {
    return arr.filter((docItem:any)=>{
      if(selectedList.includes(docItem.attributes.name)) {
        return true;
      }
      return false;
    })
  };
}
// Customizable Area End
