import React from "react";
// Customizable Area Start
import { Button, Col, Form, Input, Row } from "antd";
import LoginController from "./LoginController.web";
import { logo } from "./assets";
import { Link } from "react-router-dom";
// Customizable Area End

export default class Login extends LoginController {
  // Customizable Area Start
  renderLoginPart = () => {
    return (
      <>
        <div className="bgimg-container">
          <div className="login-row">
            <Row className="login-main-row" gutter={[4, 4]}>
              <Col
                className="leftpartcolumn"
                lg={16}
                md={24}
                sm={24}
                xs={24}
                xl={16}
              >
                {this.renderloginleftPart()}
              </Col>
              <Col
                className="reightpartcolumn"
                lg={8}
                md={24}
                sm={24}
                xs={24}
                xl={8}
              >
                {this.renderloginrightPart()}
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderloginleftPart = () => {
    return (
      <>
        <div className="loginleft-container">
          <div className="loginform-container">
            <h3 className="form-title">Login</h3>
            <div className="form-bar">
              <p className="form-bar-title ">
                <Link
                  to="/"
                  className={
                    window.location.pathname === "/"
                      ? "bar-title-active"
                      : "form-bar-title"
                  }
                >
                  EMPLOYEE
                </Link>
              </p>
              <p className="form-bar-title">
                <Link
                  to="/company/login"
                  className={
                    window.location.pathname === "/company/login"
                      ? "bar-title-active"
                      : "form-bar-title"
                  }
                >
                  COMPANY
                </Link>
              </p>
            </div>
            <div className="login-form-container">
              <Form
                name="basic"
                layout="vertical"
                initialValues={{ remember: true }}
                onFinish={this.onFinish}
                autoComplete="off"
                className="login-indv-form"
              >
                <Row className="">
                  <p className="form-email-text">Email ID</p>
                </Row>
                <Form.Item
                  label=""
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email" },
                    {
                      type: "email",
                      message: "Please enter a valid Email address",
                    },
                  ]}
                >
                  <Input
                    className="form-email-box"
                    onChange={this.handleEmailErrorMessage}
                  />
                </Form.Item>
                <Row className="justify-content-between">
                  <p className="form-email-text">Password</p>
                </Row>
                <Form.Item
                  label=""
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password ",
                    },
                    {
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,15}$/,
                      message:
                        "Password must contain 8 to 15 characters and atleast one uppercase, one lowercase, one number and one special character ex.(@,$,!,%,*,?,&,#)",
                    },
                  ]}
                >
                  <Input.Password
                    className="form-email-box"
                    onChange={this.handlePasswordErrorMessage}
                  />
                </Form.Item>
                {this.state.invalidEmailAndPassword ? (
                  <p className="error-password">Invalid Email ID or Password</p>
                ) : (
                  ""
                )}
                <Form.Item>
                  <div className="form-button">
                    <Button className="btn-login" htmlType="submit">
                      Login
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderloginrightPart = () => {
    return (
      <>
        <div className="loginright-container">
          <div className="securedoc-container">
            <div className="logo-box">
              <img src={logo} className="logo-img" />
            </div>
          </div>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return <>{this.renderLoginPart()}</>;
  }
  // Customizable Area End
}
