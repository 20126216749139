import { notification } from 'antd';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCQzUJ7nEnJ5BTsJO8v3j3hW_-fuFIRNmY",
  authDomain: "first-step-alerts-1e6d5.firebaseapp.com",
  projectId: "first-step-alerts-1e6d5",
  storageBucket: "first-step-alerts-1e6d5.appspot.com",
  messagingSenderId: "355528533508",
  appId: "1:355528533508:web:2e63b86e2add7def3a3751",
  measurementId: "G-JDZ765NL26"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getTokenData = () => {
  return getToken(messaging, { vapidKey: 'BPQA2tsIt06SzsPr0Uu77N31U5w71AySCcGtVSCCnoyzHX5a4U9m6gZ1HTKViWYh8iwjxWEa88TUbTw2e-7AY8o' }).then((currentToken) => {
    if (currentToken) {
      localStorage.setItem('fcmToken', currentToken.toString())
    } else {
      console.log('@@@ Firebase No registration token available. Request permission to generate one.');
    }
  }).catch((err) => {
    MESSAGE.error(err, 4);
  });
}

onMessage(messaging, payload => {
  const objNotification = JSON.parse(payload?.data?.notification);
      notification.open({
        message: objNotification.title,
        description:
          objNotification.body,
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
});