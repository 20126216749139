import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../../components/src/DocumentLoader.web";
import { message as MESSAGE, notification } from "antd";
import React from "react";
import type { FormInstance } from "antd/es/form";
import moment from "moment";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  isUserLoggedIn: any;
  apiToken: any;
  createReminder: any;
  dataList: any;
  allCompanies: any;
  allDocuments: any;
  frequencies: any;
  selectedCompanyData: any;
  selectedSelectFrequencies: any;
  selectedSelectDocumentType: any;
  pageSize: any;
  reminderId: any;
  reminderDetails: any;
  blankNote: any;
  reminderModalOpen: boolean;
  doubleClickStop: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployeeRemindersController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmployeeRemindersApiCallId: any;
  getAllCompaniesRemindersApiCallId: any;
  getAllDocumentTypesForReminderApiCallId: any;
  postSetRemindersApiCallId: any;
  getViewEmployeeRemindersApiCallId: any;
  deleteReminderTypeApiCallId: any;
  formRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      isUserLoggedIn: false,
      reminderModalOpen: false,
      apiToken: "",
      blankNote: "",
      reminderId: "",
      createReminder: false,
      dataList: [],
      allCompanies: [],
      allDocuments: [],
      selectedCompanyData: [],
      selectedSelectFrequencies: [],
      selectedSelectDocumentType: [],
      reminderDetails: [],
      frequencies: [
        { id: "weekly", name: "Once in a week" },
        { id: "biweekly", name: "Once in two weeks" },
        { id: "monthly", name: "Once in a month" },
        { id: "twice_a_week", name: "Twice in a week" },
        { id: "thrice_a_week", name: "Thrice in a week" },
      ],
      pageSize: 10,
      doubleClickStop: false,
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.formRef = React.createRef<FormInstance>();
  }

  // Customizable Area Start

  async componentDidMount() {
    {
      window.location.pathname === "/employee/view/reminder" &&
        this.setState(
          {
            reminderId: this.props.location.state.reminderId,
          },
          () => this.getViewEmployeeRemindersShow()
        );
    }
    {
      window.location.pathname !== "/employee/view/reminder" &&
        this.getEmployeeRemindersShow();
    }
    this.getAllCompaniesForRemindersShow();
    this.getAllDocumentTypesForReminderShow();
  }

  CreateReminderModal = () => {
    this.setState({ createReminder: true });
  };

  handleCreateCancel = () => {
    this.setState({ createReminder: false });
  };

  employeeReminderApiCall = async (data: any) => {
    let { contentType, method, endPoint, body } = data;
    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const token = (await localStorage.getItem("token")) || "";
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    let header = {
      "Content-Type": contentType,
      token: token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.isValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isValidResponse = (responseJson: any) => {
    return responseJson || responseJson.data;
  };
  // Customizable Area End

  // Customizable Area Start
  isInValidResponse = (responseJson: any) => {
    return responseJson || responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getEmployeeRemindersApiCallId) {
      this.getRemindersShowSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getAllCompaniesRemindersApiCallId) {
      this.getAllCompaniesForRemindersSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getAllDocumentTypesForReminderApiCallId) {
      this.getAllDocumentsTypeForRemindersSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postSetRemindersApiCallId) {
      this.postSetRemindersSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.getViewEmployeeRemindersApiCallId) {
      this.getViewRemindersShowSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteReminderTypeApiCallId) {
      this.deleteDocumentTypeSuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.getEmployeeRemindersApiCallId) {
      this.getRemindersShowFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getAllCompaniesRemindersApiCallId) {
      this.getAllCompaniesForRemindersFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getAllDocumentTypesForReminderApiCallId) {
      this.getAllDocumentsTypeForRemindersFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.postSetRemindersApiCallId) {
      this.postSetRemindersFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.getViewEmployeeRemindersApiCallId) {
      this.getViewRemindersShowFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.deleteReminderTypeApiCallId) {
      this.deleteDocumentTypeFailureCallBack(responseJson);
    }
  };
  // Customizable Area End

  getEmployeeRemindersShow = async () => {
    this.props.showLoader();
    this.getEmployeeRemindersApiCallId = await this.employeeReminderApiCall({
      contentType: configJSON.getEmployeRemindersApiContentType,
      method: configJSON.getEmployeRemindersAPiMethod,
      endPoint: configJSON.getEmployeRemindersAPiEndPoint,
    });
  };

  getRemindersShowSuccessCallBack = async (res: any) => {
    this.setState({
      dataList: res.reminders.data,
    });
    this.props.hideLoader();
  };

  getRemindersShowFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  getAllCompaniesForRemindersShow = async () => {
    this.getAllCompaniesRemindersApiCallId = await this.employeeReminderApiCall(
      {
        contentType: configJSON.getAllCompaniesForReminderApiContentType,
        method: configJSON.getAllCompaniesForReminderAPiMethod,
        endPoint: configJSON.getAllCompaniesForReminderAPiEndPoint,
      }
    );
  };

  getAllCompaniesForRemindersSuccessCallBack = async (res: any) => {
    this.setState({
      allCompanies: res.data.attributes.companies,
    });
  };

  getAllCompaniesForRemindersFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  getAllDocumentTypesForReminderShow = async () => {
    this.getAllDocumentTypesForReminderApiCallId =
      await this.employeeReminderApiCall({
        contentType: configJSON.getAllDocumentTypesForReminderApiContentType,
        method: configJSON.getAllDocumentTypesForReminderAPiMethod,
        endPoint: configJSON.getAllDocumentTypesForReminderAPiEndPoint,
      });
  };

  getAllDocumentsTypeForRemindersSuccessCallBack = async (res: any) => {
    this.setState({
      allDocuments: res,
    });
  };

  getAllDocumentsTypeForRemindersFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  onReset = () => {
    this.formRef.current?.resetFields();
  };

  postSetReminders = async (values: any) => {
    if (values.note.trim() == "") {
      return notification["error"]({
        message: "Please fill the Note field",
      });
    }

    let apiBody = {
      note: values.note,
      document_type_id: this.state.selectedSelectDocumentType,
      due_date: values.reminderdate,
      frequency: this.state.selectedSelectFrequencies,
      specific_company_id: this.state.selectedCompanyData,
    };
    this.postSetRemindersApiCallId = await this.employeeReminderApiCall({
      method: configJSON.postSetReminderAPiMethod,
      endPoint: configJSON.postSetReminderAPiEndPoint,
      contentType: configJSON.postSetReminderApiContentType,
      body: apiBody,
    });
  };

  postSetRemindersSuccessCallBack = async (res: any) => {
    this.setState({ createReminder: false, doubleClickStop: false }, () => {
      this.onReset();
    });
    notification["success"]({
      message: "New reminder created successfully",
    });
    this.props.history.push("/employee/reminders");
    this.getEmployeeRemindersShow();
  };

  postSetRemindersFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  handleSelectCompany = (Value: any) => {
    this.setState({
      selectedCompanyData: Value,
    });
  };

  handleSelectFrequencies = (Value: any) => {
    this.setState({
      selectedSelectFrequencies: Value,
    });
  };

  handleSelectDocumentType = (Value: any) => {
    this.setState({
      selectedSelectDocumentType: Value,
    });
  };

  onSetReminderFinish = async (values: any) => {
    if (this.validateSetReminderForm(values)) {
      this.setState({ doubleClickStop: true });
      this.postSetReminders(values);
    }
  };

  validateSetReminderForm = (values: any) => {
    let isValid = true;
    return isValid;
  };

  handleChangeRemindersPageSize = (val: any) => {
    this.setState({ pageSize: val });
  };

  getViewEmployeeRemindersShow = async () => {
    this.props.showLoader();
    this.getViewEmployeeRemindersApiCallId = await this.employeeReminderApiCall(
      {
        contentType: configJSON.getViewEmployeRemindersApiContentType,
        method: configJSON.getViewEmployeRemindersAPiMethod,
        endPoint: `${configJSON.getViewEmployeRemindersAPiEndPoint}/${this.state.reminderId}`,
      }
    );
  };

  getViewRemindersShowSuccessCallBack = async (res: any) => {
    this.setState({
      reminderDetails: res.data,
    });
    this.props.hideLoader();
  };

  getViewRemindersShowFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  disabledReminderPastDate = (current: any) => {
    return current && current < moment().startOf("day");
  };

  handleReminderModalOpen = () => {
    this.setState({
      reminderModalOpen: true,
    });
  };

  handleCancelReminderModal = () => {
    this.setState({
      reminderModalOpen: false,
    });
  };

  deleteDocumentType = async () => {
    this.props.showLoader();
    this.deleteReminderTypeApiCallId = await this.employeeReminderApiCall({
      method: configJSON.deleteEmployeRemindersAPiMethod,
      endPoint: `${configJSON.deleteEmployeRemindersAPiEndPoint}/${this.state.reminderId}`,
      contentType: configJSON.deleteEmployeRemindersApiContentType,
    });
  };

  deleteDocumentTypeFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  deleteDocumentTypeSuccessCallBack = async (res: any) => {
    notification["success"]({
      message: res.message,
    });
    setTimeout(() => {
      this.props.history.push("/employee/reminders");
    }, 1000);
    this.props.hideLoader();
  };
}
// Customizable Area End
