import React from "react";

import {
  Container,
  Box,
  Button,
  // Customizable Area Start
  Card,
  CardActionArea,
  CardActions
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import DownloadController, {
  Props,
} from "./DownloadController";

export default class Download extends DownloadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={'xl'} >
          <Box className={"donwloadCardWrapper"}>
            <Card className={"downloadCard"}>
            <CardActionArea className="cardActionArea">
              <label className={"fileLabelRefernce"} htmlFor="">Referance Id</label>
              <input type="text" data-test-id={"referenceId"} className={"fileInputRefernce"} onChange={this.onChangeReferenceId} placeholder="Type referanceId"/>
              <label className={"fileLabelRefernce"} htmlFor="">Referance Type</label>
              <input type="text" data-test-id={"referenceType"}  className={"fileInputRefernce"}  onChange={this.onChangeReferenceType} placeholder="Type referanceType" />
              <input id="uploadFileInput" placeholder="select file" type="file" onChange={this.UploadFIle} data-testid="uploadFileId"/>
            </CardActionArea>
                {this.state.showLoader && (
              <Loader
                loading={this.state.showLoader}
              />
            )}
              <CardActions>
                <Button data-test-id={"viewDownloadBtn"} onClick={this.handleClickViewUpload} variant="contained" className={"ViewDownloadDetails cardButton"} disabled={this.state.uploadSucessMesaage === ""}>
                  View Uploaded file
                </Button>
              </CardActions>
            </Card>
          </Box>  
          <Box><h3>{this.state.uploadSucessMesaage}</h3></Box>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
