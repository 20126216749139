import React from "react";
import {
  Box,
  Typography,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import DownloadController, {
  Props,
} from "./DownloadController";

class DownloadProgressBar extends DownloadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress className={"CircularProgress"} variant="determinate" {...this.props}/>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" >
          {`${Math.round(this.props.value)}%`}
        </Typography>
      </Box>
    </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End

export default (DownloadProgressBar);
