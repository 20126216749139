import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import { message as MESSAGE, notification } from "antd";
import { withLoaderProps } from "../../../../components/src/DocumentLoader.web";
// Customizable Area End

export const configJSON = require("../config");

export type Props = RouteComponentProps &
  withLoaderProps & {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    match:any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  companyprofiledetails: any;
  apiToken: any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CompanyProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getcompanyprofiledetailsApiCallId: any;
  upload: any;
  formRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      companyprofiledetails: {},
      apiToken: "",
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
   // this.formRef = React.createRef<FormInstance>();
  }

  // Customizable Area Start

  async componentDidMount() {
    this.checkUserStatus();
  }

  checkUserStatus = async () => {
    this.setState(
      {
        apiToken: localStorage.getItem("token"),
      },
      () => {
        if (this.state.apiToken) {
          this.getcompanyprofiledetailsShow();
        }
      }
    );
  };

  companyProfileApiCall = async (data: any) => {
    const { contentType, method, endPoint, body, type = "" } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.apiToken,
    };
    const companyProfileReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    companyProfileReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    companyProfileReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    companyProfileReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      companyProfileReqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(companyProfileReqMessage.id, companyProfileReqMessage);
    return companyProfileReqMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let companyProfileResJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let companyProfileErrorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(companyProfileResJson){
        if (companyProfileResJson.status === 500) {
          this.props.hideLoader();
          MESSAGE.error(`${companyProfileResJson.error}. Please try again later.`, 4);
          return;
        }else if(companyProfileResJson.errors){
            this.props.hideLoader();
            this.getcompanyprofiledetailsShowFailureCallBack(apiRequestCallId, companyProfileResJson);
        }else if(companyProfileResJson.data){
            this.props.hideLoader();
            this.getcompanyprofiledetailsShowSuccessCallBack(apiRequestCallId, companyProfileResJson);
        }
      }else if (companyProfileErrorResponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
  }

  getcompanyprofiledetailsShow = async () => {
    this.props.showLoader();
    this.getcompanyprofiledetailsApiCallId = await this.companyProfileApiCall({
      contentType: configJSON.companyprofileApiContentType,
      method: configJSON.companyprofileAPiMethod,
      endPoint: configJSON.getcompanyprofileAPiEndPoint,
    });
  };

  getcompanyprofiledetailsShowFailureCallBack = (apiCallId:any, error: any) => {
    if(apiCallId === this.getcompanyprofiledetailsApiCallId){
      MESSAGE.error(error, 4);
    }
  };

  getcompanyprofiledetailsShowSuccessCallBack = async (apiCallId:any, res: any) => {
    if(apiCallId === this.getcompanyprofiledetailsApiCallId){
      this.setState({
        companyprofiledetails: res.data.attributes,
      });
    }
  };

}
// Customizable Area End
