import React from 'react'
// Customizable Area Start
import { Col, Row, Modal, Input, Form } from 'antd';
import { edit, maleimg } from '../assets'
import EmployeeProfileController from './EmployeeProfileController.web';
import NavbarCommonComponent from '../../../../components/src/NavbarCommonComponent.web';
import { LockOutlined } from '@ant-design/icons';
import DocumentLoader from "../../../../components/src/DocumentLoader.web"
import PaswordCommonComponent from '../../../../components/src/PasswordCommonComponent.web';
// Customizable Area End

class EmployeProfile extends EmployeeProfileController {
    // Customizable Area Start
    renderProfileHeaderPart = () => {
        return (
            <>
                <div className='profile-header-container'>
                    <div className='profile-edit-box'>
                        <button type='button' className='edit-button margin-class' onClick={this.handleEditProfile}>
                            <img src={edit} className="pswrd-show-img" />
                        </button>
                    </div>
                    <div className='profile-name-box'>
                        <button className="profile_insta_btn">
                            <input
                                id="myInput"
                                type="file"
                                accept="image/*"
                                ref={(ref) => this.upload = ref}
                                style={{ display: "none" }}
                                onChange={this.imgFilehandler}
                                data-test-id = "fieldLogoUpdate"
                            />
                            {this.state.employeeprofiledetails?.profile_image?.url ?
                                <img onClick={() => this.upload.click()} alt="" src={this.state.employeeprofiledetails?.profile_image?.url} className="profile-img" />
                                :
                                <img onClick={() => this.upload.click()} alt="" src={maleimg} className="profile-img" />
                            }
                        </button>
                        <h3 className='profile-name-title'>
                            {this.state.employeeprofiledetails?.name}
                        </h3>
                    </div>
                    <div className='employe-details-title-box'>
                        <p className='employe-details-title'>
                            Employee details
                        </p>
                    </div>
                </div>
            </>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderNameCommon = () => {
        return (
            <>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Full name
                                </p>
                                <p className='details-title-text'>
                                    {this.state.employeeprofiledetails?.name}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Gender
                                </p>
                                <p className='details-title-text'>
                                    {this.state.employeeprofiledetails?.gender}
                                </p>
                            </div>
                        </Col>
            </>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderPersonalDetailsPart = () => {
        return (
            <>
                <div className='personal-details-box'>
                    <div className='employe-details-title-box employe-details-title-margin'>
                        <p className='employe-details-title personal-details-padding'>
                            Personal details
                        </p>
                    </div>
                    <Row gutter={[16, 16]} className="details-row">
                        {this.renderNameCommon()}
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Education
                                </p>
                                <p className='details-title-text'>
                                    {this.state.employeeprofiledetails?.education}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Employee contact
                                </p>
                                <p className='details-title-text'>
                                    +971 {this.state.employeeprofiledetails?.contact}
                                </p>
                            </div>
                        </Col> <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Address
                                </p>
                                <p className='details-title-text'>
                                    {this.state.employeeprofiledetails?.address}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderProfessionalDetailsPart = () => {
        return (
            <>
                <div className='personal-details-box'>
                    <div className='employe-details-title-box employe-details-title-margin'>
                        <p className='employe-details-title personal-details-padding'>
                            Professional details
                        </p>
                    </div>
                    <Row gutter={[16, 16]} className="details-row">
                    {this.renderCommonID()}
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding password-change'>
                                <div>
                                    <p className='details-title'>
                                        Password
                                    </p>
                                    <p className='details-title-text'>
                                        ***********
                                    </p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Company assigned
                                </p>
                                <p className='details-title-text'>
                                    {this.state.employeeprofiledetails?.companies?.map((item: any,index:any) => {
                                        return (
                                            <p className='details-title-text' key={index}>
                                                {item.name}
                                            </p>)
                                    })}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Hired date
                                </p>
                                <p className='details-title-text'>
                                    {this.state.employeeprofiledetails?.hired_on}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderEmployeeProfileView = () => {
        return (
            <>
                {this.renderProfileHeaderPart()}
                {this.renderPersonalDetailsPart()}
                {this.renderProfessionalDetailsPart()}
            </>
        )
    }
    // Customizable Area End
     // Customizable Area Start
     renderEmployeeProfessionalheading = () => {
        return (
            <>
                 <div className='employe-details-title-box employe-details-title-margin'>
                        <p className='employe-details-title personal-details-padding'>
                            Professional details
                        </p>
                    </div>
            </>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderEditPersonalDetailsPart = () => {
        return (
            <>
                <div className='personal-details-box'>
                    <div className='employe-details-title-box employe-details-title-margin'>
                        <p className='employe-details-title personal-details-padding'>
                            Personal details
                        </p>
                    </div>
                    <Row gutter={[16, 16]} className="details-row">
                    {this.renderNameCommon()}
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Education
                                </p>
                                <input
                                data-testid={"EducationInputID"}                                
                                name='Education' className='details-title-text edit-input-text' value={this.state.employeeprofiledetails?.education} type="input" onChange={this.handleprofileEdit} />
                                {this.state.emptyField ? <p className='error-password'>This field cannot remain empty</p> : ""}
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Employee contact
                                </p>
                                <input data-testid={"ContactInputID"} name='Contact' className='details-title-text edit-input-text' value={this.state.employeeprofiledetails?.contact} type="input" onChange={this.handleprofileEditcontact} required />
                                {this.state.emptyFieldContact ? <p className='error-password'>This field cannot remain empty and only numbers (minimum 7) are allowed </p> : ""}
                                {this.state.sameError ? <p className='error-password'>An account already exists with this contact </p> : ""}
                            </div>
                        </Col> 
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Address
                                </p>
                                <input data-testid={"AddressInputID"} name='Address' className='details-title-text edit-input-text bottom--margin' value={this.state.employeeprofiledetails?.address} type="input" onChange={this.handleprofileEditaddress} />
                                {this.state.emptyFieldAddress ? <p className='error-password'> This field cannot remain empty</p> : ""}
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderCommonID = () => {
        return (
            <>
                <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Employee ID
                                </p>
                                <p className='details-title-text'>
                                    {this.state.employeeprofiledetails?.id}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Job title
                                </p>
                                <p className='details-title-text'>
                                    {this.state.employeeprofiledetails?.job_title}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Employment type
                                </p>
                                <p className='details-title-text'>
                                    {this.state.employeeprofiledetails?.employment_type}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Status
                                </p>
                                <p className='details-title-text'>
                                    {this.state.employeeprofiledetails?.status}
                                </p>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Email ID
                                </p>
                                <p className='details-title-text'>
                                    {this.state.employeeprofiledetails?.email}
                                </p>
                            </div>
                        </Col>
            </>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderEditProfessionalDetailsPart = () => {
        return (
            <>
                <div className='personal-details-box'>
                  {this.renderEmployeeProfessionalheading()}
                    <Row gutter={[16, 16]} className="details-row">
                    {this.renderCommonID()}
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding password-change'>
                                <div>
                                    <p className='details-title'>
                                        Password
                                    </p>
                                    <p className='details-title-text'>
                                        ***********
                                    </p>
                                </div>
                                <button type='button' className='pswrd-btn' onClick={this.changePasswordModal}>
                                    Change password
                                </button>
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Company assigned
                                </p>  
                                {this.state.employeeprofiledetails?.companies?.map((item: any, index: any) => {
                                    return (
                                        <p className='details-title-text' key={index}>
                                            {item.name}
                                        </p>)
                                })}
                            </div>
                        </Col>
                        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
                            <div className='details-text-box personal-details-padding'>
                                <p className='details-title'>
                                    Hired date
                                </p>
                                <p className='details-title-text'>
                                    {this.state.employeeprofiledetails?.hired_on}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderSaveChangesbutton = () => {
        return (
            <>
                <div className='save-btn-container'>
                    <button type='button' className='pswrd-btn save-btn' onClick={this.employeeEditfunction}>
                        Save Details
                    </button>
                </div>
            </>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    renderChangePasswordModal = () => {
        return (
            <>
                <Modal
                    closable={false}
                    open={this.state.changemodal}
                    onCancel={this.modalhandleCancel}
                    footer={null}
                    centered
                    className='main-modal'
                >
                    <div className='modal-content'>
                        <h3 className="modal-heading"> Change password</h3>
                    </div>
                    <Form
                        name='basic'
                        layout='vertical'
                        initialValues={{ remember: false }}
                        onFinish={this.onFinish}
                        autoComplete='off'
                        ref={this.formRef}
                        className='login-indv-form'
                    >
                        <Row>
                            <p className='change-pswrd-title'>
                                Old password
                            </p>
                        </Row>
                        <Form.Item
                            label=''
                            name='old_password'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter old password'
                                },
                            ]}
                        >
                            <div className='password-box'>
                                <LockOutlined translate={undefined} />
                                <Input.Password className='chnge-pswrd-input' name='old_password' onChange={this.handleOldpassword} />
                            </div>
                        </Form.Item>
                      <PaswordCommonComponent  handleNewpassword={this.handleNewpassword} handleConfirmNewpassword={this.handleConfirpasword} />
                        {this.state.oldPasswordIncorrect && <p className='error-password'>Old password is incorrect</p>}
                        {this.state.oldPasswordnewPassword && <p className='error-password'>Old  password and New password can't be same</p>}
                        <Form.Item>
                            <div className='pswrd-submit-btn'>
                                <button type='button' className='pswrd-btn close-btn' onClick={this.modalhandleCancel}>
                                    Close
                                </button>
                                <button type='submit' className='pswrd-btn save-btn close-btn' >
                                    Submit
                                </button>
                            </div>
                        </Form.Item>
                    </Form>
                </Modal>

            </>
        )
    }
    // Customizable Area End

    // Customizable Area Start
    renderEditEmployeeProfileView = () => {
        return (
            <>
                {this.renderProfileHeaderPart()}
                {this.renderEditPersonalDetailsPart()}
                {this.renderEditProfessionalDetailsPart()}
                {this.renderSaveChangesbutton()}
                {this.renderChangePasswordModal()}
            </>
        )
    }
    // Customizable Area End
    // Customizable Area Start
    render() {
        return (
            <>
                <NavbarCommonComponent displaySpinner={() => { } } hideLoader={() => { } } showLoader={() => { } } history={undefined} navigation={undefined} id={''} location={undefined} isNotificationUpdate={false} />
                <div className='Employee-Pofile-container'>
                    {this.state.editbtn ? this.renderEditEmployeeProfileView() : this.renderEmployeeProfileView()}
                </div>
            </>
        )
    }
    // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(EmployeProfile)
export {EmployeProfile}
// Customizable Area End