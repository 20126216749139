Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AutomaticReminders";
exports.labelBodyText = "AutomaticReminders Body";

exports.getEmployeRemindersAPiEndPoint = "reminders";
exports.getEmployeRemindersAPiMethod = "GET";
exports.getEmployeRemindersApiContentType = "application/json";

exports.getAllCompaniesForReminderAPiEndPoint = "account_block/employee/companies";
exports.getAllCompaniesForReminderAPiMethod = "GET";
exports.getAllCompaniesForReminderApiContentType = "application/json";

exports.getAllDocumentTypesForReminderAPiEndPoint = "bx_block_types/document_types";
exports.getAllDocumentTypesForReminderAPiMethod = "GET";
exports.getAllDocumentTypesForReminderApiContentType = "application/json";

exports.postSetReminderAPiEndPoint = "reminders";
exports.postSetReminderAPiMethod = "POST";
exports.postSetReminderApiContentType = "application/json";

exports.getViewEmployeRemindersAPiEndPoint = "reminders";
exports.getViewEmployeRemindersAPiMethod = "GET";
exports.getViewEmployeRemindersApiContentType = "application/json";

exports.deleteEmployeRemindersAPiEndPoint = "reminders";
exports.deleteEmployeRemindersAPiMethod = "DELETE";
exports.deleteEmployeRemindersApiContentType = "application/json";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End