
import React from "react";
// Customizable Area Start
import './TableCommonComponent.css';
import { Checkbox } from 'antd';
// Customizable Area End

type FilterCommonComponentProps = {
  // Customizable Area Start
  dataList: any;
  isFiltersort: any;
  checkBoxChangeFilter: any;
  clearFilter: any;
  // Customizable Area End
};

const FilterCommonComponent = ({
  // Customizable Area Start
  dataList,
  isFiltersort,
  checkBoxChangeFilter,
  clearFilter,
  // Customizable Area End
}: FilterCommonComponentProps) => {
  // Customizable Area Star
  
  return (
    <>
      {isFiltersort ? (
        <div className="company-filter-documents">
          <div className="filter-sort-container">
            <div className="filter-sort-box">
              <div className="filter-box">
                <div className="border-right-box">
                  <div className="filter-clear">
                  <h3 className="filter-title">Filter by :</h3>
                  <button className="clear-all-button"  onClick={() => clearFilter()}>clear all</button>
                  </div>
                  <div className="filter-options-box">
                    {dataList?.map((item: any) => {
                      return (
                        <>
                          <Checkbox checked={item.isSelect} className="checkox-style" onChange={(e:any)=>checkBoxChangeFilter(e,item)}>
                            {item.data.attributes.name}
                          </Checkbox>
                        </>
                      ) 
                    })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default FilterCommonComponent
// Customizable Area End
