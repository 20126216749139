import React from "react";
// Customizable Area Start
import {  Col, Row } from "antd";
import NavbarCommonComponent from "../../../../components/src/NavbarCommonComponent.web";
import { filterimgsort } from "../assets";
import EmployeeCompanyController from "./EmployeeCompanyController.web";
import FilterCommonComponent from "../../../../components/src/FilterCommonComponent.web";
import {
  CaretRightOutlined
} from '@ant-design/icons';
import DocumentLoader from "../../../../components/src/DocumentLoader.web";
// Customizable Area End
 class EmployeeCompany extends EmployeeCompanyController {
  // Customizable Area Start
  renderCompanyHomeHeaderPart = () => {
    return (
      <>
        <Row>
          <Col lg={12} xl={12} md={24} sm={24} xs={24}>
            <div className="header-entry-box">
              <h2 className="header-title">{this.state.employeeCompanyname.length} Companies </h2>
              <p className="header-notification">+ {this.state?.employeeCompanydata?.new_companies_count} New Company</p>
              <button
                type="button"
                data-test-id="btnFilterCountry"
                className="header-filter-sort"
                onClick={() => this.handlefiltersortcompany()}
              >
                {(!this.state.isCompanyFiltersort || this.state.isCompanyFiltersort) && (
                  <img src={filterimgsort} className="filterimgsort" />
                )}
              </button>
            </div>
          </Col>
          <Col lg={12} xl={12} md={24} sm={24} xs={24}>
            <div className="header-create-box">
              <button
                type="button"
                data-test-id="btnCreateCountry"
                className="create-button"
                onClick={this.handleCreateCompanyAccount}
              >
                Create company account
              </button>
            </div>
          </Col>
        </Row>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderCompanyFilterSortPart = () => {
    return (
      <>
         <FilterCommonComponent dataList={this.state.employeeCompanyname} isFiltersort={this.state.isCompanyFiltersort} checkBoxChangeFilter={this.companyCheckBoxChangeFilter} clearFilter={this.handleCompanyFilter} />
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderEmployeeCompaniesView = () => {
    return (
      <>
        <div className="employee-companies-container">
          <Row gutter={[16, 16]}>
            {(this.state?.dataCompanyForFilterSort?.length > 0 ? this.state?.dataCompanyForFilterSort : this.state?.employeeCompanyname)?.map((item: any,index:any) => {
              return (
                <>
                  <Col lg={8} xl={8} md={12} sm={12} xs={24} key={index}>
                    <div className="companies-details-box">
                      <div className="companies-details-header">
                        <div className="companies-img-box">
                          <img
                           src={item?.data?.attributes?.logo?.url}
                            className="companies-img-logo"
                          />
                        </div>
                        <div className="companies-info-text" onClick={()=>this.props.history.push("/employee/company/details",{companyDetails:item.data.attributes,employeeName: this.state.employeeCompanydata.name,})}>
                          <h3 className="comapnies-name-text">{item?.data?.attributes?.name}</h3>
                          <p className="companies-email-text">
                            {item?.data?.attributes?.email}
                          </p>
                        </div>
                      </div>
                      <div className="companies-document-box">
                      {item.data.attributes.documents.uploaded?.map((uploaditem: any) =>
                           <div className="companies-radiobtn-box">
                           <div className="bullets-of-documents">
                           <CaretRightOutlined translate={undefined} />
                            </div>
                           <p className="docs-company-heading">{uploaditem?.name}</p>
                         </div>
                        )
                        }
                      </div>
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      </>
    );
  };

  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <>
        <div className="employee-height-set">
        <NavbarCommonComponent displaySpinner={() => { } } hideLoader={() => { } } showLoader={() => { } } history={undefined} navigation={undefined} id={''} location={undefined} isNotificationUpdate={false} />
          <div className="Main_container">
            {this.renderCompanyHomeHeaderPart()}
            {this.renderCompanyFilterSortPart()}
            {this.renderEmployeeCompaniesView()}
          </div>
        </div>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(EmployeeCompany);
export { EmployeeCompany };
// Customizable Area End