import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../../../components/src/DocumentLoader.web";
import React from "react";
import type { FormInstance } from "antd/es/form";
// Customizable Area End

export const configJSON = require("../config");

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  isUserLoggedIn: any;
  getStaffId: any;
  employeeEditCompanyDetailsData: any;
  editbutton: boolean;
  deletemodal: any;
  confirmdeletemodal: any;
  CompanyprofileImage: any;
  companyEmail: any;
  companyStakeholder: any;
  companyAddress: any;
  companyRentDueDate: any;
  companyContact: any;
  companyRentAmmount: any;
  companyDetails: any;
  employeeName: any;
  logoImage: any;
  emptyFieldStakeholder: boolean;
  removeStaff: boolean;
  emptyFieldEmail: boolean;
  emptyFieldContact: boolean;
  emptyFieldRentAmmount: boolean;
  emptyFieldRentDueDate: boolean;
  emptyFieldAddress: boolean;
  companyChangeModal: boolean;
  companyAddNewStaffModal: boolean;
  emailErrorMessage: boolean;
  alreadyTaken: boolean;
  alreadyTakenStaffEmail: boolean;
  alreadyTakenContact: boolean;
  confirmPassword: any;
  newStaffName: any;
  newStaffEmail: any;
  negativeValue: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployeeCompanyDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  putEmployeeCompanyDetailsApiCallId: any;
  deleteEmployeeCompanyApiCallId: any;
  postCompanyPasswordApiCallId: any;
  postAddNewStaffApiCallId: any;
  deleteCompanyStaffApiCallId: any;
  upload: any;
  formRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      isUserLoggedIn: false,
      getStaffId: "",
      employeeEditCompanyDetailsData: [],
      editbutton: false,
      deletemodal: false,
      confirmdeletemodal: false,
      CompanyprofileImage: "",
      companyEmail: this.props.location.state?.companyDetails?.email,
      companyStakeholder: this.props.location.state?.companyDetails?.stakeholder,
      companyAddress: this.props.location.state?.companyDetails?.address,
      companyContact: this.props.location.state?.companyDetails?.contact,
      companyRentAmmount: this.props.location.state?.companyDetails?.rent_amt,
      companyRentDueDate: this.props.location.state?.companyDetails?.rent_due_date,
      companyDetails: this.props.location.state?.companyDetails,
      employeeName: this.props.location.state?.employeeName,
      logoImage: null,
      emptyFieldStakeholder: false,
      emptyFieldContact: false,
      emptyFieldRentAmmount: false,
      emptyFieldRentDueDate: false,
      emptyFieldEmail: false,
      emptyFieldAddress: false,
      companyChangeModal: false,
      companyAddNewStaffModal: false,
      confirmPassword: "",
      emailErrorMessage: false,
      alreadyTaken: false,
      alreadyTakenStaffEmail: false,
      alreadyTakenContact: false,
      negativeValue: false,
      removeStaff: false,
      newStaffName: "",
      newStaffEmail: "",
      // Customizable Area End
    };

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.formRef = React.createRef<FormInstance>();

  }

  // Customizable Area Start

  async componentDidMount() {
    this.checkUserStatus();
  }

  checkUserStatus = async () => {
    let token = await localStorage.getItem("token");
    if (token) {
      this.setState({ isUserLoggedIn: true });
    }
  };

  handleCompanyEdit = () => {
    this.setState({
      editbutton: !this.state.editbutton,
      negativeValue: false,
      alreadyTaken: false,
    });
  };


  apiCall = async (data: any) => {
    let { contentType, method, endPoint, body, type } = data;
    let token = (await localStorage.getItem("token")) || "";
    let header = {
      "Content-Type": contentType,
      token: token,
    };

    let requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (responseJson.status === 500) {
          MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
          return;
        } else if (responseJson.email) {
          this.setState({
            alreadyTaken: true,
          });
        } else if (responseJson.rent_amt) {
          this.setState({
            negativeValue: true,
          });
        }
        else if (responseJson.contact) {
          this.setState({
            alreadyTakenContact: true,
          });
        } else if (responseJson.errors) {
          this.handleFailureCallbacks(apiRequestCallId, responseJson);
        }
        else {
          this.handleSuccessCallbacks(apiRequestCallId, responseJson);
        }
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
  }

  handleFailureCallbacks = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.putEmployeeCompanyDetailsApiCallId: {
        this.editEmployeeCompanyDetailsShowFailureCallBack(responseJson);
        break;
      }
      case this.deleteEmployeeCompanyApiCallId: {
        this.deleteEmployeeCompanyShowFailureCallBack(responseJson);
        break;
      }
      case this.postCompanyPasswordApiCallId: {
        this.postCompanyChangePasswordFailureCallBack(responseJson);
        break;
      }

      case this.postAddNewStaffApiCallId: {
        this.postAddNewStaffFailureCallBack(responseJson);
        break;
      }

      case this.deleteCompanyStaffApiCallId: {
        this.deleteCompanyStaffFailureCallBack(responseJson);
        break;
      }

      default: {
        break;
      }
    }
  }

  handleSuccessCallbacks = (apiRequestCallId: any, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.putEmployeeCompanyDetailsApiCallId: {
        notification["success"]({
          message: "Your details has been saved successfully",
        });
        this.editEmployeeCompanyDetailsShowSuccessCallBack(responseJson);
        break;
      }

      case this.deleteEmployeeCompanyApiCallId: {
        this.deleteEmployeeCompanyShowSuccessCallBack(responseJson);
        break;
      }

      case this.postCompanyPasswordApiCallId: {
        this.postCompanyChangePasswordSuccessCallBack(responseJson);
        break;
      }

      case this.postAddNewStaffApiCallId: {
        this.postAddNewStaffSuccessCallBack(responseJson);
        break;
      }

      case this.deleteCompanyStaffApiCallId: {
        this.deleteCompanyStaffSuccessCallBack(responseJson);
        break;
      }

      default: {
        break;
      }
    }
  }

  editEmployeeCompanyDetailShow = async () => {
    const emailReg = /^\s*\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,16})+\s*$/;
    if (this.state.emptyFieldStakeholder || this.state.emptyFieldAddress || this.state.emptyFieldEmail || this.state.emptyFieldContact || this.state.emptyFieldRentAmmount || this.state.emptyFieldRentDueDate) {
      return (
        notification["error"]({
          message: "Please fill the blank field",
        })
      )
    }
    if (this.state.companyStakeholder.trim() == "") {
      return notification["error"]({
        message: "Please fill the Company stakeholder field",
      });
    }
    if (this.state.companyEmail.trim() == "") {
      return notification["error"]({
        message: "Please fill the Company Email field",
      });
    }
    if (!emailReg.test(this.state?.companyEmail)) {
      return this.setState({
        emailErrorMessage: true
      })
    }
    if (this.state.companyAddress.trim() == "") {
      return notification["error"]({
        message: "Please fill the Address field",
      });
    }
    if (this.state.companyContact.trim() == "") {
      return notification["error"]({
        message: "Please fill the Company contact field",
      });
    }
    if (this.state.companyContact.length < 7) {
      return notification["error"]({
        message: "Please fill (minimum 7) characters in  contact field",
      });
    }
    let formdata = new FormData();
    formdata.append("email", this.state.companyEmail);
    formdata.append("stakeholder", this.state.companyStakeholder);
    formdata.append("address", this.state.companyAddress);
    formdata.append("contact", this.state.companyContact);
    formdata.append("rent_amt", this.state.companyRentAmmount);
    formdata.append("rent_due_date", this.state.companyRentDueDate);
    if (this.state.logoImage) {
      formdata.append("logo", this.state.logoImage);
    }

    this.putEmployeeCompanyDetailsApiCallId = await this.apiCall({
      method: configJSON.putCompaniesDetailsAPiMethod,
      endPoint: `${configJSON.putCompaniesDetailsAPiEndPoint}/${this.state.companyDetails?.id}`,
      body: formdata,
      type: "formData",
    });
  };

  editEmployeeCompanyDetailsShowFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  editEmployeeCompanyDetailsShowSuccessCallBack = async (res: any) => {
    let newState = { companyDetails: res.data?.attributes, employeeName: this.state.employeeName }
    this.setState({
      companyEmail: res.data?.attributes?.email,
      companyStakeholder: res.data?.attributes?.stakeholder,
      companyAddress: res.data?.attributes?.address,
      companyContact: res.data?.attributes?.contact,
      companyRentAmmount: res.data?.attributes?.rent_amt,
      companyRentDueDate: res.data?.attributes?.rent_due_date,
      editbutton: false,
    }, () => {
      this.props.history.push("/employee/company/details", newState)
    })
    this.props.hideLoader()
  };

  deleteCompanyModal = () => {
    this.setState({ deletemodal: true });
  };
  handleCancelModal = () => {
    this.setState({ deletemodal: false });
  };
  handleCancelDeleteModal = () => {
    this.setState({ confirmdeletemodal: false });
  };
  changePasswordCompanyModal = () => {
    this.setState({ companyChangeModal: true });
  };
  companyPasswordModalCancel = () => {
    this.setState({ companyChangeModal: false });
  };
  addNewStaffModal = () => {
    this.setState({ companyAddNewStaffModal: true });
  };
  addNewStaffModalCancel = () => {
    this.setState({ companyAddNewStaffModal: false });
  };
  validateCompanyForm = (values: any) => {
    let isValidForm = true;

    return isValidForm;
  };
  validateStaff = (values: any) => {
    let isValidAddStaffForm = true;

    return isValidAddStaffForm;
  };

  onPassowrdFinish = async (values: any) => {
    if (this.validateCompanyForm(values)) {
      this.setState(
        {
          confirmPassword: values.confirm_password,
        },
        () => {
          this.CompanyChangePasswordFunction();
        }
      );
    }
  };

  handleCompanyDetailsEditstakeholder = (e: any) => {
    const { value } = e.target;
    this.setState({ companyStakeholder: value })
    if (value.length == 0) {
      this.setState({
        emptyFieldStakeholder: true,
      });
    } else {
      this.setState({
        emptyFieldStakeholder: false,
      });
    }
  }

  handleCompanyDetailsEditaddress = (e: any) => {
    const { value } = e.target;
    this.setState({ companyAddress: value })
    if (value.length == 0) {
      this.setState({
        emptyFieldAddress: true,
      });
    } else {
      this.setState({
        emptyFieldAddress: false,
      });
    }
  }

  handleCompanyDetailsEditemail = (e: any) => {
    const { value } = e.target;
    this.setState({ companyEmail: value })
    if (value.length == 0) {
      this.setState({
        emptyFieldEmail: true,
      });
    } else {
      this.setState({
        emptyFieldEmail: false,
        emailErrorMessage: false,
        alreadyTaken: false,
      });
    }
  }

  handleCompanyDetailsEditContact = (e: any) => {
    const { value } = e.target;
    let numbers = /^\d+$/;
    if (value === "" || numbers.test(value)) {
      if (value.length <= 10 || value.length === 0) {
        this.setState({ companyContact: value })
      }
    }
    if (value.length == 0) {
      this.setState({
        emptyFieldContact: true,
      });
    } else {
      this.setState({
        emptyFieldContact: false,
        alreadyTakenContact: false,
      });
    }
  }

  handleCompanyDetailsEditRentAmmount = (e: any) => {
    const { value } = e.target;
    this.setState({ companyRentAmmount: value })
    if (value.length == 0) {
      this.setState({
        emptyFieldRentAmmount: true,
      });
    } else {
      this.setState({
        emptyFieldRentAmmount: false,
        negativeValue: false,
      });
    }
  }

  handleCompanyDetailsEditRentDueDate = (date: any) => {

    this.setState({ companyRentDueDate: date })
    if (date.length == 0) {
      this.setState({
        emptyFieldRentDueDate: true,
      });
    } else {
      this.setState({
        emptyFieldRentDueDate: false,
      });
    }
  }


  logoFilehandler = (e: any) => {
    const reader: any = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        const companyDetails = this.state.companyDetails;
        let logoData = {
          url: reader.result
        }
        companyDetails['logo'] = logoData;
        this.setState({ companyDetails });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    this.setState(
      {
        logoImage: e.target.files[0],
        editbutton: true
      },
    );
  }

  deleteEmployeeCompany = async () => {
    this.deleteEmployeeCompanyApiCallId = await this.apiCall({
      method: configJSON.deleteCompaniesAPiMethod,
      endPoint: `${configJSON.deleteCompaniesAPiEndPoint}/${this.state.companyDetails?.id}`,
      contentType: configJSON.deleteCompaniesApiContentType,
    });

  };

  deleteEmployeeCompanyShowFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  deleteEmployeeCompanyShowSuccessCallBack = async (res: any) => {
    if (res.message === "Company account deleted") {
      this.setState({
        deletemodal: false,
        confirmdeletemodal: true,
      }, () => {
        setTimeout(() => {
          this.setState({ confirmdeletemodal: false }, () => {
            this.props.history.push("/employee/home")
          });
        }, 2000);
      });
    }
  };

  CompanyChangePasswordFunction = async () => {

    let apiBody = {
      password: this.state.confirmPassword,
    };
    this.postCompanyPasswordApiCallId = await this.apiCall({
      contentType: configJSON.PostCompaniesPasswordApiContentType,
      method: configJSON.PostCompaniesPasswordAPiMethod,
      endPoint: `${configJSON.PostCompaniesPasswordAPiEndPoint}/${this.state.companyDetails?.id}`,
      body: JSON.stringify(apiBody),
    });
  };

  postCompanyChangePasswordSuccessCallBack = (res: any) => {
    if (res.message === "Password changed successfully") {
      notification["success"]({
        message: res.message,
      });
      this.setState({
        companyChangeModal: false,
      }, () => {
        this.onResetPassword();
      });
    }
  };

  postCompanyChangePasswordFailureCallBack = async (err: any) => {
    MESSAGE.error(err, 4);
  };

  onResetPassword = () => {
    this.formRef.current?.resetFields();
  };

  companyAddNewStaffFunction = async (values: any) => {
    if (this.state.newStaffName.trim() == "") {
      return notification["error"]({
        message: "Please fill the staff name field",
      });
    }
    let apiBody = {
      name: values.staffname,
      email: values.email,
    };
    this.postAddNewStaffApiCallId = await this.apiCall({
      contentType: configJSON.postAddNewStaffApiContentType,
      method: configJSON.postAddNewStaffAPiMethod,
      endPoint: `${configJSON.postAddNewStaffAPiEndPoint}/${this.state.companyDetails?.id}/staff`,
      body: JSON.stringify(apiBody),
    });
  };

  postAddNewStaffSuccessCallBack = (res: any) => {
    let Response = res.data?.attributes
    setTimeout(() => {
      this.setState({ 
        companyAddNewStaffModal: false,editbutton:false,
        companyEmail: Response?.email,
        companyStakeholder: Response?.stakeholder,
        companyAddress: Response?.address,
        companyContact: Response?.contact,
        companyRentAmmount: Response?.rent_amt,
        companyRentDueDate: Response?.rent_due_date,
        companyDetails: Response,
      }, () => {
        notification["success"]({
          message: "New staff added",
        });
      });
    }, 2000);
  };
  postAddNewStaffFailureCallBack = async (err: any) => {
    if (err?.errors?.email?.[0] == "has already been taken") {
      this.setState({
        alreadyTakenStaffEmail: true
      })
    }
   
  };

  onAddNewStaffFinish = async (values: any) => {
    if (this.validateStaff(values)) {
      this.setState(
        {
          newStaffName: values.staffname,
          newStaffEmail: values.email,
        },
        () => {
          this.companyAddNewStaffFunction(values);
        }
      );
    }
  };

  handleStaffEmail = () => {
    this.setState({
      alreadyTakenStaffEmail : false
    })
  }

  removeStaffModal = (staffId:number) => {
    this.setState({
      removeStaff : true,
      getStaffId: staffId
    })
  }

  handleCancelRemoveStaffModal = () => {
    this.setState({
      removeStaff : false
    })
  }

  deleteCompanyStaff = async () => {
    this.deleteCompanyStaffApiCallId = await this.apiCall({
      method: configJSON.deleteCompanyStaffAPiMethod,
      endPoint: `${configJSON.deleteCompanyStaffAPiEndPoint}/${this.state.companyDetails?.id}/staff?staff_id=${this.state.getStaffId}`,
      contentType: configJSON.deleteCompanyStaffApiContentType,
    });
  };

  deleteCompanyStaffFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  deleteCompanyStaffSuccessCallBack = async (res: any) => {
    let staffData =  this.state.companyDetails.staffs
    let companyDetailsData =  this.state.companyDetails
    let staffDataIndex = staffData?.findIndex((filterItem: any) => filterItem.id === this.state.getStaffId);
    staffData.splice(staffDataIndex, 1);
    companyDetailsData["staffs"] = staffData
    if (res.message === "staff deleted") {
      this.setState({
        removeStaff: false,
        editbutton:false,
        companyDetails:companyDetailsData
      }, () => {

      });
    }
  };

  handleNewStaffEmail = () => {
    this.setState({
      alreadyTakenStaffEmail : false
    })
  }

}
// Customizable Area End
