
import React from "react";
// Customizable Area Start
import { Row, Input, Form } from 'antd';
import { LockOutlined } from '@ant-design/icons';
// Customizable Area End

type PasswordCommonComponentProps = {
  // Customizable Area Start
  handleNewpassword:any;
  handleConfirmNewpassword:any;
  // Customizable Area End
};

const PaswordCommonComponent = ({
  // Customizable Area Start
  handleNewpassword,
  handleConfirmNewpassword,
  // Customizable Area End
}: PasswordCommonComponentProps) => {
  // Customizable Area Star
  
  return (
    <>
   <Row>
                    <p className='change-pswrd-title'>
                        New password
                    </p>
                </Row>
                <Form.Item
                    label=''
                    name='new_password'
                    rules={[
                        {
                            required: true,
                            message: 'Please enter new password',
                        },
                        {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,15}$/,
                            message:
                                'Password must contain 8 to 15 characters and atleast one uppercase, one lowercase, one number and one special character ex.(@,$,!,%,*,?,&,#)',
                        },
                    ]}
                >
                    <div className='password-box'>
                        <LockOutlined translate={undefined} />
                        <Input.Password className='chnge-pswrd-input' name='new_password' onChange={handleNewpassword} />
                    </div>
                </Form.Item>
                <Row>
                    <p className='change-pswrd-title'>
                        Confirm new password
                    </p>
                </Row>
                <Form.Item
                    label=''
                    name='confirm_password'
                    // hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Please confirm your password',
                        },
                        {},

                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (
                                    !value ||
                                    getFieldValue('new_password') === value
                                ) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(
                                    'New password and Confirm new password are not same'
                                )
                            },
                        }),
                    ]}
                    dependencies={['new_password']}
                    validateTrigger='onChange'
                >
                    <div className='password-box'>
                        <LockOutlined translate={undefined} />
                        <Input.Password
                            name='confirm_password'
                            className='chnge-pswrd-input'
                            onChange={handleConfirmNewpassword}
                        />
                    </div>
                </Form.Item>
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default PaswordCommonComponent
// Customizable Area End