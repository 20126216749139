import React from "react";
// Customizable Area Start
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
} from "antd";
import EmployeeRemindersController from "./EmployeeRemindersController.web";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import TableCommonComponent from "../../../components/src/TableCommonComponent.web";
import DocumentLoader from "../../../components/src/DocumentLoader.web";
// Customizable Area End

class EmployeeReminders extends EmployeeRemindersController {
  // Customizable Area Start
  renderRemindersHeaderPart = () => {
    return (
      <>
        <Row>
          <Col lg={12} xl={12} md={24} sm={24} xs={24}>
            <div className="header-entry-box">
              <h2 className="header-title">Reminders</h2>
            </div>
          </Col>
          <Col lg={12} xl={12} md={24} sm={24} xs={24}>
            <div className="header-create-box">
              <button
                type="button"
                className="create-button"
                onClick={this.CreateReminderModal}
              >
                Set reminder
              </button>
            </div>
          </Col>
        </Row>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderCreateReminderModal = () => {
    return (
      <>
        <Modal
          closable={false}
          open={this.state.createReminder}
          onCancel={this.handleCreateCancel}
          footer={null}
          centered
          className="main-modal"
        >
          <div className="modal-content">
            <h3 className="modal-heading"> Create Reminder</h3>
          </div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={this.onSetReminderFinish}
            autoComplete="off"
            ref={this.formRef}
            initialValues={{ remember: false }}
          >
            <div className="create-reminder-container">
              <Form.Item
                name="companyname"
                label=""
                rules={[
                  {
                    required: true,
                    message: "Please select company name",
                  },
                ]}
              >
                <Select
                  bordered={false}
                  showArrow={false}
                  className="contact_select_input"
                  placeholder={
                    <p className="select_placeholder">Select Company</p>
                  }
                  onChange={this.handleSelectCompany}
                >
                  {this.state.allCompanies.map((item: any) => (
                    <Select.Option
                      value={item?.data?.attributes?.id}
                      key={item.data.id}
                      name="companyname"
                    >
                      {item?.data?.attributes?.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="note-area-box">
                <Form.Item
                  name="note"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "Please add note",
                    },
                  ]}
                >
                  <Input className="note-area" placeholder="Add Note" />
                </Form.Item>
              </div>
              <div className="note-area-box">
                <Row gutter={[8, 8]}>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <Form.Item
                      name="reminderdate"
                      label=""
                      rules={[
                        {
                          required: true,
                          message: "Please select date",
                        },
                      ]}
                    >
                      <DatePicker
                        placement="bottomRight"
                        className="reminderDatepicker"
                        placeholder={"Select date"}
                        disabledDate={this.disabledReminderPastDate}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="note-area-box">
                <Row gutter={[8, 8]}>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <h3 className="select-text"> Document Type</h3>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      name="documenttype"
                      label=""
                      rules={[
                        {
                          required: true,
                          message: "Please select document type",
                        },
                      ]}
                    >
                      <Select
                        bordered={false}
                        showArrow={true}
                        className="contact_select_input"
                        placeholder={
                          <p className="select_placeholder">
                            Select document type
                          </p>
                        }
                        onChange={this.handleSelectDocumentType}
                      >
                        {this.state.allDocuments.map((item: any) => (
                          <Select.Option
                            value={item.id}
                            key={item.id}
                            name="documenttype"
                          >
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <div className="note-area-box">
                <Row gutter={[8, 8]}>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <h3 className="select-text"> Reminder Type</h3>
                  </Col>
                  <Col lg={12} md={12} sm={24} xs={24}>
                    <Form.Item
                      name="remindertype"
                      label=""
                      rules={[
                        {
                          required: true,
                          message: "Please select reminder type",
                        },
                      ]}
                    >
                      <Select
                        bordered={false}
                        showArrow={true}
                        className="contact_select_input"
                        placeholder={
                          <p className="select_placeholder">
                            Select reminder type
                          </p>
                        }
                        onChange={this.handleSelectFrequencies}
                      >
                        {this.state.frequencies.map((item: any) => (
                          <Select.Option
                            value={item.id}
                            key={item.id}
                            name="remindertype"
                          >
                            {item?.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="pswrd-submit-btn">
              <button
                type="button"
                className="reminder-close-btn"
                onClick={this.handleCreateCancel}
              >
                Cancel
              </button>
              <Form.Item>
                <Button
                  htmlType="submit"
                  className="reminder-btn save-btn"
                  disabled={this.state.doubleClickStop}
                >
                  Create Reminder
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Modal>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <>
        <div className="home-height-set">
          <NavbarCommonComponent
            displaySpinner={() => { } }
            hideLoader={() => { } }
            showLoader={() => { } }
            history={undefined}
            navigation={undefined}
            id={""}
            location={undefined} 
            isNotificationUpdate={false}          />
          <div className="Main_container">
            {this.renderRemindersHeaderPart()}
            <TableCommonComponent
              paginationTitle="Reminders per page"
              handlePageSize={this.handleChangeRemindersPageSize}
              dataList={this.state.dataList}
              callingColumnsFeature={"reminder"}
              props={this.props}
              employeeName={undefined}
              dueDateSet={undefined}
              upload={undefined}
              uploadDoc={undefined}
              uplodeId={undefined}
              pageSize={this.state.pageSize}
            />
            {this.renderCreateReminderModal()}
          </div>
        </div>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(EmployeeReminders);
export { EmployeeReminders };
// Customizable Area End
