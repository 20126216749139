import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import { message as MESSAGE, notification } from "antd";
import { withLoaderProps } from "../../../components/src/DocumentLoader.web";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouteComponentProps & withLoaderProps & {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    isNotificationUpdate:boolean
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  userValues: any;
  isHomeFiltersort: any;
  dataList:any;
  isLogout:any;
  companyName:any;
  filteredData:any
  indOrderNext:any
  itemsPerPage:any;
  data:any;
  selectedList:any;
  isDataCount:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CompanyHomeController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
   getCompanyHomeApiCallId: any;
   deleteCompanyFcmTokenApiCallId: any;
   NotificationApiCallID:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      userValues: null,
      isHomeFiltersort: false,
      dataList: [],
      data:[],
      isLogout:false,
      companyName:"",
      filteredData:[],
      indOrderNext:0,
      itemsPerPage:5,
      selectedList:[],
      isDataCount:""
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidUpdate(prevProps: any) {
    if(prevProps.isNotificationUpdate !== this.props.isNotificationUpdate){
      this.getNotifications()
    }
  }

  async componentDidMount() {
    this.getCompanyHomeDetailsShow();
    this.getNotifications()
  }


  companyHomeApiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    let token = (await localStorage.getItem("token")) || "";
    let companyHomeReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    companyHomeReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body &&
    companyHomeReqMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      body
    );
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    companyHomeReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    companyHomeReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(companyHomeReqMessage.id, companyHomeReqMessage);
    return companyHomeReqMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const companyHomeApiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let companyHomeResJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let companyHomeErrorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if(companyHomeResJson){
        if (companyHomeResJson.status === 500) {
          MESSAGE.error(`${companyHomeResJson.error}. Please try again later.`, 4);
          return;
        }else if(companyHomeResJson.data){
            this.props.hideLoader()
            this.getCompanyHomeShowSuccessCallBack(companyHomeApiCallId, companyHomeResJson);
        }
        else if (companyHomeResJson.message) {
          this.deleteCompanyFcmTokenShowSuccessCallBack(companyHomeApiCallId);
        }
        else if(companyHomeResJson.errors){
            this.props.hideLoader()
            this.getCompanyHomeShowFailureCallBack(companyHomeApiCallId, companyHomeResJson);
        }
      }else if (companyHomeErrorJson) {
        
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
  }

  handlefiltersort = () => {
    this.setState({ isHomeFiltersort: !this.state.isHomeFiltersort, });
  };

  getCompanyHomeDetailsShow = async () => {
    this.props.showLoader()
    this.getCompanyHomeApiCallId = await this.companyHomeApiCall({
      contentType: configJSON.getCompanyHomeAPiContentType,
      method: configJSON.getCompanyHomeApiMethod,
      endPoint: configJSON.getCompanyHomeAPiEndPoint,
    });
  };

  getCompanyHomeShowFailureCallBack = (apiCallId:any, error: any) => {
    if(apiCallId === this.getCompanyHomeApiCallId){
      MESSAGE.error(error, 4);
    }
    if (apiCallId === this.NotificationApiCallID) {
      MESSAGE.error(error, 4);
    }
  };

  getCompanyHomeShowSuccessCallBack = async (apiCallId:any, res: any) => {
    if(apiCallId === this.getCompanyHomeApiCallId){
      this.setState({ 
        data: res.data.attributes, 
        companyName: res.data.attributes.name,
      });
    }
    if (apiCallId === this.NotificationApiCallID) {
      let unReadCount = res?.data?.filter((item:any)=>{ return item?.attributes?.is_read === false} )
      this.setState({
          isDataCount : unReadCount.length ,
      });
    }

  };

  handleCompanyLogut = () => {
    localStorage.clear();
    this.setState({
      isLogout: true
    },()=> {
      this.props.history.push("/");
    });
  };

  handleDocumentsHomeFilter = (selectedList: any) => {
    let filteredData = this.state.data;
    let filteredPendingDocs = filteredData.pending_docs.data.length > 0 
    ? this.documentsFilterHandler(selectedList, filteredData.pending_docs.data) : [];

    let filteredUploadedDocs = filteredData.non_pending_docs.data.length > 0 
    ? this.documentsFilterHandler(selectedList, filteredData.non_pending_docs.data) : [];

    filteredData = { ...filteredData,
        employee: { ...filteredData.employee },
        pending_docs : { data:filteredPendingDocs },
        non_pending_docs: { data: filteredUploadedDocs}
     }

    if(selectedList.length > 0){
      this.setState({
        filteredData: filteredData
      });
    }else{
      this.setState({
        filteredData: []
      });
    }
    return filteredData;
  };

  companyHomeCheckboxFilterHandler = (e:any,item:any) => {
    const { data, selectedList } = this.state;

    let updatedSelectedList = [...selectedList];

    if(e.target.checked){
      updatedSelectedList.push(item.attributes.name);
    }else{
      updatedSelectedList = updatedSelectedList.filter(val => !item.attributes.name.includes(val));
    }
    this.setState({selectedList: updatedSelectedList});
    this.handleDocumentsHomeFilter(updatedSelectedList);
  };

  resetFilters = () => {
    this.setState({selectedList:[], filteredData:[]});
  };

  documentsFilterHandler = (selectedList:any ,arr: any) => {
    return arr.filter((docItem:any)=>{
      if(selectedList.includes(docItem.attributes.name)) {
        return true;
      }
      return false;
    })
  };

  deleteCompanyFcmToken = async () => {
    this.deleteCompanyFcmTokenApiCallId = await this.companyHomeApiCall({
      method: configJSON.deleteFcmTokenAPiMethod,
      endPoint: configJSON.deleteFcmTokenAPiEndPoint,
      contentType: configJSON.deleteFcmTokenApiContentType,
    });
    localStorage.clear();
  };

  deleteCompanyFcmTokenShowFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  deleteCompanyFcmTokenShowSuccessCallBack = async (companyHomeApiCallId: any) => {
    if (companyHomeApiCallId == this.deleteCompanyFcmTokenApiCallId) {
      this.handleCompanyLogut();
    }
  };

  getNotifications = async () => {
    this.NotificationApiCallID = await this.companyHomeApiCall({
      contentType: configJSON.getNotificationsApiContentType,
      method: configJSON.getNotificationsAPiMethod,
      endPoint: configJSON.getNotificationsAPiEndPoint,
    });
  };

  // Customizable Area End
}
