//** App.tsx - WEB ***//
import React, { Component } from 'react';
import WebRoutes from './WebRoutes';
import HomeScreen from '../../components/src/HomeScreen';
import './App.css';
import 'antd/dist/antd.css';
import { getTokenData } from './firebaseInit.js';


if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: 'HomeScreen'
  };
  const homeScreen = new HomeScreen(defaultProps);
}

class App extends Component {

  async componentDidMount() {
    Notification.requestPermission().then((permission) => {
      getTokenData();
    });
  }

  render() {
    return (
      <>
        <WebRoutes />
      </>
    );
  }
}

export default App;
