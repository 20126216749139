import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../../../components/src/DocumentLoader.web";
// Customizable Area End

export const configJSON = require("../config");

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  isUserLoggedIn: any;
  userValues: any;
  isCompanyFiltersort: any;
  profileImage:any;
  employeeCompanydata:any;
  employeeCompanyname:any;
  dataCompanyForFilterSort:any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployeeCompanyController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
   getEmployeeCompanyApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      isUserLoggedIn: false,
      userValues: null,
      isCompanyFiltersort: false,
      profileImage:"",
      employeeCompanydata: [],
      employeeCompanyname: [],
      dataCompanyForFilterSort:[],
      // Customizable Area End
    };

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.checkUserStatus();
    this.getEmployeeCompanyDetailsShow();
  }

  checkUserStatus = async () => {
    let token = await localStorage.getItem("token");
    if (token) {
      this.setState({ isUserLoggedIn: true });
    }
  };

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = (await localStorage.getItem("token")) || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }
      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.getEmployeeCompanyApiCallId) {
          this.getEmployeeCompanyShowSuccessCallBack(responseJson);
        }

      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.getEmployeeCompanyApiCallId) {
          this.getEmployeeCompanyShowFailureCallBack(responseJson);
        }
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
  }

  handlefiltersortcompany = () => {
    this.setState({ isCompanyFiltersort: !this.state.isCompanyFiltersort , dataCompanyForFilterSort : this.state?.dataCompanyForFilterSort?.length > 0 ? this.state?.dataCompanyForFilterSort : this.state?.employeeCompanyname });
  };
  handleCreateCompanyAccount = () => {
    this.props.history.push("/employee/create/companyaccount")
 }
 
getEmployeeCompanyDetailsShow = async () => {
  this.props.showLoader()
  this.getEmployeeCompanyApiCallId = await this.apiCall({
    contentType: configJSON.getEmployeeCompaniesApiContentType,
    method: configJSON.getEmployeeCompaniesAPiMethod,
    endPoint: configJSON.getEmployeeCompaniesAPiEndPoint,
  });
};

getEmployeeCompanyShowFailureCallBack = (error: any) => {
  MESSAGE.error(error, 4);
  this.props.hideLoader();
};

getEmployeeCompanyShowSuccessCallBack = async (res: any) => {
  res.data.attributes.companies.map((val: any, index: any) => {
    val["isSelect"] = false;
});
  this.setState({ employeeCompanydata: res.data.attributes , employeeCompanyname: res.data.attributes.companies});
  this.props.hideLoader();
};
 
companyFiltredData = (data:any) => {
  const companyFiltredData = this.state.employeeCompanyname
  let filtredDataForSelect = companyFiltredData.filter((compItem:any)=>{
    if(compItem.isSelect){
      return compItem
    }
  })
  this.setState({
    dataCompanyForFilterSort:filtredDataForSelect,
  })
}
companyCheckBoxChangeFilter = (e:any,item:any) => {
  const companData = this.state.employeeCompanyname
  if(e.target.checked){
    let dataItemForSelect = companData.map((compItem:any)=>{
      if(compItem.data.id === item.data.id){
        compItem.isSelect = true
      }
    })
    this.companyFiltredData(dataItemForSelect)
  }else {
    let dataItemForSelect = companData.map((compItem:any)=>{
      if(compItem.data.id === item.data.id){
        compItem.isSelect = false
      }
    })
    this.companyFiltredData(dataItemForSelect)
  }
}

handleCompanyFilter =  () => {
  let companyFilterData = this.state.employeeCompanyname
 let x = companyFilterData.map((filItem:any)=>{
    if (filItem.isSelect) {
      filItem.isSelect = false
    }
   return filItem
  }
  )
  this.setState({
    dataCompanyForFilterSort : [],
    employeeCompanyname : x
  })
}

}
// Customizable Area End
