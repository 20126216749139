
import React from "react";
// Customizable Area Start
import './TableCommonComponent.css';
import { Checkbox } from 'antd';
// Customizable Area End

type FilterCompanyCommonComponentProps = {
  // Customizable Area Start
  data: any;
  isOpenFilter: any;
  checkBoxChangeFilter: any;
  selectedList: any;
  resetFilters: any;
  // Customizable Area End
};

const FilterCompanyCommonComponent = ({
  // Customizable Area Start
  data,
  isOpenFilter,
  checkBoxChangeFilter,
  selectedList,
  resetFilters
  // Customizable Area End
}: FilterCompanyCommonComponentProps) => {
  // Customizable Area Star
  
  return (
    <>
      {isOpenFilter ? (
        <div className="company-filter-documents">
            <div className="documents-filter-sort-box">
                <div className="documents-filter-actions">
                  <h3 >Filter by :</h3>
                  <button onClick={resetFilters}>Clear all</button>
                </div>
                <div className="documents-filter-box">
                  {data?.pending_docs?.data?.length > 0 ? data?.pending_docs?.data?.map((item: any) => {
                    return (
                        <Checkbox key={item.id} className="checkox-style" checked={selectedList.includes(item.attributes.name)} onChange={(e:any)=>checkBoxChangeFilter(e,item)}>
                          {item.attributes.name}
                        </Checkbox>
                      )
                    })
                  : <></>}

                  {data?.non_pending_docs?.data?.length > 0  ? data.non_pending_docs?.data?.map((item: any) => {
                    return (
                        <Checkbox key={item.id} className="checkox-style" checked={selectedList.includes(item.attributes.name)} onChange={(e:any)=>checkBoxChangeFilter(e,item)}>
                          {item.attributes.name}
                        </Checkbox>
                      )
                    })
                  : <></>}
                  {data?.length > 0  ? data?.map((item: any) => {
                    return (
                        <Checkbox key={item.id} className="checkox-style" checked={selectedList.includes(item.attributes.name)} onChange={(e:any)=>checkBoxChangeFilter(e,item)}>
                          {item.attributes.name}
                        </Checkbox>
                      )
                    })
                  : <></>}
                </div>
            </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default FilterCompanyCommonComponent
// Customizable Area End
