import React from "react";
// Customizable Area Start
import { Table, Select } from "antd";
import { mail } from './assets';
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
// Customizable Area End

type TableCommonComponentProps = {
  // Customizable Area Start

  dataList: any;
  callingColumnsFeature: any;
  props: any;
  employeeName: any;
  dueDateSet: any;
  upload: any;
  uploadDoc: any;
  uplodeId: any;
  pageSize: any;
  handlePageSize: any;
  paginationTitle: any;
  // Customizable Area End
};

const TableCommonComponent = ({
  // Customizable Area Start
  dataList,
  callingColumnsFeature,
  props,
  employeeName,
  dueDateSet,
  uploadDoc,
  uplodeId,
  pageSize,
  handlePageSize,
  paginationTitle
  // Customizable Area End
}: TableCommonComponentProps) => {
  // Customizable Area Start

  const sorter = (valA: any, valB: any) => {
    if (valA === "") {
      return 1;
    } else if (valB === "") {
      return -1;
    } else if (valA?.toLowerCase() < valB?.toLowerCase()) {
      return -1;
    } else if (valA?.toLowerCase() > valB?.toLowerCase()) {
      return 1;
    }
    return 0;
  };

  const employeeHomeColumnprofile = {
    title: 'Company Profile',
    dataIndex: 'data',
    width: '20%',
    className: "main-table-columns",
    sorter: (a: any, b: any) => sorter(a?.data?.attributes?.name, b?.data?.attributes?.name),
    render: (data: any) =>
      <div className='content-box' onClick={() => props.history.push("/employee/company/details", { companyDetails: data?.attributes, employeeName, })}>
        <div className="company-logo-name-container">
          <div className='company-logo-box'>
            <img src={data?.attributes?.logo?.url} className="logo-companies" />
          </div>
          <p className='company-name-title'>
            {data?.attributes?.name}
          </p>
        </div>
        <p className='content-name'>
          {data?.attributes?.email}
        </p>
      </div>
  }

  const employeeHomeColumnpending = {
    title: 'Pending Documents',
    dataIndex: 'data',
    className: "main-table-columns",
    width: '20%',
    sorter: (a: any, b: any) => a.data.attributes.id - b.data.attributes.id,
    render: (data: any) => data?.attributes?.documents.pending?.map((pendingitem: any) =>
      <div className='content-box' key={pendingitem?.id}>
        <p className='content-name'>
          {pendingitem.name}
        </p>
      </div>
    ),
  }

  const employeeHomeColumnUploaded = {
    title: 'Uploaded Documents',
    dataIndex: 'data',
    className: "main-table-columns",
    width: '20%',
    sorter: (a: any, b: any) => a.data.attributes.id - b.data.attributes.id,
    render: (data: any) => data?.attributes?.documents.uploaded?.map((uploaditem: any) => {
      return (
        <div className='content-box'>
          <div className="Upload_doc-name-box">
          <p className='upload-title'
            onClick={() => props.history.push({ pathname: "/employee/view/docs", state: { documentFilesData: uploaditem, companyId: data?.attributes?.id } })}
          >
            {uploaditem?.name}
          </p>
          <button className='upload-view-btn'
          onClick={() => props.history.push({ pathname: "/employee/view/docs", state: { documentFilesData: uploaditem, companyId: data?.attributes?.id } })}
        >
          View 
        </button>
          </div>
        </div>
      )
    }
    ),
  }

  const employeeHomeColumnduedate = {
    title: 'Due Date',
    dataIndex: 'data',
    className: "main-table-columns",
    width: '12%',
    sorter: (a: any, b: any) => a.data.attributes.id - b.data.attributes.id,
    render: (data: any) =>
      <div className="due-date-box upload-width">
        <span className='date-title ' >
          {data?.attributes.leastDeadlineDate === "Invalid date" ? "" : data?.attributes.leastDeadlineDate}
        </span>
      </div>
  }

  const employeeAllFilesColumnUploadDate = {
    title: 'Upload Date',
    dataIndex: 'data',
    className: "main-table-columns",
    width: '13%',
    sorter: (a: any, b: any) => a.data.attributes.id - b.data.attributes.id,
    render: (data: any) => data?.attributes?.documents.uploaded?.map((item: any) => {
      return (
        <div className="due-date-box upload-width">
          <span className='date-title'>
            {item.upload_date}
          </span>
        </div>
      )
    }
    )
  }

  const employeeHomeColumnimg = {
    title: '',
    dataIndex: 'data',
    className: "main-table-columns",
    width: '10%',
    render: (data: any) => (
      <div className='mail-imgbox'>
           <img src={mail} className="mail-img" />
      </div>
    )
  }

  const employeeAllfilesdownloadimg = {
    title: '',
    dataIndex: 'data',
    className: "main-table-columns",
    width: '15%',
    render: (data: any) => data?.attributes?.documents.uploaded?.map((uploaditem: any) =>
      <div className="download-container">
        <button className='mail-imgbox view-button'
          onClick={() => props.history.push({ pathname: "/employee/view/docs", state: { documentFilesData: uploaditem, companyId: data?.attributes?.id } })}
        >
          View 
        </button>
      </div>
    )
  }

  const employeeAllfilesNewDoc = {
    title: '',
    dataIndex: 'data',
    className: "main-table-columns",
    width: '15%',
    render: (data: any) =>
      <div className="download-container">
        <button className='mail-imgbox view-button'
          onClick={() => props.history.push({ pathname: "/employee/add/newdocument", state: { companyId: data?.attributes?.id } })}
        >
          Add document
        </button>
      </div>
  }

  const employeeDocName = {
    title: 'Document name',
    dataIndex: 'data',
    width: '18%',
    className: "main-table-columns",
    sorter: (a: any, b: any) => sorter(a?.data?.attributes?.name, b?.data?.attributes?.name),
    render: (data: any) => (
      <div className='content-box'>
        <p className='content-name'>
          {data?.attributes?.name}
        </p>
      </div>
    )
  }

  const employeeDocStatus = {
    title: 'Status',
    dataIndex: 'data',
    width: '15%',
    className: "main-table-columns",
    sorter: (a: any, b: any) => sorter(a?.data?.attributes?.status, b?.data?.attributes?.status),
    render: (data: any) =>
      <div className='content-box'>
        <p className='content-name-status'>
          {data?.attributes?.status}
        </p>
      </div>
  }

  const employeeDeadlineDate = {
    title: 'Deadline Date',
    dataIndex: 'data',
    width: '18%',
    className: "main-table-columns",
    sorter: (a: any, b: any) => sorter(a?.data.attributes.deadline_date, b?.data.attributes.deadline_date),
    render: (data: any) =>
      <div className='content-box'>
        <p className='content-name upload-width'>
          {data.attributes.deadline_date}
        </p>
      </div>
  }

  const employeeUploadby = {
    title: 'Uploaded by',
    dataIndex: 'data',
    width: '15%',
    className: "main-table-columns",
    sorter: (a: any, b: any) => sorter(a?.data.attributes.uploaded_by, b?.data.attributes.uploaded_by),
    render: (data: any) =>
      <div className='content-box'>
        <p className='content-name'>
          {data.attributes.uploaded_by}
        </p>
      </div>
  }

  const employeeUploadDate =
  {
    title: 'Upload Date',
    dataIndex: 'data',
    className: "main-table-columns",
    width: '20%',
    sorter: (a: any, b: any) => sorter(a?.data.attributes.upload_date, b?.data.attributes.upload_date),
    render: (data: any) =>
      <div className="due-date-box upload-width">
        <span className='date-title'>
          {data.attributes.upload_date}
        </span>
      </div>
  }

  const employeeUploadbtn =
  {
    title: '',
    dataIndex: 'data',
    className: "main-table-columns",
    width: '15%',
    render: (data: any) =>
      <>
        {["approved", "uploaded"].includes(data.attributes.status)
          ? <></>
          : <input
            id="myInput"
            type="file"
            name="upload"
            multiple
            style={{ display: "none" }}
            onChange={(e: any) => uploadDoc(e, data.attributes)}
          />}
        <div className="aprrove-btns-container">
          <label
            className={`${["approved", "uploaded"].includes(data.attributes.status) ? "disabled-btn  upload" : "approve-btn  upload"}`}
            htmlFor="myInput"
            onClick={(e) => ["approved", "uploaded"].includes(data.attributes.status) ? e.preventDefault() : uplodeId(data.attributes)}
          >
            Upload
          </label>
        </div>
      </>
  }

  const employeeReminderCreatedBy = {
    title: 'Created On',
    dataIndex: 'attributes',
    width: '18%',
    className: "main-table-columns",
    sorter: (a: any, b: any) => sorter(a?.attributes.due_date, b?.attributes.due_date),
    render: (attributes: any) =>
      <>
        <div className='content-box'>
          <p className='content-name upload-width'>
            {attributes?.created_at}
          </p>
        </div>
      </>
  }

  const employeeReminderDeadlineDate = {
    title: 'Deadline Date',
    dataIndex: 'attributes',
    width: '17%',
    className: "main-table-columns",
    sorter: (a: any, b: any) => sorter(a?.attributes.due_date, b?.attributes.due_date),
    render: (attributes: any) =>
      <div className='content-box'>
        <p className='content-name upload-width'>
          {attributes?.due_date}
        </p>
      </div>
  }

  const employeeReminderFor = {
    title: 'For',
    dataIndex: 'attributes',
    width: '15%',
    className: "main-table-columns",
    sorter: (a: any, b: any) => sorter(a?.attributes.due_date, b?.attributes.due_date),
    render: (attributes: any) =>
      <div className='content-box'>
        <p className='content-name reminder-width'>
          {attributes?.specific_company?.name}
        </p>
      </div>
  }

  const employeeReminderDocType = {
    title: 'Document Type',
    dataIndex: 'attributes',
    width: '17%',
    className: "main-table-columns",
    sorter: (a: any, b: any) => sorter(a?.attributes.due_date, b?.attributes.due_date),
    render: (attributes: any) =>
      <div className='content-box'>
        <p className='content-name'>
          {attributes?.document_type}
        </p>
      </div>
  }

  const employeeReminderType = {
    title: 'Reminder Type',
    dataIndex: 'attributes',
    width: '17%',
    className: "main-table-columns",
    sorter: (a: any, b: any) => sorter(a?.attributes.due_date, b?.attributes.due_date),
    render: (attributes: any) =>
      <div className='content-box'>
        <p className='content-name'>
          {attributes?.frequency}
        </p>
      </div>
  }

  const employeeReminderViewButton = {
    title: '',
    dataIndex: 'attributes',
    width: '10%',
    className: "main-table-columns",
    render: (attributes: any) =>
      <div className="download-container">
        <button className='mail-imgbox view-button'
          onClick={() => props.history.push({ pathname: "/employee/view/reminder", state: { reminderId: attributes?.id } })
          }
        >
          View reminder
        </button>
      </div>
  }

  const columnsEmployeeHome = [
    employeeHomeColumnprofile,
    employeeHomeColumnpending,
    employeeHomeColumnduedate,
    employeeHomeColumnUploaded,
    // employeeHomeColumnimg,
  ];

  const columnsEmployeeAllFiles = [
    employeeHomeColumnprofile,
    employeeAllFilesColumnUploadDate,
    employeeHomeColumnUploaded,
    employeeAllfilesNewDoc
  ];

  const columnsEmployeeUploadDoc = [
    employeeDocName,
    employeeUploadby,
    employeeUploadDate,
    employeeDeadlineDate,
    employeeDocStatus,
    employeeUploadbtn,
  ];

  const columnsEmployeeReminder = [
    employeeReminderCreatedBy,
    employeeReminderFor,
    employeeReminderDocType,
    employeeReminderDeadlineDate,
    employeeReminderType,
    employeeReminderViewButton,
  ];

  const onChange = (pagination: any, filters: any, sorter: any, extra: any) => {
    console.log('params');
  };

  return (
    <Table columns={
      (callingColumnsFeature === "home") ? columnsEmployeeHome :
        (callingColumnsFeature === "reminder") ? columnsEmployeeReminder :
          (callingColumnsFeature === "allFiles") ? columnsEmployeeAllFiles :
            (callingColumnsFeature === "upload") ? columnsEmployeeUploadDoc : columnsEmployeeHome
    }
      dataSource={dataList}
      onChange={onChange}
      className="main_table_style"
      pagination={pageSize === null
        ? false
        : {
          showSizeChanger: true,
          selectComponentClass: () => (
            <div className="custom-page-selection-drop">
              <p>View</p>
              <Select
                style={{
                  width: 120,
                }}
                onChange={(value) => {
                  handlePageSize(value);
                }}
                value={pageSize}
                options={[
                  {
                    value: '10',
                    label: '10',
                  },
                  {
                    value: '15',
                    label: '15',
                  },
                  {
                    value: '20',
                    label: '20',
                  },
                  {
                    value: '25',
                    label: '25',
                  },
                ]}
              />
              <p>{paginationTitle}</p>
            </div>
          ),
          showTitle: true,
          pageSize: pageSize,
          pageSizeOptions: [10, 15, 20],
          itemRender: (page: any, type: any, elem: any) => {
            if (type === "prev") {
              return <p className="ant-table-pagination-custom-prev"><span ><LeftOutlined translate={undefined} /></span>Prev</p>;
            }
            if (type === "next") {
              return <p className="ant-table-pagination-custom-next">Next<span ><RightOutlined translate={undefined} /></span></p>;
            }
            return elem;
          }
        }
      }
    />
  );
  // Customizable Area End
};
// Customizable Area Start
export default TableCommonComponent
// Customizable Area End
