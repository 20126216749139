import React from "react";
// Customizable Area Start
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  Row,
  DatePicker,
  Button,
  Col,
  Select,
} from "antd";
import {
  Link
} from 'react-router-dom';
import { createlogo, docsearch, galleryimg } from "../assets";
import moment from "moment";
import EmployeeCreateCompanyAccountController from "./EmployeeCreateCompanyAccountController.web";
import DocumentLoader from "../../../../components/src/DocumentLoader.web";
// Customizable Area End

 class EmployeeCreateCompanyAccount extends EmployeeCreateCompanyAccountController {
  // Customizable Area Start
  renderAccountHeader = () => {
    return (
      <>
        <div className="accnt-header-box">
          <div className="accnt-logo-box">
            <Link to="/employee/home">
              <img src={createlogo} className="accnt-logo" />
            </Link>
          </div>
          <button data-test-id="goToPrev" className="accnt-cross-box" onClick={this.handlepreviouspage}>
            <CloseOutlined className="cross-icon" translate={undefined} />
          </button>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderAccountForm = () => {
    const { openStaffForm, addedStaff } = this.state;
      const worker = {
        documentdatepicker: moment('')
      };
      return (
        <>
          <div className="form-title-box">
            <h3 className="form-title-text">Create new company account</h3>
          </div>
          <div className="accnt-form-container">
            <div className="form-start">
              <>
              </>
              <button data-test-id="inputCompanyLogoAdd" className="logo-upload-button" onClick={() => this.upload?.click()}>
                <div className="add_logo_box">
                  <input
                    id="myInput"
                    type="file"
                    ref={(ref) => this.upload = ref}
                    data-test-id="btnUploadCompanyLogo"
                    accept="image/*"
                    
                    style={{ display: "none" }}
                    onChange={this.companyLogoHandler}
                  />
                  {!!this.state.companyLogoImage && !!this.state.companyLogoImage.url 
                    ?  <img src={this.state.companyLogoImage.url} className="gallery-icon-selected" />
                    :<>
                    <p className="ant-upload-drag-icon">
                        <img src={galleryimg} className="gallery-icon" />
                    </p>
                    <p className="upload-text">Add logo file</p>
                    </> 
                  }
                </div>
              </button>
              <Form
                name="basic"
                layout="vertical"
                onFinish={this.onAccountFinish}
                autoComplete="off"
                className="create-accnt-form"
                initialValues={worker}
                ref={this.formRef}
              >
                <Row className="">
                  <p className="form-text">Company name</p>
                </Row>
                <Form.Item
                  name="name"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "Please input company name",
                    },
                  ]}
                >
                  <Input data-test-id={"companyname"} className="form-accnt-box" name="name" />
                </Form.Item>
                <Row className="">
                  <p className="form-text">Stakeholder of the company</p>
                </Row>
                <Form.Item
                  name="stakeholder"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "Please input stakeholder of the company",
                    },
                    {
                      pattern:
                        /[A-Za-z]/,
                      message:
                        "Please enter only characters",
                    },
                  ]}
                >
                  <Input data-test-id={"stackholdername"} className="form-accnt-box" name="stakeholder" />
                </Form.Item>
                <Row className="">
                  <p className="form-text">Company type</p>
                </Row>
                <Form.Item
                  name="companytype"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "Please select company type",
                    },
                  ]}
                >
                  <Select
                    data-test-id={"companyTypeInputID"}
                    bordered={false}
                    showArrow={false}
                    className="form-accnt-box"
                    suffixIcon={
                      <DownOutlined
                        className="contact_select_down_icon"
                        translate={undefined}
                      />
                    }
                    onChange={this.handleCompanyTypes}
                    placeholder={
                      <p className="select_placeholder">Select company type</p>
                    }
  
                  >
                    {this.state.companyTypes.map((item: any) => (
                      <Select.Option value={item.id} key={item.id} name="companytype">
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Row className="">
                  <p className="form-text">Company contact</p>
                </Row>
                <Form.Item
                  name="companycontact"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "Please input number ",
                    },
                    {
                      pattern: new RegExp(/^\d+$/),
                      message: "Please input numbers only ",
                    },
                    { min: 7, message: 'Minimum 7 numbers are allowed' },
                    { max: 10, message: 'Maximum 10 numbers are allowed' },
                  ]}
                >
                  <Input data-test-id={"companycontact"} className="form-accnt-box" name="companycontact" type="input" onChange={this.handleAlreadyTaken}/>
                </Form.Item>
                {this.state.alreadyTakenContact ? <p className='error-password'>Company contact already exists </p> : ""}
                <Row className="">
                  <p className="form-text">Email</p>
                </Row>
                <Form.Item
                  label=""
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email" },
                    {
                      type: "email",
                      message: "Please enter a valid Email address",
                    },
                  ]}
                >
                  <Input  data-test-id={"companyemail"} className="form-accnt-box" name="email" onChange={this.handleNewCompanyEmail}/>
                </Form.Item>
                {this.state.alreadyTaken ? <p className='error-password'>Email address already exists </p> : ""}
                <Row className="">
                  <p className="form-text">Password</p>
                </Row>
                <Form.Item
                  name="password"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "Please input your password ",
                    },
                    {
                      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,15}$/,
                      message:
                        "Password must contains 8 to 15 characters and atleast one uppercase, one lowercase, one number and one special character ex.(@,$,!,%,*,?,&,#)",
                    },
                  ]}
                  hasFeedback
                >
                  <Input.Password  data-test-id={"companypassword"} className="form-accnt-box" name="password" />
                </Form.Item>
                <Row className="">
                  <p className="form-text">Address</p>
                </Row>
                <Form.Item
                  name="address"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "Please input address",
                    },
                    { min: 3, message: 'Please input atleast 3 characters' }
                  ]}
                >
                  <Input data-test-id={"companyaddress"} className="form-accnt-box" name="address" />
                </Form.Item>
                <Row className="">
                  <p className="form-text">Rent amount</p>
                </Row>
                <Form.Item
                  name="rentamount"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "Please input rent amount ",
                    },
                    {
                      pattern: new RegExp(/^[0-9.]+$/),
                      message: "Please input numbers only ",
                    },
                  ]}
                >
                  <Input data-test-id={"companyrentamount"}  className="form-accnt-box" name="rentamount" type="input" />
                </Form.Item>
                <Row className="">
                  <p className="form-text">Rent due date</p>
                </Row>
                <Form.Item
                  name="datepicker"
                  label=""
                  rules={[
                    {
                      required: true,
                      message: "Please input rent due date ",
                    },
                  ]}
                >
                  <DatePicker
                    className="form-accnt-box"
                    placement="bottomRight"
                    name="datepicker"
                    disabledDate={this.disabledPastDate}
                    data-test-id={"companyrentdue"} 
                  />
                </Form.Item>
                <Row className="docs-needed-container">
                  <Col
                    className="docs-needed-column"
                    lg={24}
                    xl={24}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <p className="docs-text">List of documents needed</p>
                  </Col>
                  <Col
                    className="docs-needed-column"
                    lg={24}
                    xl={24}
                    md={24}
                    sm={24}
                    xs={24}
                  >
                    <div className="docs-needed-box">
                      <div className="docs-header">
                        <div className="doc-searchimgbox">
                          <img className="doc-search-logo" src={docsearch} />
                          <input
                            type="input"
                            placeholder="Search for document"
                            className="docs-input"
                            onChange={(e)=>this.searchFilterFunction(e)}
                            data-test-id={"documentSearchID"}
                          />
                        </div>
  
                      </div>
                      <div className="docs-bottom" >
                        <div className="imp-docs-box">
                          <p className="docs-text">Important Documents</p>
                        </div>
                        <div className="list-box">
                          {this.state.documentTypes?.map((item: any) => {
                            const { name, id } = item
                            return (
                                <p className="listofdocuments" onClick={() => this.handleDocName(name, id)}  data-test-id={"documentTypeInputID"} key={item.id}>
                                  {item.name}
                                </p>
                            )
                          })}
                        </div>
  
                        <div className="imp-docs-box">
                          <p className="docs-text">Recent Search</p>
                        </div>
                          {this.state?.recentSearch?.map((item: any) => {
                            return(
                            <div className="recent-box" key={item.id}>
                              <p className="recent-text" data-test-id={"recentSearchesInputID"} >
                                {item.term} 
                              </p>
                              <span>
                              <CloseOutlined className="cross-icon" translate={undefined} onClick={()=>this.deleteRecentSearch(item.id)} />
                              </span>
                              </div>
                            )
                          })}
                        <div className="imp-docs-box doc-type-box">
                          <p className="docs-add-text">Added Documents</p>
                          <Row className="">
                            <p className="form-text">Document name</p>
                          </Row>
                          <p className="docs-text">Deadline date</p>
                        </div>
                        {this.state.showDocName.map((item: any, index: any) => {
                          return (
                            <>
                              <div className="added-doc-box">
                                <p className="added-doc-name">
                                  {item.docName}
                                </p>
                                <Form.Item
                                  name={`docname${index}`}
                                  label=""
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input document name",
                                    },
                                    {
                                      pattern:
                                        /[A-Za-z]/,
                                      message:
                                        "Please enter only characters",
                                    },
                                  ]}
                                  className="remove-margin"
                                >
                                  <Input className="form-accnt-box doc-name-create" name={`docname${index}`} onChange={(e) => this.documentNameChange(e, index)} data-test-id={"documentNameID"}/>
                                </Form.Item>
                                <div className="doc-deadline-date-box">
                                  <Form.Item
                                    name={`documentdatepicker${index}`}
                                    label=""
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input deadline date ",
                                      },
                                    ]}
                                    className="remove-margin"
                                  >
                                    <DatePicker
                                      className="deadline-datepicker"
                                      placement="bottomRight"
                                      name={`documentdatepicker${index}`}
                                      format={"DD-MM-YYYY"}
                                      showToday={false}
                                      disabledDate={this.disabledPastDate}
                                      onChange={(date, dateString) => this.deadlineDateChange(dateString, index)}
                                      data-test-id={"documentdeadlinedate"} 
                                    />
                                  </Form.Item>
                                </div>
                                <span>
                                  <CloseOutlined className="cross-icon cancel-cross-icon" translate={undefined} onClick={() => this.deleteDocTypeChange(index)} />
                                </span>
                              </div>
                            </>
                          )
                        }
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>  
              <Row>
                <Col
                    lg={24}
                    xl={24}
                    md={24}
                    sm={24}
                    xs={24}
                    className="staff-form-header"
                    >
                    <p>Staff</p>
                    <Button onClick={this.handleAddNewStaffButton}>Add New Staff</Button>
                </Col>
                {addedStaff.length > 0
                ? addedStaff.map((staff: any) => <Row key={staff.id} className="staff-form-outer">
                  <Col
                    className="staff-form-container"
                    lg={24}
                    xl={24}
                    md={24}
                    sm={24}
                    xs={24}
                    > 
                      <Form.Item label={<label className="staff-form-label">Name</label>}>
                        <Input 
                        name="added-staff-name"
                        value={staff.name} 
                        className="staff-input-box"/>
                      </Form.Item>
                      <Form.Item label={<label className="staff-form-label">Email</label>}>
                        <Input 
                        name="added-staff-email"
                        value={staff.email} className="staff-input-box"/>
                      </Form.Item>
                      <div className="staff-form-actions">
                        <Button onClick={(e:any) => this.handleRemoveStaffButton(e,staff.id)}>Remove</Button>
                      </div>
                  </Col>
                </Row>)
                :<></>}
                {openStaffForm 
                ? (<Row className="staff-form-outer">
                    <Col
                      className="staff-form-container"
                      lg={24}
                      xl={24}
                      md={24}
                      sm={24}
                      xs={24}
                      > 
                        <Form.Item 
                        name="staff-name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter staff name",
                              whitespace: true,
                            },
                          ]}
                          label={<label className="staff-form-label" aria-required="false">Name</label>}>
                          <Input 
                            name="staff-name"
                            onChange={this.handleStaffFormInput} 
                            value=""
                          className="staff-input-box"/>
                        </Form.Item>
                        <Form.Item
                        name="staff-email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter staff email",
                            whitespace: true,
                          },
                          {
                            type: "email",
                            message: "Please enter a valid Email address",
                          },
                        ]} 
                        label={<label className="staff-form-label">Email</label>}>
                          <Input 
                            name="staff-email"
                            onChange={this.handleStaffFormInput} 
                            value=""
                            className="staff-input-box"/>
                        </Form.Item>
                        <div className="staff-form-actions">
                          <Form.Item>
                          <Button htmlType="submit"
                            onClick={this.handleAddNewStaff}
                          >Add</Button>
                          </Form.Item>
                          <Button onClick={this.handleRemoveStaffButton}>Remove</Button>
                        </div>
                    </Col>
                </Row>)
                : <></>
                }
             </Row>
              <Form.Item>
                <div className="company-accnt-btn">
                  <Button className="btn-accnt" htmlType="submit" data-test-id={"createcompanyAccountID"} >
                    Create Account
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <div className="company-account-bgcolor">
        {this.renderAccountHeader()}
        {this.renderAccountForm()}
      </div>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(EmployeeCreateCompanyAccount);
export { EmployeeCreateCompanyAccount };
// Customizable Area End