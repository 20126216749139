import React from "react";
// Customizable Area Start
import { Col, Modal, Row } from "antd";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import DocumentLoader from "../../../components/src/DocumentLoader.web";
import EmployeeRemindersController from "./EmployeeRemindersController.web";
// Customizable Area End

class EmployeeViewReminder extends EmployeeRemindersController {
  // Customizable Area Start
  renderReminderNameBox = () => {
    return (
      <>
        <Row>
          <Col lg={24} xl={24} md={24} sm={24} xs={24}>
            <div className="profile-name-box doc-button-group">
              <div className="doc-name-text-box width-remindertype-set">
                <h3 className="profile-name-title view-doc-name">
                  {this.state.reminderDetails?.attributes?.document_type}
                </h3>
              </div>
              <div className="view-document-actions">
                <button
                  type="button"
                  data-test-id="btnDeleteDocType"
                  className="files-button files-button-margin"
                  onClick={this.handleReminderModalOpen}
                >
                  Delete reminder
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderReminderDetailsBox = () => {
    return (
      <>
        <div className="doc-details-box">
          <div className="employe-details-title-box">
            <p className="employe-details-title">Reminder details</p>
          </div>
          <div className="personal-details-box ">
            <Row>
              {this.renderReminderIdAndCompanyName()}
              {this.renderReminderTypeAndDeadlineDate()}
              {this.renderReminderDeadlineDate()}
              {this.renderReminderNote()}
            </Row>
          </div>
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderReminderIdAndCompanyName = () => {
    return (
      <>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Id</p>
            <p className="details-title-text">{this.state.reminderDetails?.attributes?.id}</p>
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">For </p>
            <p className="details-title-text">
              {" "}
              {this.state.reminderDetails?.attributes?.specific_company?.name}
            </p>
          </div>
        </Col>
        </Col>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderReminderTypeAndDeadlineDate = () => {
    return (
      <>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Document type</p>
            <p className="details-title-text">
              {this.state.reminderDetails?.attributes?.document_type}
            </p>
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Reminder type</p>
            <p className="details-title-text">
              {" "}
              {this.state.reminderDetails?.attributes?.frequency}
            </p>
          </div>
        </Col>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderReminderDeadlineDate = () => {
    return (
      <>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Deadline date</p>
            <p className="details-title-text">
              {this.state.reminderDetails?.attributes?.due_date}
            </p>
          </div>
        </Col>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderReminderNote = () => {
    return (
      <>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Created at</p>
            <p className="details-title-text">
            {this.state.reminderDetails?.attributes?.created_at}
            </p>
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Note</p>
            <p className="details-title-text">
              {" "}
              {this.state.reminderDetails?.attributes?.note}
            </p>
          </div>
        </Col>
      </>
    );
  };
  // Customizable Area End

    // Customizable Area Start
    renderDeleteReminderModal = () => {
      return (
        <>
          <Modal
            closable={false}
            footer={null}
            centered
            className="delete-main-modal"
            open={this.state.reminderModalOpen}
            onCancel={this.handleCancelReminderModal}
          >
            <div className="modal-content">
              <h3 className="modal-heading">
              You're about to delete the reminder!
              </h3>
            </div>
            <div className="pswrd-submit-btn">
              <button type="button" className="pswrd-btn companymodalbutton" onClick={this.handleCancelReminderModal}>
                Cancel
              </button>
              <button
                type="button"
                data-test-id="btnDeleteHandler"
                className="pswrd-btn save-btn companymodalbutton"
                onClick={this.deleteDocumentType}
              >
                Delete
              </button>
            </div>
          </Modal>
        </>
      );
    };
    // Customizable Area End

    
  // Customizable Area Start
  render() {
    return (
      <>
        <NavbarCommonComponent
          displaySpinner={() => { } }
          hideLoader={() => { } }
          showLoader={() => { } }
          history={undefined}
          navigation={undefined}
          id={""}
          location={undefined} isNotificationUpdate={false}        />
        <div className="Employee-Pofile-container height-set">
          {this.renderReminderNameBox()}
          {this.renderReminderDetailsBox()}
          {this.renderDeleteReminderModal()}
        </div>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(EmployeeViewReminder);
export { EmployeeViewReminder };
// Customizable Area End
