export const logo = require("../../blocks/email-account-login/assets/logo.png");
export const home = require("../../blocks/landingpage/assets/home.png");
export const profile = require("../../blocks/landingpage/assets/profile.png");
export const shop = require("../../blocks/landingpage/assets/shop.png");
export const pending = require("../../blocks/landingpage/assets/timer.png");
export const file = require("../../blocks/landingpage/assets/file.png");
export const filter = require("../../blocks/landingpage/assets/filter.png");
export const mail = require("../../blocks/landingpage/assets/mail.png");
export const search = require("../../blocks/landingpage/assets/search.png");
export const logocompany = require("../../blocks/landingpage/assets/Image.png");
export const docdownload = require("../../blocks/landingpage/assets/docdownload.png");

