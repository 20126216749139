Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Download";
exports.labelBodyText = "Download Body";
exports.getDownloadFilesEndPoint = "bx_block_download/downloadables/view_downloadables"
exports.postUploadFileEndPoint = "bx_block_download/downloadables/upload"
exports.GetDownloadFileIEndPoint = "bx_block_download/downloadables/download?reference_id=2&reference_type=account"

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End