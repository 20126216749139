import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import type { FormInstance } from "antd/es/form";
import { message as MESSAGE, notification } from "antd";
import moment from "moment";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../../../components/src/DocumentLoader.web";
// Customizable Area End

export const configJSON = require("../config");

export type Props = RouterProps &
  withLoaderProps & {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isUserLoggedIn: any;
  userValues: any;
  isFiltersort: any;
  companyTypes: any;
  selectedData: any;
  documentTypes: any;
  searchedDocumentstype: any;
  showDocName: any;
  newCompnayEmail: any;
  newCompnayPassword: any;
  newCompnayname: any;
  newCompnayStakeholder: any;
  newCompnayaddress: any;
  newCompnayRentAmount: any;
  newCompnayRentDueDate: any;
  newCompnayTypeId: any;
  newCompnayDocName: any;
  newCompnayTypeDeadlineDate: any;
  alreadyTaken: boolean;
  alreadyTakenContact: boolean;
  recentSearch: any;
  newCompnayConatct: any;
  searchedDocument: any;
  companyLogoImage: any,
  newStaff: any;
  addedStaff:any;
  openStaffForm:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployeeCreateCompanyAccountController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCompanyTypeApiCallId: any;
  getDocumentTypeApiCallId: any;
  getRecentSearchApiCallId: any;
  postCreateCompanyApiCallId: any;
  postSearchDocumentApiCallId: any;
  deleteRecentSearchApiCallId: any;
  upload: any;
  formRef: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      isUserLoggedIn: false,
      alreadyTaken: false,
      alreadyTakenContact: false,
      userValues: null,
      isFiltersort: false,
      companyTypes: [],
      selectedData: [],
      documentTypes: [],
      searchedDocumentstype: [],
      searchedDocument: [],
      showDocName: [],
      recentSearch: [],
      newCompnayEmail: "",
      newCompnayPassword: "",
      newCompnayname: "",
      newCompnayStakeholder: "",
      newCompnayaddress: "",
      newCompnayRentAmount: "",
      newCompnayRentDueDate: "",
      newCompnayTypeId: "",
      newCompnayDocName: "",
      newCompnayTypeDeadlineDate: "",
      newCompnayConatct: "",
      companyLogoImage:null,
      newStaff: {
        email:"",
        name:""
      },
      addedStaff: [],
      openStaffForm: false
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.formRef = React.createRef<FormInstance>();
  }

  // Customizable Area Start

  async componentDidMount() {
    this.getCompanyTypeShow();
    this.getDocumentTypeShow();
    this.getRecentSearchShow();
  }

  employeeCreateCompanyAccountApiCall = async (data: any) => {
    const { contentType, method, endPoint, body} = data;
    let token = await localStorage.getItem("token");
    const header = {
      "Content-Type": contentType,
      token,
    };
    const employeeCreateCompanyAccReqMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    employeeCreateCompanyAccReqMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    employeeCreateCompanyAccReqMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    employeeCreateCompanyAccReqMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      employeeCreateCompanyAccReqMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body) 
      );
    runEngine.sendMessage(employeeCreateCompanyAccReqMessage.id, employeeCreateCompanyAccReqMessage);
    return employeeCreateCompanyAccReqMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(responseJson){
          if(responseJson.status === 500){
            MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
            return;
          }
          else if(responseJson.errors){
            this.handleFailureCallbacks(apiRequestCallId, responseJson);
          }
          else{
           this.handleSuccessCallbacks(apiRequestCallId, responseJson);
          } 
      }else if(errorReponse){
        notification["error"]({
          message: "Internal Server Error.",
        });
      } 
    }
  }

  handleSuccessCallbacks = (apiRequestCallId: any,responseJson: any) => {
    switch(apiRequestCallId){

      case this.postCreateCompanyApiCallId : {
        this.props.hideLoader();
        this.postCreateCompanyAccountSuccessCallBack(responseJson);
        break;
      }

      case this.getCompanyTypeApiCallId : {
        this.getCompanyTypeShowSuccessCallBack(responseJson);
        break;
      }

      case this.getRecentSearchApiCallId : {
        this.getRecentSearchShowSuccessCallBack(responseJson);
        break;
      }

      case this.getDocumentTypeApiCallId : {
        this.getDocumentTypeShowSuccessCallBack(responseJson);
        break;
      }

      case this.postSearchDocumentApiCallId : {
        this.postSearchDocumentShowSuccessCallBack(responseJson);
        break;
      }

      case this.deleteRecentSearchApiCallId : {
        this.deleteRecentSearchShowSuccessCallBack(responseJson);
        break;
      }

      default :{
        break;
      }
    }
  };

  handleFailureCallbacks = (apiRequestCallId: any,responseJson: any) => {
    switch(apiRequestCallId) {
      case this.getCompanyTypeApiCallId : {
        this.getCompanyTypeShowShowFailureCallBack(responseJson);
        break;
        }

      case this.getDocumentTypeApiCallId : {
        this.getDocumentTypeShowShowFailureCallBack(responseJson);
        break;
      }
      
      case this.getRecentSearchApiCallId : {
        this.getRecentSearchShowShowFailureCallBack(responseJson);
        break;
      }

      case this.postCreateCompanyApiCallId :{
        this.props.hideLoader();
        this.postCreateCompanyAccountFailureCallBack(responseJson);
        break;
      }

      case this.postSearchDocumentApiCallId :{
        this.postSearchDocumentShowShowFailureCallBack(responseJson);
        break;
      }

      case this.deleteRecentSearchApiCallId :{
        this.deleteRecentSearchShowFailureCallBack(responseJson);
        break;
      }

      default :{
        break;
      }
     }
  };

  handlepreviouspage = () => {
    this.props.history.goBack();
  };

  getCompanyTypeShow = async () => {
    this.getCompanyTypeApiCallId = await this.employeeCreateCompanyAccountApiCall({
      contentType: configJSON.getCreateCompanyTypeApiContentType,
      method: configJSON.getCreateCompanyTypeApiMethod,
      endPoint: configJSON.getCreateCompanyTypeApiEndPoint,
    });
  };

  getCompanyTypeShowSuccessCallBack = (res: any) => {
    this.setState({
      companyTypes: res,
    });
  };

  getCompanyTypeShowShowFailureCallBack = (err: any) => {
    MESSAGE.error(err, 4);
  }
  handleCompanyTypes = (Value: any) => {
    this.setState({
      selectedData: Value,
    });
  };

  getDocumentTypeShow = async () => {
    this.getDocumentTypeApiCallId = await this.employeeCreateCompanyAccountApiCall({
      contentType: configJSON.getCreateDocumentTypeApiContentType,
      method: configJSON.getCreateDocumentTypeApiMethod,
      endPoint: configJSON.getCreateDocumentTypeApiEndPoint,
    });
  };

  getDocumentTypeShowSuccessCallBack = (res: any) => {
    this.setState({
      documentTypes: res,
      searchedDocumentstype: res,
    });
  };

  getDocumentTypeShowShowFailureCallBack = (err: any) => {
    MESSAGE.error(err, 4);
  };

  getRecentSearchShow = async () => {
    this.getRecentSearchApiCallId = await this.employeeCreateCompanyAccountApiCall({
      contentType: configJSON.getRecentSearchApiContentType,
      method: configJSON.getRecentSearchApiMethod,
      endPoint: configJSON.getRecentSearchApiEndPoint,
    });
  };

  getRecentSearchShowSuccessCallBack = (res: any) => {
    this.setState({
      recentSearch: res.recent_searches,
    });
  };

  getRecentSearchShowShowFailureCallBack = (err: any) => {
    MESSAGE.error(err, 4);
  };

  postSearchDocumentShow = async (values:any) => {
    let apiBody = {
        term: values
    };
      this.postSearchDocumentApiCallId = await this.employeeCreateCompanyAccountApiCall({
        contentType: configJSON.postSearchApiContentType,
        method: configJSON.postSearchApiMethod,
        endPoint: configJSON.postSearchApiEndPoint,
        body: apiBody,
      });
    };

  postSearchDocumentShowSuccessCallBack = (res: any) => {
    this.setState({
      searchedDocument: res,
    });
    this.getRecentSearchShow()
  };

  postSearchDocumentShowShowFailureCallBack = (err: any) => {
    MESSAGE.error(err, 4);
  };

  searchFilterFunction = (e: any) => {
    let { value } = e.target;
    let detail = this.state.documentTypes;
    const newData = detail.filter(function(item: any) {
      const titleData = item.name.toLowerCase();
      const titletextData = value.toLowerCase();
      const orderidData = item.id;
      const orderidtextData = value;
      return (
        titleData.toString().indexOf(titletextData) > -1 ||
        orderidData.toString().indexOf(orderidtextData) > -1
      );
    });
    if (value.trim().length === 0) {
      this.setState({ documentTypes: this.state.searchedDocumentstype });
    } else {
      this.setState({ documentTypes: newData });
    }
  };

  handleDocName = (name: any, id: any) => {
    let deadline_date = this.state.newCompnayTypeDeadlineDate;
    this.setState({
      showDocName: [...this.state.showDocName, { name, document_type_id:id, deadline_date,docName:name, validationDeadlineDate:false,validationDocName:false }],
    });
    notification["success"]({
      message: "Document type selected",
    });
    this.postSearchDocumentShow(name)
  };
  deadlineDateChange = (date:any,index:any) => {
    let documentChange = this.state.showDocName
    documentChange[index].deadline_date = date
    documentChange[index].validationDeadlineDate = true
    this.setState({
      showDocName:documentChange
    })
  }
  documentNameChange = (e:any,index:any) => {
    const {value } = e.target
    let documentChange = this.state.showDocName
    documentChange[index].name = value
    documentChange[index].validationDocName = true
    this.setState({
      showDocName:documentChange
    })
  }
  employeeCreateCompanyAccount = async (values: any) => {
    if(this.state.newCompnayaddress.trim() == ""){
      return notification["error"]({
        message: "Please fill the Address field",
      });
    }
    if(this.state.newCompnayname.trim() == ""){
      return notification["error"]({
        message: "Please fill the Company name field",
      });
    }

    if(this.state.openStaffForm){
      return notification["error"]({
        message: "Please add staff details",
      });
    }

    let staffsBody = [];
    if(this.state.addedStaff.length > 0) {
      staffsBody = this.state.addedStaff.map((staff:any) => ({email:staff.email, name: staff.name}))
    }
    this.props.showLoader();
    let apiBody = {
      logo: this.state.companyLogoImage?.url,
      email: values.email,
      password: values.password,
      name: values.name,
      contact: values.companycontact,
      stakeholder: values.stakeholder,
      address: values.address,
      rent_amt: values.rentamount,
      rent_due_date: values.datepicker,
      company_type_id: values.companytype,
      documents_attributes: this.state.showDocName,
      staffs_attributes : staffsBody
    };
    this.postCreateCompanyApiCallId = await this.employeeCreateCompanyAccountApiCall({
      contentType: configJSON.postCreateCompanyApiContentType,
      method: configJSON.postCreateCompanyAPiMethod,
      endPoint: configJSON.postCreateCompanyAPiEndPoint,
      body: apiBody,
    });
  };

  postCreateCompanyAccountSuccessCallBack = (res: any) => {
    notification["success"]({
      message: res.message,
    });
      this.props.history.push("/employee/home");
  };

  postCreateCompanyAccountFailureCallBack = (err: any) => {
  if (err.errors.contact){
    this.setState({
      alreadyTakenContact:true
    })
  }
  if (err.errors.email){
    this.setState({
      alreadyTaken:true,
    })
  }

  };

  onAccountFinish = async (values: any) => {
    if (this.validateForm(values)) {
      this.setState(
        {
          newCompnayEmail: values.email,
          newCompnayPassword: values.password,
          newCompnayname: values.name,
          newCompnayConatct: values.companycontact,
          newCompnayStakeholder: values.stakeholder,
          newCompnayaddress: values.address,
          newCompnayRentAmount: values.rentamount,
          newCompnayRentDueDate: values.datepicker,
          newCompnayTypeId: values.companytype,
          newCompnayDocName: values.companytype,
          newCompnayTypeDeadlineDate: values.companytype,
        },
        () => {
          this.employeeCreateCompanyAccount(values);
        }
      );
    }
  };

  validateForm = (values: any) => {
    let isValid = true;
    return isValid;
  };

  deleteRecentSearch = async (id:any) => {
    this.deleteRecentSearchApiCallId = await this.employeeCreateCompanyAccountApiCall({
      method: configJSON.deleteRecentSearchAPiMethod,
      endPoint: `${configJSON.deleteRecentSearchAPiEndPoint}/${id}`,
      contentType: configJSON.deleteRecentSearchApiContentType,
    });
  }

  deleteRecentSearchShowFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  deleteRecentSearchShowSuccessCallBack = async (res: any) => {
    this.getRecentSearchShow()
  };

  disabledPastDate = (current: any) => {
    return (
          current && current < moment().startOf("day")
        );
  }


  handleAddNewStaffButton = () => {
    this.setState({openStaffForm: true});
  };

  handleRemoveStaffButton = (e:any , id ?: any) => {
    let updatedAddedList = [...this.state.addedStaff];
    if(id){ 
      updatedAddedList = updatedAddedList.filter(staff => staff.id !== id);
    }
    
    this.setState({
      openStaffForm: id ? true : !this.state.openStaffForm, 
      newStaff: {email:"", name:""},
      addedStaff: updatedAddedList
    })
  };

  handleStaffFormInput = (e:any) => {
    const name = e.target.name?.replace("staff-","");
    this.setState({
      newStaff: {
        ...this.state.newStaff,
        [name] : e.target.value
      }
    });
  };

  handleAddNewStaff = async() => {
    this.formRef?.current?.validateFields(["staff-name","staff-email"])
    .then(() => {

      let updatedAddedList = [...this.state.addedStaff];
      let id = updatedAddedList.length + 1;
      updatedAddedList.push({
        ...this.state.newStaff,
        id:id
      });  

      this.setState({
        addedStaff: updatedAddedList, 
        openStaffForm:!this.state.openStaffForm,
        newStaff: {
          ...this.state.newStaff,
          email: "",
          name: ""
        }
      });

      this.formRef?.current?.setFieldsValue({ "staff-name":"", "staff-email":""});
    })
    .catch((error:any) => {
      MESSAGE.error(error, 4);
    })
  
  };

  deleteDocTypeChange = (index : any) =>{
    let documentChange = this.state.showDocName
    documentChange.splice(index, 1);
    this.setState({
      showDocName:documentChange
    })
  }

  companyLogoHandler = (e: any) => {
    const reader: any = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        let profileImage = {
          url: reader.result,
        };
        this.setState({ companyLogoImage: profileImage });
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  handleAlreadyTaken = () => {
     this.setState  ({
      alreadyTakenContact: false
     }) 
  }
  handleNewCompanyEmail = () => {
     this.setState  ({
      alreadyTaken: false
     }) 
  }

}
// Customizable Area End
