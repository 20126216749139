import React from "react";
// Customizable Area Start
import { Col, Row } from "antd";
import CompanyHomeController from "./CompanyHomeController.web";
import { filterimgsort } from "./assets";
import { withRouter } from "react-router-dom";
import NavbarCompanyCommonComponent from "../../../components/src/NavbarCompanyCommonComponent.web";
import TableCompanyCommonComponent from "../../../components/src/TableCompanyCommonComponent.web";
import DocumentLoader from "../../../components/src/DocumentLoader.web";
import FilterCompanyCommonComponent from "../../../components/src/FilterCompanyCommonComponent.web";
// Customizable Area End

class CompanyHome extends CompanyHomeController {
  // Customizable Area Start
  renderHomeHeaderPart = () => {
    return (
      <>
        <Row>
          <Col lg={12} xl={12} md={24} sm={24} xs={24}>
            <div className="header-entry-box company_home-header">
              <h2 className="header-title">
                {this.state.data.documents_count} Documents
              </h2>
              <button
                type="button"
                data-test-id="btnFilterCompany"
                className="header-filter-sort"
                onClick={() => this.handlefiltersort()}
              >
                {(!this.state.isHomeFiltersort ||
                  this.state.isHomeFiltersort) && (
                  <img src={filterimgsort} className="filterimgsort" />
                )}
              </button>
            </div>
          </Col>
        </Row>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderFilterSortPart = () => {
    return (
      <>
        <FilterCompanyCommonComponent
          selectedList={this.state.selectedList}
          data={this.state.data}
          isOpenFilter={this.state.isHomeFiltersort}
          checkBoxChangeFilter={this.companyHomeCheckboxFilterHandler}
          resetFilters={this.resetFilters}
       />
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return (
      <>
          <NavbarCompanyCommonComponent
            {...this.props}
          />
          <div className="Main_container_company">
            {this.renderHomeHeaderPart()}
            {this.renderFilterSortPart()}
            <TableCompanyCommonComponent
              data={
                Object.keys(this.state.filteredData).length > 0
                  ? this.state.filteredData
                  : this.state.data
              }
              callingColumnsFeature={"home"}
              props={this.props}
              companyName={this.state.companyName}
            />
          </div>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(CompanyHome);
export { CompanyHome };
// Customizable Area End
