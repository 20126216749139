import React from "react";
import {
  Container,
  Box,
  // Customizable Area Start
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import DownloadProgressBar from "./DownloadProgressBar.web";
import moment from 'moment'
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import DownloadController, {
  Props,
} from "./DownloadController";



export default class DownloadedFile extends DownloadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const {DownloadGetArray} = this.state
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth={'lg'} >
          <Box>
            <h1>Download History</h1>
          </Box>
          <table className={"table-container"}>
            <tr>
              <th className={"tableHeader"}>file ID</th>
              <th className={"tableHeader"}>File Name</th>
              <th className={"tableHeader"}>Download date and time</th>
              <th colSpan={4}>Actions</th>
            </tr>
            {DownloadGetArray?.map((files:{id:number,name:string,downloaded_at:string,downloading:number} ,index:number)=> (
            <tr data-test-id={"UploadedFileShow"} key={index}>
              <td>{files.id}</td>
              <td>{files.name}</td>
              <td>{files.downloaded_at ? moment(files.downloaded_at).format('DD/MM/YYYY hh:mm'):''}</td>
              <td className={"downloadAction"}> 
              {files.downloading &&
              <DownloadProgressBar navigation= {this.props.navigation}  screenId={"downloadButton"} value={files.downloading} location={this.props.location} history={this.props.history} />
              }
              <Button data-test-id={"UploadedFiledownloadBtn"} onClick={()=>this.handleDownloadFiles(files.id, files.name)} variant="contained" className={"downloadButton cardButton"}>
                Download
              </Button>
              <Button data-test-id={"cancelBtn"} onClick={()=>this.handleCancelbtn(JSON.stringify(files.id))} variant="contained" className={"cancelButton cardButton"} >
                Cancel 
              </Button>
              </td>
            </tr>
            ))}
          </table>
        </Container>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
