import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
type NotificationType = "success" | "info" | "warning" | "error";
import { message as MESSAGE, notification } from "antd";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  history: any;
  location: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isUserLoggedIn: any;
  userValues: any;
  isUserEmployelogin: any;
  invalidEmailAndPassword: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class LoginController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postLoginWebApiCallId: any;
  postFcmTokenApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      isUserLoggedIn: false,
      userValues: null,
      isUserEmployelogin: "",
      invalidEmailAndPassword: false,
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.checkUserStatus();
  }

  checkUserStatus = async () => {
    let token = await localStorage.getItem("token");
    if (token) {
      this.setState({ isUserLoggedIn: true });
    }
  };

  loginApiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    let token = (await localStorage.getItem("token")) || "";
    const header = {
      "Content-Type": contentType,
       token:token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (responseJson.status === 500) {
        MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
        return;
      }

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (this.isLoginValidResponse(responseJson)) {
        this.apiSuccessCallBacks(apiRequestCallId, responseJson);
      } else if (this.isLoginInValidResponse(responseJson)) {
        this.apiFailureCallBacks(apiRequestCallId, responseJson);
      } else if (errorReponse) {
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  isLoginValidResponse = (responseJson: any) => {
    return responseJson && responseJson.data || responseJson.meta;
  };
  // Customizable Area End

  // Customizable Area Start
  isLoginInValidResponse = (responseJson: any) => {
    return responseJson && responseJson.errors;
  };
  // Customizable Area End

  // Customizable Area Start
  apiSuccessCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postLoginWebApiCallId) {
      this.LoginSuccessCallBack(responseJson);
    }
    if (apiRequestCallId === this.postFcmTokenApiCallId) {
      this.fcmSuccessCallBack(responseJson);
    }
  };
  // Customizable Area End

  // Customizable Area Start
  apiFailureCallBacks = (apiRequestCallId: any, responseJson: any) => {
    if (apiRequestCallId === this.postLoginWebApiCallId) {
      this.LoginFailureCallBack(responseJson);
    }
    if (apiRequestCallId === this.postFcmTokenApiCallId) {
      this.fcmFailureCallBack(responseJson);
    }
  };
  // Customizable Area End


  loginfunction = async (values: any) => {
    let apiBody = {
      type: window.location.pathname === "/" ? "employee" : "company",
      email: values.email,
      password: values.password,
    };
    this.postLoginWebApiCallId = await this.loginApiCall({
      contentType: configJSON.loginApiContentType,
      method: configJSON.loginAPiMethod,
      endPoint: configJSON.loginAPiEndPoint,
      body: apiBody,
    });
  };

  LoginSuccessCallBack = (res: any) => {
    this.openNotificationWithIcon("success", "Login successful");
    localStorage.setItem("token", res.meta.token);
    window.location.pathname === "/"
      ?
       this.props.history.push({
          pathname: "/employee/home",
        })
      : this.props.history.push({
          pathname: "/company/home",
        });
        this.fcmTokenfunction()
  };

  LoginFailureCallBack = async (err: any) => {
    if (err.errors[0].failed_login === "Invalid email ID or password") {
      this.setState({
        invalidEmailAndPassword: true,
      });
    }
  };

  onFinish = async (values: any) => {
    this.setState({ userValues: values }, () => {
      if (this.validateForm(values)) {
        this.loginfunction(values);
      } 
    });
  };

  validateForm = (values: any) => {
    let isValid = true;
    return isValid;
  };

  openNotificationWithIcon = (type: NotificationType, error: any) => {
    notification[type]({
      message: error,
    });
  };

  handlePasswordErrorMessage = () => {
    this.setState({
      invalidEmailAndPassword: false,
    });
  };

  handleEmailErrorMessage = () => {
    this.setState({
      invalidEmailAndPassword: false,
    });
  };

  fcmTokenfunction = async () => {
    const fcmToken = (await localStorage.getItem("fcmToken")) || "";
    let apiBody = {
      "fcm_token" : fcmToken
  }
    this.postFcmTokenApiCallId = await this.loginApiCall({
      contentType: configJSON.postFcmTokenApiContentType,
      method: configJSON.postFcmTokenAPiMethod,
      endPoint: configJSON.postFcmTokenAPiEndPoint,
      body: apiBody,
    });
  };

  fcmSuccessCallBack = (res: any) => {
  
  };

  fcmFailureCallBack = async (err: any) => {
    MESSAGE.error(err, 4);
  };
  

}
// Customizable Area End
