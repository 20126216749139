import React from "react";
// Customizable Area Start
import './TableCommonComponent.css';
import { Table } from 'antd';
// Customizable Area End

type TableCompanyCommonComponentProps = {
  // Customizable Area Start

  data: any;
  callingColumnsFeature:any;
  props:any;
  companyName:any;
  // Customizable Area End
};

const TableCompanyCommonComponent = ({
  // Customizable Area Start
  data,
  callingColumnsFeature,
  props,
  companyName,
  // Customizable Area End
}: TableCompanyCommonComponentProps) => {
  // Customizable Area Start

  const sorter = (valA : any, valB: any) => {
    if(valA === ""){
      return 1;
    }else if(valB === ""){
      return -1;
    }else if(valA?.toLowerCase() < valB?.toLowerCase()) {
      return -1;
    }else if(valA?.toLowerCase() > valB?.toLowerCase()){
      return 1;
    }
    return 0;
  };

  const companyHomeColumnName = (type:any) => ({
    title: type === 'pending' ? 'Document Needed' : 'Document Name',
    dataIndex: 'data',
    width: type === 'pending' ? '12%' : '21%',
    className: "main-table-columns",
    sorter:  ( a: any,b: any) => sorter(a?.attributes?.name, b?.attributes?.name),
    render: (_:any,data: any) =>  (<div className='content-box'>
    <p className='content-name'>
      {data?.attributes?.name}
    </p>
  </div>),
  });

  const companyHomeColumnDate = (type: String) => ({
    title: type === 'pending' ? 'Due date' : 'Date of upload' ,
    dataIndex: 'data',
    className: "main-table-columns",
    width: type === 'pending' ? '10%' : '19%',
    sorter: (a: any, b: any) => {
      if(type === "pending"){
        return sorter(a?.attributes?.deadline_date, b?.attributes?.deadline_date);
      }else{
        return sorter(a?.attributes?.upload_date, b?.attributes?.upload_date);
      }
    },
    render: (_:any, data: any) =>  {
      if(type === "pending") {
        return  (<div className='content-box'>
        <p className='content-name upload-width'>
          {data?.attributes?.deadline_date} 
        </p>
      </div>)
      }else{
        return  (<div className='content-box'>
        <p className='content-name upload-width'>
          {data?.attributes?.upload_date}
        </p>
      </div>)
      }
    }
  });

  const companyColumnAllFiledownloadimg= {
    title: '' ,
    dataIndex: 'data',
    width: '15%',
    className: "main-table-columns",
    render: (_:any, data: any) =>  data?.attributes?.files?.length > 0 
    ? (
      <div className="download-container">
        <button className='mail-imgbox view-button cmpny-view-btn'
        onClick={() => props.history.push(`/company/document/${data.attributes.id}`)}
        >
          View
        </button>
      </div>
    ): <></>
  };

  const companyDocumentStatus = (type: String) => ({
    title: 'Status',
    dataIndex: 'data',
    className: "main-table-columns",
    width: type === 'pending' ? '50%' : '15%',
    sorter: (a: any, b: any) => sorter(a?.attributes?.status, b?.attributes?.status),
    render: (_:any,data: any) =>  {
      return (<div className='content-box'>
        <p className='content-name upload-width'>
          {data?.attributes?.status}
        </p>
      </div>)
    }
  });


  const columnsCompanyHomeNeeded = [
    companyHomeColumnName("pending"),
    companyHomeColumnDate("pending"),
    companyColumnAllFiledownloadimg
  ];

  const columnsCompanyHomeUploaded = [
    companyHomeColumnName("needed"),
    companyHomeColumnDate("needed"),
    companyDocumentStatus("needed"),
    companyColumnAllFiledownloadimg
  ];


  const companyColumnAllFileNames= {
    title: 'Documents name' ,
    dataIndex: 'data',
    width: '25%',
    className: "main-table-columns",
    sorter:  (a: any, b: any) => sorter(a?.attributes?.name, b?.attributes?.name),
    render: (_:any, data: any) =>  (
      <div className='content-box'>
        <p className='content-name '>
          {data?.attributes?.name}
        </p>
      </div>
    ),
  };

  const companyColumnType = {
    title: 'Documents type' ,
    dataIndex: 'data',
    width: '25%',
    className: "main-table-columns",
    sorter:  (a: any, b: any) => sorter(b?.attributes?.document_type, a?.attributes?.document_type),
    render: (_:any, data: any) =>  (<div className='content-box'>
    <p className='content-name'>
      {data?.attributes?.document_type}
    </p>
  </div>),
  };

  const companyColumnDeadlineDate = {
    title: 'Deadline date' ,
    dataIndex: 'data',
    width: '20%',
    className: "main-table-columns",
    sorter:  (a: any, b: any) => sorter(a?.attributes?.deadline_date, b?.attributes?.deadline_date),
    render: (_:any, data: any) => (
      <div className='content-box'>
        <p className='content-name upload-width'>
        {data?.attributes?.deadline_date}
        </p>
      </div>
    ),
  };

  const companyColumnUploadedDate = {
    title: 'Uploaded date' ,
    dataIndex: 'data',
    width: '20%',
    className: "main-table-columns",
    sorter:  (a: any, b: any) => sorter(a?.attributes?.upload_date, b?.attributes?.upload_date),
    render: (_:any, data: any) => (<div className='content-box'>
      <p className='content-name upload-width'>
        {data?.attributes?.upload_date}
      </p>
    </div>),
  };

  const columnsCompanyAllFiles = [
    companyColumnType,
    companyColumnAllFileNames,
    companyColumnDeadlineDate,
    companyColumnUploadedDate,
    companyDocumentStatus("allFiles"),
  ];

  const onChange  = (pagination:any, filters:any, sorter:any, extra:any) => {
    console.log('params', pagination, filters, sorter, extra);
  };

  if(callingColumnsFeature === "home"){
    return (
        <>
            <Table 
                columns={columnsCompanyHomeNeeded}
                dataSource={data?.pending_docs?.data?.length > 0 ? [...data.pending_docs.data]: []} 
                onChange={onChange} 
                className="company_table_style"
                pagination={false}
            />
            <Table 
                columns={columnsCompanyHomeUploaded}
                dataSource={data?.non_pending_docs?.data?.length > 0 ? [...data.non_pending_docs.data] : []} 
                onChange={onChange} 
                className="company_table_style"
                pagination={false}
            />
        </>
    )
  }

  return (
    <>
      <Table columns={columnsCompanyAllFiles} 
        dataSource={data.length > 0 ? [...data] : []} 
        onChange={onChange} 
        className="company_all_files_table_style"
        onRow={(record, rowIndex) => {
          return {
            onClick: (e) => {
              props.history.push(`/company/document/${record.id}`);
            }
          }
        }}
        pagination={false} />
    </>
  );
  // Customizable Area End
};
// Customizable Area Start
export default TableCompanyCommonComponent
// Customizable Area End
