import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { message as MESSAGE, notification } from "antd";
import { RouterProps } from "react-router";
import { withLoaderProps } from "../../../components/src/DocumentLoader.web";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouterProps &
  withLoaderProps & {
    // Customizable Area Start
    navigation: any;
    id: string;
    history: any;
    location: any;
    // Customizable Area End
  };

interface S {
  // Customizable Area Start
  isUserLoggedIn: any;
  apiToken: any;
  allDocument: any;
  allDocCount: any;
  uploadDocId: any;
  isUploadFiltersort: any;
  dataForUploadFilterSort: any;
  pageSize:any;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmployeeUploadDocController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getEmployeeAllDocsApiCallId: any;
  postUploadDocApiCallId: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      // Customizable Area Start
      isUserLoggedIn: false,
      apiToken: "",
      allDocument:[],
      dataForUploadFilterSort:[],
      uploadDocId : "",
      allDocCount : "",
      isUploadFiltersort : false,
      pageSize: 10
      // Customizable Area End
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  async componentDidMount() {
    this.checkUserStatus();
  }

  checkUserStatus = async () => {
    this.setState(
      {
        apiToken: localStorage.getItem("token"),
      },
      () => {
        if (this.state.apiToken) {
    this.getEmployeeAllDocumentsDetailsShow();
          this.setState({ isUserLoggedIn: true });
        }
      }
    );
  };

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body ,type = ""  } = data;
    const header = {
      "Content-Type": contentType,
      token: this.state.apiToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        type === "" ? JSON.stringify(body) : body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
    
      if(responseJson) {
        if(responseJson.status === 500) {
          MESSAGE.error(`${responseJson.error}. Please try again later.`, 4);
          return;
        }else if(responseJson.errors){
          switch(apiRequestCallId) {
            case this.getEmployeeAllDocsApiCallId : {
              this.getEmployeeAllDocumentsFailureCallBack(responseJson);
              break;
            }

            case this.postUploadDocApiCallId : {
              this.postUploadDocsShowFailureCallBack(responseJson);
              break;
            }

            default :{
              break;
            }
          }
        }else {
          switch(apiRequestCallId) {
            case this.getEmployeeAllDocsApiCallId : {
              this.getEmployeeAllDocumentsSuccessCallBack(responseJson);
              break;
            }

            case this.postUploadDocApiCallId : {
              this.postUploadDocShowSuccessCallBack(responseJson);
              break;
            }

            default :{
              break;
            }
          }
        }
      }else if(errorReponse){
        notification["error"]({
          message: "Internal Server Error.",
        });
      }
    }
  }

  getEmployeeAllDocumentsDetailsShow = async () => {
    this.props.showLoader()
    this.getEmployeeAllDocsApiCallId = await this.apiCall({
      contentType: configJSON.getEmployeeAllDocumentsApiContentType,
      method: configJSON.getEmployeeAllDocumentsApiMethod,
      endPoint:configJSON.getEmployeeAllDocumentsApiEndPoint,
    });
  };

  getEmployeeAllDocumentsFailureCallBack = (error: any) => {
    this.props.hideLoader()
  };

  getEmployeeAllDocumentsSuccessCallBack = async (res: any) => {
    this.setState({ allDocument: res.documents , allDocCount : res});
    this.props.hideLoader()
  };
  uplodeId = (item:any) =>{
    this.setState({
      uploadDocId : item.id
    })
  }
  postUploadDocDetailShow = async (e:any,item:any) => {
    let fileList = [...e.target.files];
    let formData = new FormData();
    fileList.forEach((item: any) => {
      formData.append('docs[]',item)
    });
    this.props.showLoader();
    this.postUploadDocApiCallId = await this.apiCall({
      method: configJSON.postUploadDocApiMethod,
      endPoint: `${configJSON.postUploadDocApiEndPoint}/${this.state.uploadDocId}`,
      body: formData,
      type: "formData",
    });
  };

  postUploadDocsShowFailureCallBack = (error: any) => {
    MESSAGE.error(error, 4);
  };

  postUploadDocShowSuccessCallBack = async (res: any) => {
    this.getEmployeeAllDocumentsDetailsShow();
    notification["success"]({
      message: res.message,
    });
  };

  handleUploadFiltersort = () => {
    this.setState({ isUploadFiltersort: !this.state.isUploadFiltersort });
    this.setState({ isUploadFiltersort: !this.state.isUploadFiltersort, dataForUploadFilterSort: this.state.dataForUploadFilterSort.length > 0 ? this.state.dataForUploadFilterSort : this.state.allDocument });
  };

  UploadFiltredData = (data: any) => {
    const UploadFiltredData = this.state.allDocument
    let filtredDataForSelect = UploadFiltredData.filter((compItem: any) => {
      if (compItem.isSelect) {
        return compItem
      }
    })
    this.setState({
      dataForUploadFilterSort: filtredDataForSelect,
    })
  }
  uploadCheckBoxFilter = (e: any, item: any) => {
    const companData = this.state.allDocument 
    if (e.target.checked) {
      let dataItemForSelect = companData.map((compItem: any) => {
        if (compItem.data.id === item.data.id ||  compItem.data.attributes.name === item.data.attributes.name) {
          compItem.isSelect = true
        }
      })
      this.UploadFiltredData(dataItemForSelect)
    } else {
      let dataItemForSelect = companData.map((compItem: any) => {
        if (compItem.data.id === item.data.id || compItem.data.attributes.name === item.data.attributes.name) {
          compItem.isSelect = false
        }
      })
      this.UploadFiltredData(dataItemForSelect)
    }
  }

  handleUploadClearFilter =  () => {
    let uploadFilteredData = this.state.allDocument
   let abc = uploadFilteredData.map((filItem:any)=>{
      if (filItem.isSelect) {
        filItem.isSelect = false
      }
     return filItem
    }
    )
    this.setState({
      dataForUploadFilterSort : [],
      allDocument : abc
    })
  }

  handleChangePageSize = (val:any) => {
    this.setState({pageSize:val})
  }
}
// Customizable Area End
