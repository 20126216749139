import React from "react";
// Customizable Area Start
import { Col, Modal, Row } from "antd";
import NavbarCommonComponent from "../../../components/src/NavbarCommonComponent.web";
import EmployeeViewDocumentController from "../src/EmployeeViewDocumentController.web";
import DocumentLoader from "../../../components/src/DocumentLoader.web";
// Customizable Area End

class EmployeeViewDocument extends EmployeeViewDocumentController {
  // Customizable Area Start
  renderDocNameBox = () => {
    return (
      <>
        <Row>
          <Col lg={24} xl={24} md={24} sm={24} xs={24}>
            <div className="profile-name-box doc-button-group">
              <div className="doc-name-text-box">
                <h3 className="profile-name-title view-doc-name">
                  {this.state.documentData?.name}
                </h3>
              </div>
              <div className="view-document-actions">
              <button 
              className="upload-more-files-button" onClick={() => this.uploadRef.click()}>
              <>
                <input
                  id="myInput"
                  type="file"
                  name="upload"
                  ref={(ref) => this.uploadRef = ref}
                  multiple
                  data-test-id="inputUploadFiles"
                  style={{display:"none"}}
                  onChange={(e:any) => this.postUploadDocFiles(e)}
                />
                <div>
                    Upload more files
                </div>
                </> 
              </button>
                <button
                  type="button"
                  data-test-id="btnDeleteDocType"
                  className="files-button files-button-margin"
                  onClick={this.deleteDocumentModal}
                >
                  Delete document
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderDocDetailsBox = () => {
    return (
      <>
        <div className="doc-details-box">
          <div className="employe-details-title-box">
            <p className="employe-details-title">Document details</p>
          </div>
          <div className="personal-details-box ">
            <Row>
              {this.renderIdAndName()}
              {this.renderDocAndDeadlineDate()}
              {this.renderCompanyNameUploadDate()}
              {this.renderStatusUploadBy()}
            </Row>
          </div>
        </div>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderIdAndName = () => {
    return (
      <>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Id</p>
            <p className="details-title-text">{this.state.documentData?.id}</p>
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Name</p>
            <p className="details-title-text">
              {this.state.documentData?.name}
            </p>
          </div>
        </Col>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderDocAndDeadlineDate = () => {
    return (
      <>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Document type</p>
            <p className="details-title-text">
              {this.state.documentData?.document_type}
            </p>
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Deadline date</p>
            <p className="details-title-text">
              {this.state.documentData?.deadline_date}
            </p>
          </div>
        </Col>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderCompanyNameUploadDate = () => {
    return (
      <>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Company</p>
            <p className="details-title-text">
              {" "}
              {this.state.documentData?.company?.name}{" "}
            </p>
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Upload date</p>
            <p className="details-title-text">
              {" "}
              {this.state.documentData?.upload_date}
            </p>
          </div>
        </Col>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  renderStatusUploadBy = () => {
    return (
      <>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Status</p>
            <p className="details-title-text">
              {" "}
              {this.state.documentData?.status}
            </p>
          </div>
        </Col>
        <Col lg={12} xl={12} md={12} sm={24} xs={24}>
          <div className="details-text-box personal-details-padding">
            <p className="details-title">Uploaded by</p>
            <p className="details-title-text">
              {this.state.documentData?.uploaded_by}
            </p>
          </div>
        </Col>
      </>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  renderDocFilesBox = () => {
    return (
      <>
        <div className="doc-details-box">
          <div className="employe-details-title-box">
            <p className="employe-details-title">Files</p>
          </div>
          <div className="personal-details-box height-class">
            <Row>
              {this.state.documentData?.files?.map((item: any, index: any) => {
                return (
                  <Col lg={24} xl={24} md={24} sm={24} xs={24} key={index}>
                    <>
                      <div className="files-box">
                        <div className="details-text-box">
                          <p className="details-title">{item.name}</p>
                        </div>
                        <div className="files-button-box">
                          <button
                            type="button"
                            data-test-id="btnDownloadFiles"
                            className="files-button files-button-margin"
                            onClick={() => this.handleDownloadFiles(item)}
                          >
                            Download
                          </button>
                          <button
                            type="button"
                            className="files-button"
                            onClick={() =>
                              this.deleteDocumentFilesModal(item.id)
                            }
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </>
                  </Col>
                );
              })}
            </Row>
          </div>
        </div>
      </>
    );
  };
  // Customizable Area End

    // Customizable Area Start
    renderDeleteFileModal = () => {
      return (
        <>
          <Modal
            closable={false}
            footer={null}
            centered
            className="delete-main-modal"
            open={this.state.deleteFileModal || this.state.deleteDocModal}
            onCancel={this.handleCancelFileModal}
          >
            <div className="modal-content">
              <h3 className="modal-heading">
              You're about to delete {`${this.state.deleteDocModal ? "the document!" : "a document file!"}`}
              </h3>
            </div>
            <div className="pswrd-submit-btn">
              <button type="button" className="pswrd-btn companymodalbutton" onClick={this.handleCancelFileModal}>
                Cancel
              </button>
              <button
                type="button"
                data-test-id="btnDeleteHandler"
                className="pswrd-btn save-btn companymodalbutton"
                onClick={this.deleteHandler}
              >
                Delete
              </button>
            </div>
          </Modal>
        </>
      );
    };
    // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <NavbarCommonComponent
          displaySpinner={() => { } }
          hideLoader={() => { } }
          showLoader={() => { } }
          history={undefined}
          navigation={undefined}
          id={""}
          location={undefined} isNotificationUpdate={false}/>
        <div className="Employee-Pofile-container">
          {this.renderDocNameBox()}
          {this.renderDocDetailsBox()}
          {this.renderDocFilesBox()}
          {this.renderDeleteFileModal()}
        </div>
      </>
    );
  }
  // Customizable Area End
}
// Customizable Area Start
export default DocumentLoader(EmployeeViewDocument);
export { EmployeeViewDocument };
// Customizable Area End
