import React from "react";
// Customizable Area Start
import { Col, Row } from "antd";
import { UserOutlined, BellOutlined, LogoutOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { search } from "./assets";
import HomeController from "../../blocks/landingpage/src/EmployeeHomeController.web";
// Customizable Area End
export default class NavbarCommonComponent extends HomeController {
  // Customizable Area Start
  renderNavbar = () => {
    return (
      <>
        <div className="navbarContainer">
          <Row className="navbar-row">
            <Col lg={8} md={8} sm={24} xs={24}>
              <div className="greeting-box">
                <h3 className="greeting-text">
                  Hi {this.state.employeeName},<br />
                  {
                     window.location.pathname === "/employee/home" ?  <span className="sidebar-title">Home</span> : 
                     window.location.pathname === "/employee/company" ?  <span className="sidebar-title">Companies</span> : 
                     window.location.pathname === "/employee/files" ?  <span className="sidebar-title">All files</span> : 
                     window.location.pathname === "/employee/company/details" ?  <span className="sidebar-title">Company details</span> : 
                     window.location.pathname === "/employee/upload/docs" ?  <span className="sidebar-title">Upload documents</span> : 
                     window.location.pathname === "/employee/view/docs" ?  <span className="sidebar-title">View document files</span> : 
                     window.location.pathname === "/employee/profile" ?  <span className="sidebar-title">Employee profile</span> :
                      window.location.pathname === "/employee/add/newdocument" ?  <span className="sidebar-title">Employee add new document</span> : 
                      window.location.pathname === "/employee/reminders" ?  <span className="sidebar-title">Employee reminders</span> : 
                      window.location.pathname === "/employee/view/reminder" ?  <span className="sidebar-title">Employee view reminder</span> :
                      window.location.pathname === "/employee/notifications" ?  <span className="sidebar-title">Employee notifications</span> : ""
                  }
                </h3>
              </div>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <div className="searchbar-box">
                <div className="searchbox">
                  <img src={search} className="search-icon-img" />
                  <input className="search-section" placeholder="Search" />
                </div>
              </div>
            </Col>
            <Col lg={8} md={8} sm={24} xs={24}>
              <div className="notification-box">
              <Link to="/employee/notifications">
                <button className={
                      window.location.pathname === "/employee/notifications"
                        ? "active-profile profile-bar countVisisble"
                        : "notification-bar profile-bar countVisisble"
                    } type="button">
                  <BellOutlined
                    translate={undefined}
                    className="bell-outlined" 
                  />
                </button>
                {
                  this.state.isReadDataCount ? <p className="countData">{this.state.isReadDataCount}</p> : <p className="countData">0</p>
                }
                </Link>
                <div className="hover_block">
                  <button
                    className={
                      window.location.pathname === "/employee/profile"
                        ? "active-profile profile-bar"
                        : "notification-bar profile-bar"
                    }
                    type="button"
                  >
                    <UserOutlined translate={undefined} />
                  </button>
                  <div className="profile_hove_head_set">
                    <div className="profile_hove_head">
                      <ul className="hovelist_header">
                        <Link to="/employee/profile">
                          <li className="hover_list">
                            <UserOutlined translate={undefined} /> &nbsp;
                            Profile
                          </li>
                        </Link>
                        <Link to={this.state.isLogout ? "/" : ""}>
                              <li className="hover_list" onClick={()=>{this.deleteFcmToken()}}>
                            <LogoutOutlined translate={undefined} /> &nbsp;
                            Logout
                          </li>
                        </Link>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  };
  // Customizable Area End
  // Customizable Area Start
  render() {
    return <>{this.renderNavbar()}</>;
  }
  // Customizable Area End
}
